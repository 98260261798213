import React, { useState } from "react";
// import { useHistory } from "react-router";
import { ReactComponent as AuthImg } from "./images/auth-img.svg";
import * as yup from "yup";
import { useFormik } from "formik";
import "./index.css";
import { forgotPasswordApiCall } from "../../Api/AuthApi";
import { LoadingButton } from "@mui/lab";
import notification from "../../common/Alert";

const ForgotPassword = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // let history = useHistory();

  let validationSchema = yup.object({
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setError(null);
      values.url = "https://resetpasswordurl";
      forgotPassword(values);
    },
  });

  const forgotPassword = async (values) => {
    setLoading(true);
    const res = await forgotPasswordApiCall(values);
    const { success, resData, error } = res;
    setLoading(false);

    console.info({ success, resData, error });
    if (success) {
      // history.push("/reset-password");
      notification.notify(
        "success",
        "A verification link has been sent to your email.Follow the link to reset your password"
      );
    }
    if (error)
      setError(typeof error === "string" ? error : "Invalid Credentials");
  };

  return (
    <div className="auth-container">
      <div className="auth-container-inner">
        <div className="auth-left">
          <h2>Forgot your Password?</h2>
          <p>Enter the email address you used in opening an account</p>
          {error && <div style={{ color: "red" }}>{error}</div>}
          <form onSubmit={formik.handleSubmit}>
            <label>Email</label>
            <br />
            <input
              type="email"
              placeholder="Enter your email address"
              name="email"
              id="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && formik.touched.email && (
              <div style={{ color: "red" }}> {formik.errors.email}</div>
            )}
            <br />

            <LoadingButton
              loadingPosition="end"
              loading={loading}
              type="submit"
            >
              Send Reset Link
            </LoadingButton>
          </form>
        </div>
        <div className="auth-right">
          <AuthImg style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
