import { createSlice } from "@reduxjs/toolkit";
import { getCustomersApiCall } from "../../../Api/customerApi";
import { getSalesApiCall } from "../../../Api/sales";
import { getStaffsApiCall } from "../../../Api/staffApi";
import {
  getMessagesApiCall,
  getStoreApiCall,
  getStoresApiCall,
} from "../../../Api/storApi";
import {
  getBanksApiCall,
  getNotificatitonsApiCall,
} from "../../../Api/utilities";
import { setLoading } from "../app";
import cloneDeep from "lodash.clonedeep/index";
import {
  getServiceCategoriesApiCall,
  getServicesApiCall,
} from "../../../Api/service";
import { getDiscountsApiCall } from "../../../Api/discount";
import { getOrdersApiCall } from "../../../Api/order";
import { getPayrollApiCall } from "../../../Api/payroll";
import { getPayoutsApiCall } from "../../../Api/payout";
import { getTaxsApiCall } from "../../../Api/tax";
import { logoutAction } from "../user";
import { getExpensesApiCall } from "../../../Api/expense";
import { getPurchaseItemApiCall } from "../../../Api/purchaseItem";
import { getOrderItemsApiCall } from "../../../Api/orderItem";

const initialState = {
  stores: [
    {
      id: "1",
      name: "Lekki Store",
      is_primary: 1,
      deleted_at: null,
      created_at: "2022-03-24T11:50:33.000000Z",
      updated_at: "2022-03-24T11:50:33.000000Z",
      staffs_count: 5,
    },
  ],
  selectedStore: "1",
  storeInformation: null,
  storeCustomers: [],
  storeStaffs: [],
  storeSales: [],
  customers: [],
  hashTable: {},
  banks: [],
  services: [],
  discounts: [],
  orders: [],
  payroll: [],
  payouts: [],
  notifications: [],
  serviceCategories: [],
  orderItems: [],
  tax: "",
};

let slice = createSlice({
  name: "Store",
  initialState,
  reducers: {
    setTax(state, action) {
      return { ...state, tax: action.payload };
    },
    setNotifications(state, action) {
      return { ...state, notifications: action.payload };
    },
    setStores(state, action) {
      return { ...state, stores: action.payload };
    },
    setSelectedStore(state, action) {
      return { ...state, selectedStore: action.payload };
    },
    setStoreStaffs(state, action) {
      return { ...state, storeStaffs: action.payload };
    },
    setStoreSales(state, action) {
      return { ...state, storeSales: action.payload };
    },
    setBanks(state, action) {
      return { ...state, banks: action.payload };
    },
    setCustomers(state, action) {
      return { ...state, customers: action.payload };
    },
    setHashTable(state, action) {
      return { ...state, hashTable: action.payload };
    },
    setServices(state, action) {
      return { ...state, services: action.payload };
    },
    setDiscounts(state, action) {
      return { ...state, discounts: action.payload };
    },
    setStoreInformation(state, action) {
      return { ...state, storeInformation: action.payload };
    },
    setPayrole(state, action) {
      return { ...state, payroll: action.payload };
    },
    setPayouts(state, action) {
      return { ...state, payouts: action.payload };
    },
    setOrders(state, action) {
      return { ...state, orders: action.payload };
    },
    setExpenses(state, action) {
      return { ...state, expenses: action.payload };
    },
    setPurchaseItems(state, action) {
      return { ...state, purchaseItems: action.payload };
    },
    setOrderItems(state, action) {
      return { ...state, orderItems: action.payload };
    },
    setServiceCategories(state, action) {
      return { ...state, serviceCategories: action.payload };
    },
    setMessages(state, action) {
      return { ...state, messages: action.payload };
    },
    clearStoresAction() {
      return { ...initialState };
    },
  },
});

export const {
  setStores,
  clearStoresAction,
  setStoreInformation,
  setSelectedStore,
  setCustomers,
  setStoreStaffs,
  setStoreSales,
  setBanks,
  setHashTable,
  setServices,
  setDiscounts,
  setOrders,
  setPayouts,
  setPayrole,
  setTax,
  setNotifications,
  setServiceCategories,
  setExpenses,
  setPurchaseItems,
  setOrderItems,
  setMessages,
} = slice.actions;

export default slice.reducer;

export const fetchStoresAction = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { success, resData, error } = await getStoresApiCall();

  if (success && resData) dispatch(setStores(resData?.data || []));
  dispatch(setLoading(false));
  if (error === "Unauthenticated.") dispatch(logoutAction());

  return success;
};

export const fetchStoreAction = () => async (dispatch, getStore) => {
  const { selectedStore, hashTable } = getStore().Store;
  // dispatch(clearStoresAction());
  dispatch(setLoading(true));

  const store = getStoreApiCall(selectedStore);
  const customer = getCustomersApiCall(selectedStore);
  const staff = getStaffsApiCall(selectedStore);
  const sales = getSalesApiCall(selectedStore);
  const Banks = getBanksApiCall();
  const services = getServicesApiCall(selectedStore, { per_page: 50 });
  const serviceCatgeories = getServiceCategoriesApiCall(selectedStore);
  const discounts = getDiscountsApiCall(selectedStore);
  const tax = getTaxsApiCall(selectedStore);
  const [
    storeRes,
    customerRes,
    staffRes,
    salesRes,
    banksRes,
    servicesRes,
    discountsRes,
    taxRes,
    serviceCatgeoriesRes,
  ] = await Promise.all([
    store,
    customer,
    staff,
    sales,
    Banks,
    services,
    discounts,
    tax,
    serviceCatgeories,
  ]);

  const { success: storeSuccess, resData: storeResData, error } = storeRes;
  const { success: customerSuccess, resData: customerResData } = customerRes;
  const { success: staffSuccess, resData: staffResData } = staffRes;

  const { success: salesSuccess, resData: salesResData } = salesRes;
  const { success: banksSuccess, resData: banksResData } = banksRes;

  const { success: serviceSuccess, resData: servicesResData } = servicesRes;
  if (
    servicesResData?.meta?.current_page &&
    servicesResData?.meta?.current_page < servicesResData?.meta?.last_page
  )
    dispatch(
      fetchServicesAction({
        query: { per_page: 50 },
        range: {
          from: servicesResData?.meta?.current_page + 1,
          to: servicesResData?.meta?.last_page,
        },
      })
    );
  const { success: discountSuccess, resData: discountsResData } = discountsRes;
  const { success: taxSuccess, resData: taxsResData } = taxRes;

  const {
    success: serviceCatgeoriesSuccess,
    resData: serviceCatgeoriesResData,
  } = serviceCatgeoriesRes;

  if (storeSuccess && storeResData) dispatch(setStoreInformation(storeResData));
  if (staffSuccess && staffResData)
    dispatch(setStoreStaffs(staffResData?.data || []));
  if (salesSuccess && salesResData)
    dispatch(setStoreSales(salesResData?.data || []));
  if (customerSuccess && customerResData)
    dispatch(setCustomers(customerResData?.data || []));
  if (banksSuccess && banksResData) dispatch(setBanks(banksResData || []));
  if (serviceSuccess && servicesResData)
    dispatch(setServices(servicesResData?.data || []));
  dispatch(setLoading(false));
  if (discountSuccess && discountsResData)
    dispatch(setDiscounts(discountsResData?.data || []));
  if (taxSuccess && taxsResData) dispatch(setTax(taxsResData?.tax || []));
  if (serviceCatgeoriesSuccess && serviceCatgeoriesResData)
    dispatch(setServiceCategories(serviceCatgeoriesResData?.data || []));
  dispatch(setLoading(false));

  const banks = {};
  banksResData?.forEach((v) => {
    banks[v.id] = v;
  });
  const tempHashTable = cloneDeep(hashTable);

  dispatch(setHashTable({ ...tempHashTable, banks }));

  if (error === "Unauthenticated.") dispatch(logoutAction());
};

export const fetchSalesAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getSalesApiCall(
    selectedStore,
    query
  );
  if (success && resData) dispatch(setStoreSales(resData?.data || []));

  dispatch(setLoading(false));
  if (error === "Unauthenticated.") dispatch(logoutAction());

  return resData;
};

export const fetchCustomersAction = (query) => async (dispatch, getStore) => {
  const { hashTable } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getCustomersApiCall(query);
  if (success && resData) dispatch(setCustomers(resData?.data || []));
  const tempHashTable = cloneDeep(hashTable);
  const customers = {};
  resData?.data?.forEach((v) => {
    customers[v.id] = v;
  });
  dispatch(setHashTable({ ...tempHashTable, customers }));
  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
  return resData;
};

export const fetchStaffsAction = (query) => async (dispatch, getStore) => {
  const { selectedStore, hashTable } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getStaffsApiCall(
    selectedStore,
    query
  );

  if (success && resData) dispatch(setStoreStaffs(resData?.data || []));
  const tempHashTable = cloneDeep(hashTable);
  const staffs = {};
  resData?.data?.forEach((v) => {
    staffs[v.id] = v;
  });
  dispatch(setHashTable({ ...tempHashTable, staffs }));
  // dispatch(clearStoresAction());
  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
  return resData;
};

export const fetchBanksAction = () => async (dispatch, getStore) => {
  const { hashTable } = getStore().Store;

  dispatch(setLoading(true));
  const { success, resData, error } = await getBanksApiCall();
  if (success && resData) dispatch(setBanks(resData || []));

  dispatch(setLoading(false));
  const banks = {};
  resData?.forEach((v) => {
    banks[v.id] = v;
  });
  const tempHashTable = cloneDeep(hashTable);

  dispatch(setHashTable({ ...tempHashTable, banks }));
  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchOrdersAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getOrdersApiCall(
    selectedStore,
    query
  );

  if (success && resData) dispatch(setOrders(resData?.data || []));

  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchPayRoleAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getPayrollApiCall(
    selectedStore,
    query
  );
  if (success && resData) dispatch(setPayrole(resData?.data || []));

  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());

  return resData?.payroll?.id;
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchPayOutsAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getPayoutsApiCall(
    selectedStore,
    query
  );

  if (success && resData) dispatch(setPayouts(resData?.data || []));

  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchServicesAction =
  ({ query, range }) =>
  async (dispatch, getStore) => {
    const { selectedStore, services } = getStore().Store;
    dispatch(setLoading(true));
    if (range) {
      const req = [];
      for (var i = range.from; i <= range.to; i++) {
        req.push(getServicesApiCall(selectedStore, { ...query, page: i }));
      }
      try {
        const res = await Promise.all(req);
        var allServices = [];
        res.forEach((v) => {
          allServices = [...allServices, ...v.resData?.data];
        });
        dispatch(setServices([...services, ...allServices]));
      } catch (error) {
        console.info(error);
      }
    } else {
      const { success, resData, error } = await getServicesApiCall(
        selectedStore,
        query
      );
      dispatch(setLoading(false));

      if (success && resData) dispatch(setServices(resData?.data || []));
      if (error === "Unauthenticated.") dispatch(logoutAction());
      else return resData;
    }

    dispatch(setLoading(false));

    // if (error)
    //   dispatch(
    //     displayNotificationAction({
    //       message: "Unable to fetch profile ",
    //       type: "error",
    //     })
    //   );
  };

export const fetchDiscountsAction = () => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getDiscountsApiCall(selectedStore);

  if (success && resData) dispatch(setDiscounts(resData?.data || []));
  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchTaxAction = () => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));
  const { success, resData, error } = await getTaxsApiCall(selectedStore);

  if (success && resData) dispatch(setTax(resData?.tax));
  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchNotoficationAction = () => async (dispatch) => {
  const { success, resData, error } = await getNotificatitonsApiCall();
  if (success && resData) dispatch(setNotifications(resData?.data || []));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
};

export const fetchExpensesAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  dispatch(setLoading(true));

  const { success, resData, error } = await getExpensesApiCall(
    selectedStore,
    query
  );
  dispatch(setLoading(false));
  if (success && resData) dispatch(setExpenses(resData?.data || []));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
  return { success, resData, error };
};

export const fetchItemsAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  const { success, resData, error } = await getPurchaseItemApiCall(
    selectedStore,
    query
  );
  if (success && resData) dispatch(setPurchaseItems(resData?.data || []));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
  return resData;
};
export const fetchOrderItemsAction = (query) => async (dispatch, getStore) => {
  const { selectedStore } = getStore().Store;
  const { success, resData, error } = await getOrderItemsApiCall(
    selectedStore,
    query
  );
  if (success && resData) dispatch(setOrderItems(resData?.data || []));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  // if (error)
  //   dispatch(
  //     displayNotificationAction({
  //       message: "Unable to fetch profile ",
  //       type: "error",
  //     })
  //   );
  return resData;
};
export const fetchMessagesAction = (query) => async (dispatch, getStore) => {
  dispatch(setLoading(true));
  const { selectedStore } = getStore().Store;
  const { success, resData, error } = await getMessagesApiCall(
    selectedStore,
    query
  );
  if (success && resData) dispatch(setMessages(resData?.data || []));
  dispatch(setLoading(false));

  if (error === "Unauthenticated.") dispatch(logoutAction());
  return resData;
};
