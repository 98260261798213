import axios from "axios";

export async function request({
  method = "get",
  url = "",
  payload,
  query,
  headers = {},
}) {
  try {
    const token = localStorage.getItem("washRyteToken2022");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const res = await axios({
      method,
      url,
      data: payload,
      headers,
      params: query,
    });

    const { data: resData, status} = res;
    if (
      status >= 200 && status < 300
    ) {
      return { success: true, resData };
    }
    return { success: false, resData: res };
  } catch (error) {
    if (error.response) {
      console.info("error", error?.response?.data);

      const errors = error?.response?.data?.errors;
      console.info("error", errors, error?.response?.data);

      if (errors) {
        console.info(Object.values(errors).join(", "));
        return {
          success: false,
          error: Object.values(errors).join(", "),
        };
      }

      return {
        success: false,
        error: error?.response?.data?.message,
      };
    }
    if (error.request) {
      return {
        success: false,
        error: error.request,
      };
    }
    if (error.message) {
      console.info(error.message);
      return {
        success: false,
        error: error.message,
      };
    }

    return {
      success: false,
      error: error,
    };
  }
}
