import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const servicesUrl = (storeId) => `${BASE_URL}/${storeId}/services`;
const individualServiceUrl = (storeId, serviceId) =>
  `${servicesUrl(storeId)}/${serviceId}`;

export function getServicesApiCall(storeId, query) {
  return request({ url: servicesUrl(storeId), query });
}

export function getServiceCategoriesApiCall(storeId) {
  return request({ url: `${BASE_URL}/${storeId}/service-categories` });
}

export function getServiceApiCall(storeId, serviceId) {
  return request({ url: individualServiceUrl(storeId, serviceId) });
}

export function createServiceApiCall(storeId, payload) {
  return request({ url: servicesUrl(storeId), method: "post", payload });
}

export function updateServiceApiCall(storeId, serviceId, payload) {
  return request({
    url: individualServiceUrl(storeId, serviceId),
    method: "put",
    payload,
  });
}

export function deleteServiceApiCall(storeId, serviceId) {
  return request({
    url: individualServiceUrl(storeId, serviceId),
    method: "delete",
  });
}

export function getServiceItemHistoryApiCall(storeId, itemId, query) {
  return request({
    url: `${BASE_URL}/${storeId}/${itemId}/service-inventories`,
    query,
  });
}

export function createServiceItemHistoryApiCall(storeId, itemId, payload) {
  return request({
    url: `${BASE_URL}/${storeId}/${itemId}/service-inventories`,
    payload,
    method: "post",
  });
}

export function getServiceReportApiCall(storeId, categoryId, query) {
  return request({
    url: `${BASE_URL}/${storeId}/service-categories/${categoryId}/summary`,
    query,
  });
}
