import React from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import "./sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../store/reducers/user";
import { NoteAdd, NoteAddTwoTone, ReportRounded } from "@mui/icons-material";

const { Sider } = Layout;
const { SubMenu } = Menu;
const regularStaff = [
  {
    icon: "/assets/icons/ant-design_dollar-circle-filled.png",
    pathname: "Sales",
    route: "/dashboard/sales",
  },
  {
    icon: "/assets/icons/users-2.png",
    pathname: "Customer",
    route: "/dashboard/customer",
  },
  {
    icon: "/assets/icons/clarity_settings-solid.png",
    pathname: "Settings",
    route: "/dashboard/settings",
  },
];
const salesStaff = [
  {
    icon: "/assets/icons/ic_baseline-store.png",
    pathname: "Lekki Store",
    // route: "/dashboard/store",
    route: "/dashboard/",
  },
  {
    icon: "/assets/icons/ri_dashboard-line.png",
    pathname: "Dashboard",
    route: "/dashboard",
  },
  {
    icon: "/assets/icons/ant-design_dollar-circle-filled.png",
    pathname: "Sales",
    route: "/dashboard/sales",
  },
  {
    icon: "/assets/icons/icon-park-outline_transaction-order.png",
    pathname: "Order",
    route: "/dashboard/order",
  },

  {
    icon: "/assets/icons/users-2.png",
    pathname: "Customer",
    route: "/dashboard/customer",
  },

  {
    icon: "/assets/icons/bi_chat-dots-fill.png",
    pathname: "Messaging",
    route: "/dashboard/messaging",
  },

  {
    icon: "/assets/icons/clarity_settings-solid.png",
    pathname: "Settings",
    route: "/dashboard/settings",
  },
  {
    icon: "/assets/icons/websymbol_logout.png",
    pathname: "Logout",
    route: "/dashboard/logout",
  },
];

const SiderLinks = [
  {
    icon: "/assets/icons/ic_baseline-store.png",
    pathname: "Lekki Store",
    // route: "/dashboard/store",
    route: "/dashboard/",
  },
  {
    icon: "/assets/icons/ri_dashboard-line.png",
    pathname: "Dashboard",
    route: "/dashboard",
  },
  {
    icon: "/assets/icons/ant-design_dollar-circle-filled.png",
    pathname: "Sales",
    route: "/dashboard/sales",
  },
  {
    icon: "/assets/icons/icon-park-outline_transaction-order.png",
    pathname: "Order",
    route: "/dashboard/order",
  },
  {
    icon: "/assets/icons/ic_outline-inventory.png",
    pathname: "Payment",
    route: "/dashboard/payment",
  },
  {
    icon: "/assets/icons/users-2.png",
    pathname: "Customer",
    route: "/dashboard/customer",
  },
  {
    icon: "/assets/icons/user-1.png",
    pathname: "Staff",
    route: "/dashboard/staff",
  },
  {
    icon: "/assets/icons/bi_chat-dots-fill.png",
    pathname: "Messaging",
    route: "/dashboard/messaging",
  },
  {
    icon: "/assets/icons/fluent_record-20-regular.png",
    pathname: "Attendance",
    route: "/dashboard/attendance",
  },
  {
    iconm: <ReportRounded size={24} style={{ fontSize: "24px" }} />,
    pathname: "Service Report",
    route: "/dashboard/service-report",
  },
  {
    iconm: <NoteAddTwoTone size={24} style={{ fontSize: "24px" }} />,
    pathname: "Inventory",
    route: "/dashboard/inventory",
  },
  {
    iconm: <NoteAdd size={24} style={{ fontSize: "24px" }} />,
    pathname: "Expenses",
    route: "/dashboard/expenses",
  },
  {
    icon: "/assets/icons/clarity_settings-solid.png",
    pathname: "Settings",
    route: "/dashboard/settings",
  },

  {
    icon: "/assets/icons/websymbol_logout.png",
    pathname: "Logout",
    route: "/dashboard/logout",
  },
];

function Sidebar() {
  const logoImageRef = React.useRef(null);
  const user = useSelector((state) => state.User.user);

  const ActiveLinks =
    user?.role === "sales_staff"
      ? salesStaff
      : user?.role === "regular"
      ? regularStaff
      : SiderLinks;

  const dispatch = useDispatch();
  const location = useLocation();
  // console.info(location.pathname);
  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="100px"
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div>
        <Link
          style={{
            backgroundColor:
              location.pathname === "/dashboard" ? "#006c98" : "#0088c0",
          }}
          to={"/dashboard"}
        >
          <img className="logo" src={Logo} alt="Logo" ref={logoImageRef} />
        </Link>
      </div>

      <Menu mode="inline">
        {ActiveLinks.map(({ icon, pathname, route, iconm }, index) => {
          return pathname === "Sales" ? (
            <SubMenu
              key="sales"
              icon={<img src={icon} alt="menu-icon" />}
              title={pathname}
              className="subMenu"
            >
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === route ? "#006c98" : "#0088c0",
                }}
                key="allsales"
              >
                <Link to="/dashboard/sales">All Sales</Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/pending`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="pendingsales"
              >
                <Link to="/dashboard/sales/pending">Pending Sales</Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/drafts`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="draftsales"
              >
                <Link to="/dashboard/sales/drafts">Draft Sales</Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/report`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="salesreport"
              >
                <Link to="/dashboard/sales/report">Sales Report</Link>
              </Menu.Item>
            </SubMenu>
          ) : pathname === "Order" ? (
            <SubMenu
              key="order"
              icon={<img src={icon} alt="menu-icon" />}
              title={pathname}
              className="subMenu"
            >
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/neworders`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="neworders"
              >
                <Link to="/dashboard/order/neworders">New Orders</Link>
              </Menu.Item>
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/completed`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="completed"
              >
                <Link to="/dashboard/order/completed">Completed</Link>
              </Menu.Item>
            </SubMenu>
          ) : pathname === "Payment" ? (
            <SubMenu
              key="payment"
              icon={<img src={icon} alt="menu-icon" />}
              title={pathname}
              className="subMenu"
            >
              {user?.role === "super_admin" && (
                <Menu.Item
                  style={{
                    backgroundColor:
                      location.pathname === `${route}/payroll`
                        ? "#006c98"
                        : "#0088c0",
                  }}
                  key="payroll"
                >
                  <Link to="/dashboard/payment/payroll">Payroll</Link>
                </Menu.Item>
              )}
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/payout`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="payout"
              >
                <Link to="/dashboard/payment/payout">Payout</Link>
              </Menu.Item>
            </SubMenu>
          ) : pathname === "Messaging" ? (
            <SubMenu
              key="messaging"
              icon={<img src={icon} alt="menu-icon" />}
              title={pathname}
              className="subMenu"
            >
              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/new`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="payroll"
              >
                <Link to="/dashboard/messaging/new">New</Link>
              </Menu.Item>

              <Menu.Item
                style={{
                  backgroundColor:
                    location.pathname === `${route}/history`
                      ? "#006c98"
                      : "#0088c0",
                }}
                key="payout"
              >
                <Link to="/dashboard/messaging/history">History</Link>
              </Menu.Item>
            </SubMenu>
          ) : (
            <Menu.Item
              onClick={pathname === "Logout" ? logout : null}
              key={index.toString()}
              icon={iconm ? iconm : <img src={icon} alt="menu-icon" />}
              style={{
                backgroundColor:
                  location.pathname === route ? "#006c98" : "#0088c0",
              }}
            >
              <Link to={pathname !== "Logout" ? route : "/"}>{pathname}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
}

export default Sidebar;
