import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../action";

let slice = createSlice({
  name: "search",
  initialState: {
    isSearchActive: false,
    foundSearch: [],
    loading: false,
  },
  reducers: {
    fetchedSearchedSuccess: (searched, action) => {
      searched.loading = false;
      searched.isSearchActive = action.payload.length > 0 || false;
      searched.foundSearch = action.payload.data;
    },
    searchStart: (searched, action) => {
      searched.loading = true;
    },
    searchFailed: (searched, action) => {
      searched.loading = false;
    },
  },
});

const { fetchedSearchedSuccess, searchStart, searchFailed } = slice.actions;

export default slice.reducer;

export const fetchSearchData = () =>
  apiCallBegan({
    onSuccess: fetchedSearchedSuccess.type,
    onStart: searchStart.type,
    onError: searchFailed.type,
  });

//   selectors example

export const getSearched = (state) => state.search.foundSearch;
