import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { setServices } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import {} from "@mui/material";
import { ToggleOff, ToggleOn } from "@mui/icons-material";
import { Box } from "@mui/system";
import { createServiceApiCall, updateServiceApiCall } from "../../Api/service";

const AddEditServiceForm = ({
  visible,
  handleOk,
  handleCancel,
  item,
  selectedCategory,
}) => {
  const { name, price, minimum_stock, quantity, id } = item || {};
  const storeId = useSelector((state) => state.Store.selectedStore);
  const storeServices = useSelector((state) => state.Store.services || []);

  const [loading, setLoading] = useState();
  const [deductable, setDeductable] = useState(minimum_stock);

  const dispatch = useDispatch();
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
    price: yup.string().required("this field is required"),
    ...(!item && deductable
      ? {
          minimum_stock: yup.string().required("this field is required"),
          quantity: yup.string().required("this field is required"),
        }
      : deductable
      ? {
          minimum_stock: yup.string().required("this field is required"),
        }
      : {}),
  });

  const formik = useFormik({
    initialValues: {
      minimum_stock,
      name,
      price,
      quantity,
    },
    validationSchema,

    onSubmit: (values) => {
      console.info({ values });
      createItem(values);
    },
  });

  const createItem = async (values) => {
    const { name, price, minimum_stock, quantity } = values;
    setLoading(true);

    const res = id
      ? await updateServiceApiCall(storeId, id, {
          name,
          price,
          ...(deductable ? { minimum_stock } : {}),
        })
      : await createServiceApiCall(storeId, {
          name,
          price,
          service_category_id: selectedCategory,
          ...(deductable ? { minimum_stock, quantity } : {}),
        });

    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(setServices([resData, ...storeServices]));
      handleCancel();
      formik.resetForm();
      notification.notify("success", id ? "Item Updated" : "Item Created");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!visible) return false;
  const toggle = () => {
    setDeductable(!deductable);
  };

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>{item ? "Edit " : "Add "} Item</h4>
      <ItemForm onSubmit={formik.handleSubmit}>
        <label>Item Name</label>
        <input
          type="text"
          placeholder="Enter item name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}

        <label>Price</label>
        <input
          type="text"
          placeholder="Enter quantity"
          className="new-store-input"
          name="price"
          onBlur={formik.handleBlur}
          value={formik.values.price}
          onChange={formik.handleChange}
        />
        {formik.errors.price && formik.touched.price && (
          <div style={{ color: "red" }}> {formik.errors.price}</div>
        )}
        <label>Deductable</label>
        <Box onClick={toggle}>
          {deductable ? (
            <ToggleOn
              sx={{ fontSize: "50px", cursor: "pointer", color: "green" }}
            />
          ) : (
            <ToggleOff
              size={80}
              sx={{ fontSize: "50px", cursor: "pointer", color: "red" }}
            />
          )}{" "}
        </Box>
        {deductable && !item && (
          <Box>
            <label>Quantity</label>
            <input
              type="text"
              placeholder="Enter quantity"
              className="new-store-input"
              name="quantity"
              onBlur={formik.handleBlur}
              value={formik.values.quantity}
              onChange={formik.handleChange}
            />
            {formik.errors.quantity && formik.touched.quantity && (
              <div style={{ color: "red" }}> {formik.errors.quantity}</div>
            )}
          </Box>
        )}

        {deductable && (
          <Box>
            <label>Min Stock</label>
            <input
              type="text"
              placeholder="Enter quantity"
              className="new-store-input"
              name="minimum_stock"
              onBlur={formik.handleBlur}
              value={formik.values.minimum_stock}
              onChange={formik.handleChange}
            />
            {formik.errors.minimum_stock && formik.touched.minimum_stock && (
              <div style={{ color: "red" }}> {formik.errors.minimum_stock}</div>
            )}
          </Box>
        )}

        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </ItemForm>
    </Modal>
  );
};

export default AddEditServiceForm;

const ItemForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    ::placeholder {
      color: #acacac;
    }
  }
  .new-store-input,
  select {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
`;
