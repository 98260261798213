// import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import { Modal } from "antd";
import { LoadingButton } from "@mui/lab";
import {
  disputeOrderItemsApiCall,
  // resolveOrderItemsDisputeApiCall,
} from "../../Api/order";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAction } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { useFormik } from "formik";
import styled from "styled-components";
import { TextField } from "@mui/material";
import * as yup from "yup";

const OrderItemsModal = ({
  handleClose,
  visible,
  handleCancel,
  data,
  updateOrderStatus,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [dispute, setDispute] = useState(false);
  const storeId = useSelector((state) => state.Store.selectedStore);

  let validationSchema = yup.object({
    comment: yup.string().required("this field is required"),
  });

  const dispatch = useDispatch();
  const confirmItems = async (values) => {
    setLoading(true);
    await updateOrderStatus(data, "items-confirmed");
    handleCancel();
    setLoading(false);
    // const res = await resolveOrderItemsDisputeApiCall(storeId, data.id);
    // const { success, resData, error } = res;

    // console.info({ success, resData, error });
    // if (success) {
    //   await dispatch(fetchOrdersAction());
    //   handleCancel();

    //   notification.notify("success", "Order Updated");
    // }
    // setLoading(false);

    // if (error) notification.notify("error", parseEerror(error));
  };

  const disputeItems = async (values) => {
    setLoading(true);

    const res = await disputeOrderItemsApiCall(storeId, data.id, values);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchOrdersAction());
      handleCancel();

      notification.notify("success", "Order Updated");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  const formik = useFormik({
    initialValues: {
      comment: "",
    },

    onSubmit: (values) => {
      disputeItems(values);
    },
    validationSchema,
  });

  console.info({ data });

  if (!visible) return null;
  return (
    <Modal
      title={null}
      visible={visible}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <NewSalesContainer>
        {!dispute ? (
          <div
            style={{
              padding: "20px 50px",
              minHeight: "30vh",
              overflowY: "auto",
            }}
          >
            <h3>Items</h3>
            {data.items.map((v) => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ padding: "20px 0px" }}>
                  {" "}
                  <img
                    height="40px"
                    width="40px"
                    alt=""
                    src={v?.item?.image}
                    style={{ padding: "5px" }}
                  />
                  {v?.item?.name}
                </div>
                <div style={{ padding: "20px 0px" }}>{v?.quantity}</div>
              </div>
            ))}
            {type !== "view" && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <LoadingButton
                  loading={loading}
                  onClick={confirmItems}
                  loadingPosition="end"
                  className="submit-store-btn"
                  sx={{
                    width: "49%",
                    backgroundColor: "rgb(0, 136, 192)",
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  Confirm
                </LoadingButton>
                <LoadingButton
                  // loading={loading}
                  loadingPosition="end"
                  sx={{
                    width: "49%",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  className="submit-store-btn"
                  onClick={() => setDispute(true)}
                >
                  Dispute
                </LoadingButton>
              </div>
            )}
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <h3>Log Dispute</h3>
            <br />
            <TextField
              multiline
              minRows={5}
              label="Comment"
              variant="outlined"
              placeholder="Enter ID of customer"
              name="comment"
              id="comment"
              onBlur={formik.handleBlur}
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
              fullWidth
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <LoadingButton
                loading={loading}
                onClick={formik.submitForm}
                loadingPosition="end"
                className="submit-store-btn"
                sx={{
                  width: "49%",
                  backgroundColor: "rgb(0, 136, 192)",
                  color: "white",
                  borderRadius: "10px",
                }}
              >
                Confirm
              </LoadingButton>
              <LoadingButton
                // loading={loading}
                // onClick={formik.submitForm}
                loadingPosition="end"
                sx={{
                  width: "49%",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "10px",
                }}
                className="submit-store-btn"
                onClick={() => setDispute(false)}
              >
                Cancel
              </LoadingButton>
            </div>
          </form>
        )}
      </NewSalesContainer>
    </Modal>
  );
};

export default OrderItemsModal;

const NewSalesContainer = styled.div``;
