import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const orderUrl = (storeId) => `${BASE_URL}/${storeId}/items`;

export function getOrderItemsApiCall(storeId, query) {
  return request({ url: orderUrl(storeId), query });
}

export function createOrderItemApiCall(storeId, payload) {
  return request({ url: orderUrl(storeId), method: "post", payload });
}

export function deleteOrderItemApiCall(storeId, itemId) {
  return request({ url: `${orderUrl(storeId)}/${itemId}`, method: "delete" });
}

export function updateOrderItemApiCall(storeId, itemId, payload) {
  return request({
    url: `${orderUrl(storeId)}/${itemId}`,
    method: "put",
    payload,
  });
}
