/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import CustomSearch from "../../components/search";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomersAction,
  fetchMessagesAction,
  fetchSalesAction,
} from "../../store/reducers/store";
import { getMessageApiCall, sendMessageApiCall } from "../../Api/storApi";
import { parseEerror } from "../../helpers";
import notification from "../../common/Alert";
import { Redirect } from "react-router";
import { Delete } from "@mui/icons-material";
import { setLoading } from "../../store/reducers/app";

// import Salary from "./salary";

const MessageHistory = () => {
  const sales = useSelector((state) => state.Store.storeSales) || [];
  const storeId = useSelector((state) => state.Store.selectedStore);

  const [search, setSearch] = useState("");
  const messages = useSelector((state) => state.Store.messages);
  const preparedSales = sales
    .filter((v) => v.payment_method === "pending")
    ?.filter((v) =>
      search === ""
        ? true
        : v.customer?.name?.toLowerCase().includes(search.toLowerCase())
    );
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [selected, setSelected1] = useState({});
  const [recipients, setRecipients] = useState([]);
  const [recipientsLoading, setRecipientsLoading] = useState(false);

  const [selected2, setSelected2] = useState({});
  const [message, setMessage] = useState();
  const [currentTab, setCurrentTab] = useState("pending");
  const dispatch = useDispatch();
  const defaultStyles = {
    border: "1px solid #0088c0",
    cursor: "pointer",
    borderRadius: "3px",
    width: "50%",
    padding: "10px",
    textAlign: "center",
  };

  const handleChange = (id, val, name) => {
    console.info({ name, val });
    const temp = { ...selected };
    if (val) return setSelected1({ ...temp, [id]: name });
    delete temp[id];
    setSelected1({ ...temp });
    console.info({ temp });
  };

  const selectAll = (val) => {
    const temp = {};
    preparedSales.forEach((v) => (temp[v.customer_id] = v.customer.name));
    setSelected1(val ? { ...temp } : {});
    // setAllSelected({ ...allSelected, pending: preparedSales.length });
  };

  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  useEffect(() => {
    const payload = { page: currentPage };

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchMessagesAction(payload));
      // //console.info({ data });
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    if (currentTab !== "customer") return;
    if (search) payload.search = search;
    get(payload);
  }, [dispatch, search, currentPage]);

  useEffect(() => {
    if (!message?.id) return;
    setRecipientsLoading(true);
    const get = async (payload) => {
      const { success, resData } = await getMessageApiCall(
        storeId,
        message?.id,
        payload
      );
      setRecipientsLoading(false);

      if (resData && success) {
        setRecipients(resData?.recipients);
      }
    };

    get();
  }, [dispatch, message?.id]);

  useEffect(() => {
    dispatch(fetchMessagesAction());
  }, []);
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role !== "regular";

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    console.info({ bottom });
    if (bottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <MessageHistoryContainer>
      <div className="menu-left" style={{ height: "87vh", padding: 0 }}>
        <div className="bottomLeft" onScroll={handleScroll}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 0.1 }}>
              <Checkbox
                key={currentTab}
                checked={Object.keys(selected).length === preparedSales.length}
                onChange={(e) => selectAll(e.target.checked)}
              />
            </div>
            <div style={{ flex: 0.6 }}>Mark all</div>
            <div
              style={{
                flex: 0.3,
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              {selected?.length || 0} selected
              {/* <IconButton>
                <Delete
                  sx={{
                    color: Object.keys(selected).length > 0 ? "red" : "grey",
                  }}
                />
              </IconButton> */}
            </div>
          </div>

          {messages?.map((v) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 20,
                paddingTop: 20,
                borderBottom: "1px solid #D8D8D8",
              }}
            >
              <div style={{ flex: 0.1 }}>
                <Checkbox
                  checked={selected[v.customer_id] ?? false}
                  onChange={(e) =>
                    handleChange(
                      v.customer_id,
                      e.target.checked,
                      v.customer.name
                    )
                  }
                />
              </div>
              <div style={{ flex: 0.6 }}>
                <div
                  style={{
                    width: "20vw",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "20px",
                  }}
                >
                  {v.message}
                </div>
              </div>

              <div style={{ flex: 0.3 }}>
                <div style={{ fontWeight: "bold" }}>{v.type}</div>
                <div
                  onClick={() => setMessage(v)}
                  style={{ color: "#0088c0", cursor: "pointer" }}
                >
                  view
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="menu-right" style={{ height: "87vh", padding: 20 }}>
        {recipientsLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              lineHeight: "1.5em",
              height: "3em",
              overflow: "hidden",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Recipients:{" "}
            {recipients?.map((v, i) => (
              <span
                style={{
                  fontWeight: "normal",
                }}
              >
                {v?.customer?.name}
                {i + 1 === recipients.length ? "." : " ,"}
              </span>
            ))}
          </div>
        )}
        <div
          style={{
            fontSize: 20,
            marginTop: 0,
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ paddingRight: "20px", color: "darkgray" }}>
            {/* <span style={{ color: "black" }}>To: </span> */}
          </div>
        </div>

        <textarea
          id="w3review"
          name="w3review"
          rows="15"
          placeholder=""
          disabled
          defaultValue={message?.message}
          style={{
            border: "1px solid #c4c4c4",
            borderRadius: "5px",
            backgroundColor: "transparent",
            marginTop: "0.5rem",
            width: "100%",
            padding: 10,
          }}
        ></textarea>
        <div
          style={{
            width: "100%",
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <div
            style={{
              whiteSpace: "nowrap",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Delete
            <Delete />
          </div> */}
        </div>
      </div>
    </MessageHistoryContainer>
  );
};

export default MessageHistory;

const MessageHistoryContainer = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .menu-left {
    // background: #ffffff;
    border-radius: 10px;
    flex: 0.4;
    margin-right: 1rem;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 1rem;
      display: flex;
    }

    .topLeft {
      background: #ffffff;
      border-radius: 10px;
      flex: 0.25;
      margin-right: 1rem;
      color: #000;
      flex-direction: column;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      // @media screen and (max-width: 600px) {
      //   flex-direction: column;
      //   margin-right: 0;
      //   margin-bottom: 1rem;
      //   display: flex;
      // }
    }
    .bottomLeft {
      background: #ffffff;
      border-radius: 10px;
      flex: 0.73;
      margin-right: 1rem;
      padding: 10px;
      color: #000;
      min-height: 100%;
      overflow-y: auto;
      // @media screen and (max-width: 600px) {
      //   flex-direction: column;
      //   margin-right: 0;
      //   margin-bottom: 1rem;
      //   display: flex;
      // }
    }
  }
  .menu-right {
    flex: 0.6;
    background: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    height: 100vh;
  }
`;
