import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const staffsUrl = (storeId) => `${BASE_URL}/${storeId}/staffs`;
const staffsAttendance = (storeId) => `${staffsUrl(storeId)}/attendance`;

export function getAttendanceApiCall(storeId, query) {
  return request({ url: staffsAttendance(storeId), query });
}
export function clockInApiCall(storeId, data) {
  return request({
    url: `${staffsUrl(storeId)}/clockin`,
    method: "post",
    payload: data,
  });
}
export function clockOutApiCall(storeId, data) {
  return request({
    url: `${staffsUrl(storeId)}/clockout`,
    method: "post",
    payload: data,
  });
}
