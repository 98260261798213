/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, ButtonBase, IconButton } from "@mui/material";

import {
  ArrowBackIos,
  ArrowForwardIos,
  Delete,
  Edit,
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { fetchOrderItemsAction } from "../../store/reducers/store";
import { useDispatch } from "react-redux";
import CustomTable from "../../components/customTable";
import AddEditorderItemForm from "../../components/modals/addEditorderItem";
import { deleteOrderItemApiCall } from "../../Api/orderItem";

const Items = ({ selectedCategory }) => {
  const orderItems = useSelector((state) => state.Store.orderItems || []);
  const storeId = useSelector((state) => state.Store.selectedStore);
  const [show, setShow] = useState(null);
  const [selected, setSelected] = useState(null);

  var [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  var [lastPage, setLastPage] = useState(1);

  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderItemsAction());
  }, []);

  const editorderItem = (item) => {
    setSelected(item);
  };

  const handleShow = (item) => {
    setShow(!show);
  };

  const handleCancel = () => {
    setSelected();
    setShow();
  };

  const deleteorderItem = async (orderItemIndex) => {
    setLoading(orderItemIndex);
    const res = await deleteOrderItemApiCall(storeId, orderItemIndex);
    const { success, resData, error } = res;
    console.info({ success, resData, error });
    setLoading(null);
    if (success) {
      dispatch(fetchOrderItemsAction());
      notification.notify("success", "Item Deleted");
    }
    if (error) notification.notify("error", parseEerror(error));
    return;

    // const temp = [...filteredItems];
    // temp.splice(orderItemIndex, 1);
    // dispatch(setItems([...otherItems, ...temp]));
  };

  const tableCols = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#0088c0",
            cursor: "pointer",
          }}
        >
          <img
            height="50px"
            width="50px"
            alt=""
            src={image}
            style={{ borderRadius: "5px" }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: " ",
      dataIndex: "price",
      key: "price",
    },
    {
      title: " ",
      dataIndex: "current_stock",
      key: "current_stock",
    },
    {
      title: " ",
      dataIndex: "minimum_stock",
      key: "minimum_stock",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#0088c0",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={() => editorderItem(record)}>
            <Edit />
          </IconButton>

          {loading === record?.id ? (
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <CircularProgress size={16} />
            </IconButton>
          ) : (
            <IconButton onClick={() => deleteorderItem(record?.id)}>
              <Delete />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", flex: "0.7" }}>
      {(show || selected) && (
        <AddEditorderItemForm
          visible={show || selected}
          item={selected}
          handleCancel={handleCancel}
          selectedCategory={selectedCategory}
        />
      )}
      <Box></Box>
      <ItemTable>
        <div className="item-tools">
          <div className="search-container"></div>

          <button className="add-customer-btn" onClick={handleShow}>
            <img
              src="/assets/icons/add_circle_outline.png"
              alt="add customer"
            />
            Add New Item
          </button>
        </div>
        <CustomTable
          table_columns={tableCols}
          table_data={orderItems}
          table_pagination={false}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
            <ButtonBase
              sx={{
                color: "blue",
                backgroundColor: "grey",
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <ArrowBackIos />
            </ButtonBase>
            <Box
              sx={{
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              {currentPage}
            </Box>
            <ButtonBase
              sx={{
                color: "blue",
                backgroundColor: "grey",
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
              disabled={currentPage >= lastPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <ArrowForwardIos />
            </ButtonBase>
          </div>
        </div>
      </ItemTable>
    </Box>
  );
};

export default Items;

const ItemTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;
  .add-customer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 12rem;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--secondary-bg-color);
    color: #fff;
    border: none;

    img {
      margin-right: 0.5rem;
    }
    @media screen and (max-width: 480px) {
      .add-sale-btn {
        width: 10rem;
      }
    }
    @media screen and (max-width: 380px) {
      .add-sale-btn {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
    }
  }
  .item-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-container {
      margin-right: 1.5rem;
    }
    .add-new-item-btn {
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        width: 10rem;
      }
      @media screen and (max-width: 380px) {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }
`;
