import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import AddCustomerModal from "../../components/modals/addCustomerModal";
import CustomerEditForm from "../../components/modals/customerEditModal";
import CustomSearch from "../../components/search";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomersAction } from "../../store/reducers/store";
import {
  AccountBalanceWalletOutlined,
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import { ButtonBase, IconButton } from "@mui/material";
import CustomerWalletEditModal from "../../components/modals/customerWalletEditModal";
import { Box } from "@mui/system";
import { useLocation, useHistory } from "react-router-dom";

const Customer = () => {
  const customers = useSelector((state) => state.Store.customers) || [];
  const [showModal, setShowModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showEditCustomerModal, setshowEditCustomerModal] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [query, setQuery] = useState(null);
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  // user privilage check
  // const { role } = useSelector((state) => state?.User?.user || {});

  const location = useLocation();
  const { search, pathname } = location;
  const history = useHistory();

  useEffect(() => {
    if (search === "?add-new") setShowModal(true);
  }, [search]);

  useEffect(() => {
    if (search === "?add-new" && showModal) history.replace(pathname);
  }, [history, pathname, search, showModal]);

  // const hasPrivilage = role === "sub_admin" || role === "super_admin";
  const hasPrivilage = true;

  const dispatch = useDispatch();

  const tableCols = [
    {
      title: "ID",
      dataIndex: "customer_id",
      key: "customer_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // render: () => <img src="/assets/icons/edit.png" alt="edit" />,
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/icons/fe_edit.png"
            alt="edit_icon"
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => selectCustomer(record)}
          />
          {hasPrivilage && (
            <IconButton onClick={() => openCustomerWallet(record)}>
              <AccountBalanceWalletOutlined sx={{ color: "#0088c0" }} />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const payload = { page: currentPage };

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchCustomersAction(payload));
      // //console.info({ data });
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    if (query) payload.search = query;
    get(payload);
  }, [dispatch, query, currentPage]);

  const handleChange = async (data) => {
    // add page no to query
    // if (data != "") setCurrentPage(1);
    setQuery(data);
    //console.info("test");
  };

  const selectCustomer = (record) => {
    setCustomerData(record);
    setshowEditCustomerModal(true);
  };

  const closeEditCustomer = (record) => {
    setshowEditCustomerModal(false);
    setCustomerData(null);
  };

  const openCustomerWallet = (record) => {
    setCustomerData(record);
    setShowWalletModal(true);
  };

  const closeCustomerWallet = (record) => {
    setShowWalletModal(false);
    setCustomerData(null);
  };

  return (
    <CustomerTable>
      <AddCustomerModal
        visible={showModal}
        handleCancel={() => setShowModal(false)}
      />
      {showEditCustomerModal && (
        <CustomerEditForm
          visible={showEditCustomerModal}
          handleCancel={closeEditCustomer}
          data={customerData}
        />
      )}
      {showWalletModal && (
        <CustomerWalletEditModal
          visible={showWalletModal}
          handleCancel={closeCustomerWallet}
          data={customerData}
        />
      )}

      <div className="customer-tools">
        <div className="search-container">
          <CustomSearch
            handleSearch={handleChange}
            search_placeholder="Search Customer"
          />
        </div>
        <button
          className="add-customer-btn"
          onClick={() => {
            if (showModal) {
              setShowModal(false);
            } else {
              setShowModal(true);
            }
          }}
        >
          <img src="/assets/icons/add_circle_outline.png" alt="add customer" />
          Add New Customer
        </button>
      </div>
      <CustomTable
        table_columns={tableCols}
        table_data={customers}
        // table_pagination={{
        //   defaultPageSize: 9,
        //   simple: true,
        // }}
        row_class_name={"row-class"}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </CustomerTable>
  );
};

export default Customer;

const CustomerTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;
  .customer-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-container {
      margin-right: 1.5rem;
    }
    .add-customer-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
