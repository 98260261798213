/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, ButtonBase, IconButton } from "@mui/material";

import {
  ArrowBackIos,
  ArrowForwardIos,
  Delete,
  Edit,
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { deleteServiceApiCall } from "../../Api/service";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { fetchServicesAction } from "../../store/reducers/store";
import { useDispatch } from "react-redux";
import CustomTable from "../../components/customTable";
import AddEditServiceForm from "../../components/modals/addEditService";

const SalesService = ({ selectedCategory }) => {
  const storeServices = useSelector((state) => state.Store.services || []);
  const storeId = useSelector((state) => state.Store.selectedStore);
  const [show, setShow] = useState(null);
  const [selected, setSelected] = useState(null);

  var [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const preFilteredServices = storeServices.filter(
    (v) => v.service_category_id === selectedCategory
  );
  // eslint-disable-next-line no-unused-vars
  var [lastPage, setLastPage] = useState(
    storeServices ? preFilteredServices.length / 10 : 1
  );
  const filteredServices = preFilteredServices?.slice(
    (currentPage - 1) * 10,
    (currentPage - 1) * 10 + 10
  );

  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchServicesAction());
  }, []);

  const editService = (item) => {
    setSelected(item);
  };

  const handleShow = (item) => {
    setShow(!show);
  };

  const handleCancel = () => {
    setSelected();
    setShow();
  };

  const deleteService = async (serviceIndex) => {
    setLoading(serviceIndex);
    const res = await deleteServiceApiCall(storeId, serviceIndex);
    const { success, resData, error } = res;
    console.info({ success, resData, error });
    setLoading(null);
    if (success) {
      dispatch(fetchServicesAction());
      notification.notify("success", "Service Deleted");
    }
    if (error) notification.notify("error", parseEerror(error));
    return;

    // const temp = [...filteredServices];
    // temp.splice(serviceIndex, 1);
    // dispatch(setServices([...otherServices, ...temp]));
  };

  const tableCols = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price ",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Stock ",
      dataIndex: "current_stock",
      key: "current_stock",
    },
    {
      title: "Min Stock ",
      dataIndex: "minimum_stock",
      key: "minimum_stock",
    },

    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "status",
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text?.substring(0, 16)}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#0088c0",
            cursor: "pointer",
          }}
        >
          <IconButton onClick={() => editService(record)}>
            <Edit />
          </IconButton>

          {loading === record?.id ? (
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <CircularProgress size={16} />
            </IconButton>
          ) : (
            <IconButton onClick={() => deleteService(record?.id)}>
              <Delete />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px", flex: "0.7" }}>
      {(show || selected) && (
        <AddEditServiceForm
          visible={show || selected}
          item={selected}
          handleCancel={handleCancel}
          selectedCategory={selectedCategory}
        />
      )}
      <Box>
        {/* <Grid sx={{ p: 7 }} container>
        <Grid
          sx={{ display: "flex", justifyContent: "center", p: 3 }}
          item
          sm={12}
        >
          <Typography variant="h5">Services</Typography>
        </Grid>

        {filteredServices.map((v, i) => (
          <Grid key={`${i}${v.name}${v.price}`} item sx={12} container>
            <Grid item sm={6} sx={{ paddingRight: 2 }}>
              <div className="input">
                <input
                  placeholder="Enter Services"
                  name="name"
                  id="name"
                  defaultValue={v.name}
                  onBlur={(e) => handleChange(i, "name", e.target.value)}
                  key={`${i}${v.name}${v.price}${selectedCategory}`}
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    borderRadius: "5px",
                    padding: "0.5rem",
                    outline: "none",
                    background: "transparent",
                  }}
                />
              </div>
            </Grid>
            <Grid item sm={2.5} sx={{ paddingRight: 1 }}>
              <div className="input">
                <input
                  // type={showPassword}
                  key={`${i}${v.name}${v.price}${selectedCategory}`}
                  defaultValue={v.price}
                  placeholder="Enter Price"
                  name="price"
                  id="price"
                  onBlur={(e) => handleChange(i, "price", e.target.value)}
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    borderRadius: "5px",
                    padding: "0.5rem",
                    outline: "none",
                    background: "transparent",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    // marginLeft: "10px",
                  }}
                />
              </div>
            </Grid>
            {!v.minimum_stock && (
              <Grid item sm={2.5} sx={{ paddingRight: 1 }}>
                <div className="input">
                  <input
                    // type={showPassword}
                    key={`${i}${v.name}${v.price}${selectedCategory}`}
                    defaultValue={v.quantity}
                    placeholder="Enter quantity"
                    name="quantity"
                    id="quantity"
                    onBlur={(e) => handleChange(i, "quantity", e.target.value)}
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      padding: "0.5rem",
                      outline: "none",
                      background: "transparent",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      // marginLeft: "10px",
                    }}
                  />
                </div>
              </Grid>
            )}
            <Grid
              item
              sm={1}
              sx={{
                // paddingLeft: 2,
                // paddingRight: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {loading === `s${i}` ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <CircularProgress size={16} />
                </Box>
              ) : (
                <Box
                  sx={{
                    color: "green",
                    backgroundColor: "lightGreen",
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                  onClick={() => saveService(i)}
                >
                  <Save />
                </Box>
              )}
              {loading === `d${i}` ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <CircularProgress size={16} />
                </Box>
              ) : (
                <Box
                  sx={{
                    color: "red",
                    backgroundColor: "#FFCACA",
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                
                >
                  <Close />
                </Box>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        sx={{ display: "flex", justifyContent: "center", p: 3 }}
        item
        sm={12}
        container
      >
        <Grid
          sx={{ display: "flex", justifyContent: "center", p: 1 }}
          item
          sm={12}
        >
          <LoadingButton
            sx={{ color: "white", backgroundColor: "#0088c0", width: "50%" }}
            onClick={addService}
          >
            Add New Service
          </LoadingButton>
        </Grid>
      </Grid> */}
      </Box>
      <ItemTable>
        {/* {showEditStaffModal && (
          <StaffEditForm
            visible={showEditStaffModal}
            handleCancel={closeEditStaff}
            staffData={staffData}
          />
        )} */}
        {/* <SalesReceipt
        visible={showReceiptModal}
        handleCancel={() => setshowReceiptModal(false)}
      /> */}

        <div className="item-tools">
          <div className="search-container">
            {/* <CustomSearch
              // handleSearch={setQuery}
              search_placeholder="Search Item"
            /> */}
          </div>

          <button className="add-customer-btn" onClick={handleShow}>
            <img
              src="/assets/icons/add_circle_outline.png"
              alt="add customer"
            />
            Add New Service
          </button>
        </div>
        <CustomTable
          table_columns={tableCols}
          table_data={filteredServices}
          // table_pagination={{
          //   defaultPageSize: 9,
          //   simple: true,
          // }}
          table_pagination={false}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
            <ButtonBase
              sx={{
                color: "blue",
                backgroundColor: "grey",
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <ArrowBackIos />
            </ButtonBase>
            <Box
              sx={{
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              {currentPage}
            </Box>
            <ButtonBase
              sx={{
                color: "blue",
                backgroundColor: "grey",
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
              disabled={currentPage >= lastPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <ArrowForwardIos />
            </ButtonBase>
          </div>
        </div>
      </ItemTable>
    </Box>
  );
};

export default SalesService;

const ItemTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;
  .add-customer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 12rem;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--secondary-bg-color);
    color: #fff;
    border: none;

    img {
      margin-right: 0.5rem;
    }
    @media screen and (max-width: 480px) {
      .add-sale-btn {
        width: 10rem;
      }
    }
    @media screen and (max-width: 380px) {
      .add-sale-btn {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
    }
  }
  .item-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-container {
      margin-right: 1.5rem;
    }
    .add-new-item-btn {
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        width: 10rem;
      }
      @media screen and (max-width: 380px) {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }
`;
