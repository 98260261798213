import React from "react";
import { Modal } from "antd";
import { LoadingButton } from "@mui/lab";

const ConfirmationModal = ({
  visible,
  handleOk,
  handleCancel,
  message,
  confirmText,
}) => {
  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      // closable={false}
    >
      <p>{message}</p>

      <div>
        <LoadingButton onClick={handleCancel}>Cancel</LoadingButton>
        <LoadingButton onClick={handleOk}>{confirmText}</LoadingButton>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
