import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/notfound";
import Dashboard from "./dashboard";
import Login from "../pages/auth/login";
import ResetPassword from "../pages/auth/resetPassword";
import ForgotPassword from "../pages/auth/forgotPassword";
import "antd/dist/antd.css";
import Config from "../pages/config";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/dashboard*" component={Dashboard} />
      <Route exact path="/" component={Login} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/config" component={Config} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
