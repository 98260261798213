import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearch from "../../components/search";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAction } from "../../store/reducers/store";
import {
  patchOrderApiCall,
  resolveOrderItemsDisputeApiCall,
} from "../../Api/order";
import { parseEerror } from "../../helpers";
import notification from "../../common/Alert";
import SetPrice from "../../components/modals/setPrice";
import { Redirect } from "react-router";
import OrderItemsModal from "../../components/modals/viewOrderItemsModal";
import { ButtonBase, CircularProgress } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/system";

const NewOrders = () => {
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.Store.selectedStore);
  const [query, setQuery] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [resolving, setResolving] = useState(false);
  const [setPriceVisible, setSetPriceVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [data, setData] = useState(null);
  const orders = useSelector((state) => state.Store.orders) || [];
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  const preparedOrders = orders.map((v) => ({
    ...v,
    phone: v.customer?.phone,
    name: v.customer?.name,
    payment: v.payment_method,
    item: v.items,
    amount: v.price,
  }));

  const orderTableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Service",
      dataIndex: "service_type",
      key: "service",
    },
    {
      title: "Service Types",
      dataIndex: "service_types",
      key: "service_types",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) =>
        updating === record.id ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {text === "pending" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
              >
                <option disabled value="pending">
                  Pending
                </option>
                <option value="enroute">EnRoute</option>
              </select>
            )}
            {text === "enroute" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
                disabled
              >
                <option value="enroute">EnRoute</option>
              </select>
            )}
            {text === "items-confirmed" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
              >
                <option disabled value="items-confirmed">
                  Items Confirmed
                </option>
                <option value="processing">Processing</option>
              </select>
            )}
            {text === "processing" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
                disabled
              >
                <option value="processing">Processing</option>
              </select>
            )}
            {text === "paid" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
              >
                <option disabled value="paid">
                  Paid
                </option>
                <option value="washing">Washing</option>
              </select>
            )}
            {text === "washing" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
              >
                <option disabled value="washing">
                  Washing
                </option>
                <option value="delivering">Delivering</option>
              </select>
            )}
            {text === "delivering" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
              >
                <option disabled value="delivering">
                  Delivering
                </option>
                <option value="done">Done</option>
              </select>
            )}
            {/* {text === "done" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
                disabled
              >
                <option value="done">Done</option>
              </select>
            )} */}
            {/* {text === "pending" && (
              <select
                value={text}
                onChange={(e) => updateOrderStatus(record, e.target.value)}
                style={{ borderColor: "#c4c4c4", borderRadius: "5px" }}
                // disabled={text === "pending" || text === "items-dispute"}
              >
                {text === "pending" && <option value="pending">Pending</option>}
                {text === "pending" && (
                  <option value="enroute">En-Route</option>
                )}
                {text === "items-dispute" && (
                  <option value="items-dispute">Items Dispute</option>
                )}
                <option value="washing">DELIVERING</option>
                <option value="processing">Processing</option>
                <option value="enroute">Enroute</option>
                <option value="completed">Completed</option>
              </select>
            )} */}
          </>
        ),
    },

    {
      title: "Action",
      dataIndex: "price",
      key: "price",
      render: (text, order) =>
        resolving === order.id ? (
          <CircularProgress size={20} />
        ) : (
          <div>
            {order?.status === "items-dispute" && (
              <button
                className="text-light pointer btn btn-sm"
                style={{
                  background: "#0088C0",
                }}
                loadingPosition="end"
                sx={{
                  width: "49%",
                  backgroundColor: "rgb(0, 136, 192)",
                  color: "white",
                  borderRadius: "10px",
                }}
                onClick={() => resolveDispute(order.id)}
              >
                Resolve
              </button>
            )}
            {order?.status === "enroute" && (
              <button
                className="text-light pointer btn btn-sm"
                style={{
                  background: "#0088C0",
                }}
                onClick={() => handleView(order)}
              >
                View Items
              </button>
            )}
            {order?.price === null && order?.status === "processing" && (
              <button
                className="text-light pointer btn btn-sm"
                style={{
                  background: "#0088C0",
                }}
                onClick={() => handleSetPrice(order)}
              >
                Set Price
              </button>
            )}
          </div>
        ),
    },
  ];

  const updateOrderStatus = async (record, value) => {
    // setStaffData(record);
    setUpdating(record.id);
    const storeId = record.store_id;
    const res = await patchOrderApiCall(storeId, record?.id, {
      status: value,
    });
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchOrdersAction({ new: true }));
    }
    notification.notify("success", "Order Status Updated");
    setUpdating(null);

    if (error) notification.notify("error", parseEerror(error));
  };

  // useEffect(() => {
  //   const get = async () => {
  //     // add page no to query
  //     const data = await dispatch(
  //       fetchOrdersAction(query ? query : { new: true, page: currentPage })
  //     );
  //     var lastPageNo = data?.meta?.last_page;
  //     if (lastPageNo) {
  //       setLastPage(lastPageNo);
  //       if (currentPage > lastPageNo) setCurrentPage(1);
  //     }
  //   };
  //   get();
  // }, [dispatch, query, currentPage]);

  useEffect(() => {
    const payload = { new: true, page: currentPage };

    if (query && query !== "") payload.search = query;

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchOrdersAction(payload));
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    get(payload);
  }, [dispatch, query, currentPage]);

  const handleSetPrice = (order) => {
    setData(order);
    setSetPriceVisible(true);
  };

  const closeSetPrice = (order) => {
    setSetPriceVisible(false);
    setData(null);
  };

  const handleView = (order) => {
    setData(order);
    setViewVisible(true);
  };

  const closeView = (order) => {
    setViewVisible(false);
    setData(null);
  };
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role !== "regular";

  const resolveDispute = async (id) => {
    setResolving(id);

    const res = await resolveOrderItemsDisputeApiCall(storeId, id);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchOrdersAction());

      notification.notify("success", "Order Updated");
    }
    setResolving();

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <SalesTable>
      {setPriceVisible && (
        <SetPrice
          visible={setPriceVisible}
          data={data}
          handleCancel={closeSetPrice}
        />
      )}
      {viewVisible && (
        <OrderItemsModal
          visible={viewVisible}
          data={data}
          handleCancel={closeView}
          updateOrderStatus={updateOrderStatus}
        />
      )}
      <div className="order-tools">
        <div className="search-order">
          <CustomSearch
            handleSearch={setQuery}
            search_placeholder="Search Order"
          />
        </div>
      </div>
      <CustomTable
        table_columns={orderTableCols}
        table_data={preparedOrders}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </SalesTable>
  );
};

export default NewOrders;

const SalesTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .order-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;
  }
`;
