import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { createExpensesApiCall } from "../../Api/expense";
import { fetchExpensesAction } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";

const AddExpenseForm = ({ visible, handleOk, handleCancel }) => {
  const storeId = useSelector((state) => state.Store.selectedStore);

  const [loading, setLoading] = useState();

  const dispatch = useDispatch();
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
    amount: yup.string().required("this field is required"),
    description: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      amount: "",
      description: "",
    },
    validationSchema,

    onSubmit: (values) => {
      console.info({ values });
      createExpense(values);
    },
  });
  const createExpense = async (values) => {
    setLoading(true);

    delete values.store;
    const res = await createExpensesApiCall(storeId, values);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchExpensesAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Expense Created");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Add Expense</h4>
      <ExpenseForm onSubmit={formik.handleSubmit}>
        <label>Item Name</label>
        <input
          type="text"
          placeholder="Enter item name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}

        <label>Description</label>
        <input
          type="text"
          placeholder="Enter description"
          className="new-store-input"
          name="description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
        {formik.errors.description && formik.touched.description && (
          <div style={{ color: "red" }}> {formik.errors.description}</div>
        )}

        <label>Amount</label>
        <input
          type="text"
          placeholder="Enter amount"
          className="new-store-input"
          name="amount"
          onBlur={formik.handleBlur}
          value={formik.values.amount}
          onChange={formik.handleChange}
        />
        {formik.errors.amount && formik.touched.amount && (
          <div style={{ color: "red" }}> {formik.errors.amount}</div>
        )}
        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </ExpenseForm>
    </Modal>
  );
};

export default AddExpenseForm;

const ExpenseForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    ::placeholder {
      color: #acacac;
    }
  }
  .new-store-input,
  select {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
`;
