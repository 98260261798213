import { createSlice } from "@reduxjs/toolkit";
import { clearStoresAction } from "../store";
const initialState = {
  loggedIn: false,
  user: null,
};

let slice = createSlice({
  name: "User",
  initialState,
  reducers: {
    addUserAction(state, action) {
      return (state = { ...action.payload });
    },
    updateUserAction(state, action) {
      return (state = { ...state, ...action.payload });
    },
    clearUserAction(state, action) {
      return (state = {});
    },
  },
});

export const { addUserAction, updateUserAction, clearUserAction } =
  slice.actions;

export default slice.reducer;

export const logoutAction = () => async (dispatch) => {
  localStorage.removeItem("washRyteToken2022");
  dispatch(clearUserAction());
  dispatch(clearStoresAction());
};
