import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearchDate from "../../components/search/dateSearch";
import AddExpenseForm from "../../components/modals/addExpense";
import ExpensesReceipt from "../../components/modals/receiptModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchExpensesAction } from "../../store/reducers/store";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { ExportToExcel } from "../../components/exportFile";
import { Redirect } from "react-router";
import { Box } from "@mui/system";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Expenses = () => {
  const dispatch = useDispatch();
  const expenses = useSelector((state) => state.Store.expenses) || [];
  var [lastPage, setLastPage] = useState(1);

  // const banksHash = hashTable.banks || {};
  // console.info()
  const preparedExpenses = expenses.map((v) => ({
    ...v,
    username: v.user?.name,
    role: v.user?.role,
  }));
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [salesData, setExpensesData] = useState(null);

  const [showReceiptModal, setshowReceiptModal] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateT0, setDateT0] = useState(null);
  var [currentPage, setCurrentPage] = useState(1);

  const salesTableCols = [
    {
      title: "Reference",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "User name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "User role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text.replace("_", " ")}
        </div>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "",
    //   key: "",
    //   render: (text, record) => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <img
    //         src="/assets/icons/ion_receipt-outline.png"
    //         alt="edit_icon"
    //         style={{ cursor: "pointer" }}
    //         onClick={async () => {
    //           printExpensesData(record);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
  ];
  const get = async () => {
    const payload = { page: currentPage };
    let data;
    if (dateT0 && dateFrom)
      data = await dispatch(
        fetchExpensesAction({
          date_range: true,
          start: dateFrom,
          end: dateT0,
        })
      );
    else if (dateT0 || dateFrom) return;
    else data = await dispatch(fetchExpensesAction(payload));

    var lastPageNo = data?.meta?.last_page;
    if (lastPageNo) {
      setLastPage(lastPageNo);
      if (currentPage > lastPageNo) setCurrentPage(1);
    }
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dateT0, dateFrom]);

  const getTotal = () => {
    let total = 0;
    preparedExpenses.forEach((v) => (total += parseFloat(v.amount)));
    return total;
  };
  // const printExpensesData = (record) => {
  //   setExpensesData(record);
  //   setshowReceiptModal(true);
  // };

  const closeAddExpense = () => {
    setShowAddExpenseModal(false);
    setExpensesData(null);
  };

  const closePrintExpenses = () => {
    setshowReceiptModal(false);
    setExpensesData(null);
  };
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <ExpenseTable>
      {showAddExpenseModal && (
        <AddExpenseForm
          visible={showAddExpenseModal}
          handleCancel={closeAddExpense}
          salesData={salesData}
        />
      )}
      {showReceiptModal && (
        <ExpensesReceipt
          visible={showReceiptModal}
          handleCancel={closePrintExpenses}
          salesData={salesData}
        />
      )}
      <div className="sales-tools">
        {/* <Typography variant="h5" sx={{ marginRight: 2 }}>
          Expenses For {date ? months[new Date(date).getMonth()] : month},
          {date ? new Date(date).getFullYear() : d.getFullYear()}
        </Typography> */}
        <Grid container>
          <Grid item xs={12} sm={6} md={3} sx={{ paddingRight: "10px" }}>
            <div
              className="sales-date"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ p: 1 }}>From </Typography>
              <CustomSearchDate
                handleSearch={setDateFrom}
                search_placeholder="Expenses Date"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              paddingRight: "10px",
            }}
          >
            <div
              className="sales-date"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ p: 1 }}>To </Typography>
              <CustomSearchDate
                handleSearch={setDateT0}
                search_placeholder="Expenses Date"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ width: "80%" }}>
              <button
                className="add-sale-btn"
                onClick={() => setShowAddExpenseModal(true)}
              >
                <img
                  src="/assets/icons/add_circle_outline.png"
                  alt="add store"
                />
                Add Expense
              </button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ExportToExcel
              apiData={preparedExpenses}
              fileName="sales report"
              text_name="Report"
            />
          </Grid>
        </Grid>
      </div>
      <CustomTable
        table_columns={salesTableCols}
        table_data={preparedExpenses}
        table_pagination={false}
      />

      <div className="report-total">
        <p className="total-text">Total</p>
        <p>N{getTotal()}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </ExpenseTable>
  );
};

export default Expenses;

const ExpenseTable = styled.div`
  .report-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 1.2rem 4rem;
    font-size: 1.125rem;

    p {
      margin: 0;
      font-weight: 700;
    }
    .total-text {
      font-weight: 700;
    }
  }
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-sales,
    .sales-date {
      margin-right: 1rem;
    }
    .sales-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
