import React from "react";
import "./index.css";

const DashboardCard = ({ number_of_activities, tags, bgColor, action }) => {
  return (
    <div
      onClick={action}
      className="dashboard-overview-card"
      style={{ backgroundColor: bgColor, cursor: "pointer" }}
    >
      <p className="dashboard-overview-card_number">{number_of_activities}</p>
      <p className="dashboard-overview-card_text">{tags}</p>
    </div>
  );
};

export default DashboardCard;
