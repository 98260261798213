import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import debounce from "lodash.debounce";

const CustomSearch = ({ search_placeholder, handleSearch = () => {} }) => {
  const [inputVal, setInputVal] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getResult = useCallback(
    debounce(
      (val) => {
        handleSearch(val);
      },
      [1000]
    ),
    [handleSearch]
  );

  useEffect(() => {
    console.info("sss");
    getResult(inputVal);
  }, [getResult, inputVal]);

  return (
    <SearchForm method="get" action="/">
      <i className="fa fa-search" type="submit"></i>
      <input
        type="text"
        value={inputVal}
        onBlur={(e) => handleSearch(e.target.value)}
        placeholder={search_placeholder}
        name="search"
        onChange={(e) => setInputVal(e.target.value)}
      />
    </SearchForm>
  );
};

export default CustomSearch;

const SearchForm = styled.form`
  border: 1px solid #919191;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center;
  input {
    flex: 8;
    border: none;
    outline: none;
    background: transparent;
    ::placeholder {
      color: #6f6f6f;
    }
  }
  i {
    margin-right: 0.5rem;
    color: #0088c0;
  }
`;
