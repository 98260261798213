import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ServiceSale = ({
  setServices,
  services: selectedServices = [],
  serviceCategoryId,
}) => {
  const services =
    useSelector((state) =>
      state.Store.services.filter(
        (v) => v.service_category_id === serviceCategoryId
      )
    ) || [];
  const selectedServicesHash = {};
  selectedServices.forEach((v) => {
    selectedServicesHash[v.service_id] = v;
  });
  const addItem = (itemId) => {
    const qty = selectedServicesHash[itemId]?.quantity || 0;
    if (qty === 0)
      selectedServicesHash[itemId] = { service_id: itemId, quantity: 1 };
    else selectedServicesHash[itemId].quantity = qty + 1;
    setServices(Object.values(selectedServicesHash));
  };
  const removeItem = (itemId) => {
    const qty = selectedServicesHash[itemId]?.quantity;
    if (!qty) return;
    if (qty === 1) delete selectedServicesHash[itemId];
    else selectedServicesHash[itemId].quantity = qty - 1;
    setServices(Object.values(selectedServicesHash));
  };
  return (
    <ServiceContainer>
      {services.map((item, index) => {
        return (
          <div className="service-item" key={index}>
            <div className="item-info">
              <p className="item-name">{item.name}</p>
              <p>N{item.price}</p>
            </div>
            <div className="service-tool">
              <button
                className="remove-btn"
                onClick={() => removeItem(item.id)}
              >
                <img src="/assets/icons/remove.png" alt="remove" />
              </button>
              <p>{selectedServicesHash[item.id]?.quantity || 0}</p>

              <button className="add-btn" onClick={() => addItem(item.id)}>
                <img src="/assets/icons/add.png" alt="add" />
              </button>
            </div>
          </div>
        );
      })}
    </ServiceContainer>
  );
};

export default ServiceSale;

const ServiceContainer = styled.div`
  .service-item {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    p {
      margin: 0;
    }
    .item-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-right: 1rem;
      .item-name {
        margin-right: 5px;
      }
    }
    .service-tool {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.125rem;
      p {
        margin-right: 1.5rem;
      }

      button {
        border: none;
        outline: none;
        width: 40px;
        height: 40px;

        border-radius: 5px;
      }
      .remove-btn {
        background: #dadada;
        color: #919191;
        margin-right: 1.5rem;
      }
      .add-btn {
        background: #0088c0;
        color: #fff;
      }
    }
  }
`;
