import React, { useState } from "react";
import styled from "styled-components";

const CustomSearchDate = ({ search_placeholder, handleSearch }) => {
  const [inputVal, setInputVal] = useState("");
  // const [inputType, setInputType] = useState("text");

  const handleChange = (e) => {
    handleSearch(e.target.value);
    setInputVal(e.target.value);
  };

  return (
    <SearchForm method="get" action="/">
      <input
        type="date"
        value={inputVal}
        placeholder="date"
        name="search"
        onChange={(e) => handleChange(e)}
        required
      />
    </SearchForm>
  );
};

export default CustomSearchDate;

const SearchForm = styled.form`
  border: 1px solid #919191;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center;
  width: 90%;
  margin-right: 10%;
  input {
    flex: 8;
    width: 90%;

    border: none;
    outline: none;
    ::placeholder {
      color: #6f6f6f;
    }
    ${
      "" /* :before {
      content: "\f073";
      color: var(--secondary-txt-color);
      margin-right: 0.5rem;
    } */
    }
  }
  ::-webkit - datetime - edit - year - field: not([aria - valuenow]), ::-webkit - datetime - edit - month - field: not([aria - valuenow]), ::-webkit - datetime - edit - day - field: not([aria - valuenow]) {
    color: transparent;
  }
`;
