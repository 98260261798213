import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const DiscountSale = ({ discounts: selectedDiscouts = [], setDiscounts }) => {
  const discounts = useSelector((state) => state.Store.discounts) || [];
  const selectedDiscoutsHash = {};
  selectedDiscouts.forEach((v) => {
    selectedDiscoutsHash[v.discount_id] = v;
  });

  const remove = (itemId) => {
    delete selectedDiscoutsHash[itemId];

    setDiscounts(Object.values(selectedDiscoutsHash));
  };

  const add = (itemId) => {
    selectedDiscoutsHash[itemId] = { discount_id: itemId };

    setDiscounts(Object.values(selectedDiscoutsHash));
  };

  return (
    <DiscountContainer>
      {discounts.map((item, index) => {
        return (
          <div className="discount-item" key={index}>
            <p>{item.name}</p>

            <div className="discoun-tool">
              <p>{item.percenttage}</p>
              {selectedDiscoutsHash[item.id] ? (
                <button onClick={() => remove(item.id)}>Remove</button>
              ) : (
                <button onClick={() => add(item.id)}>Add</button>
              )}
            </div>
          </div>
        );
      })}
    </DiscountContainer>
  );
};

export default DiscountSale;

const DiscountContainer = styled.div`
  .discount-item {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    p {
      margin: 0;
    }

    .discoun-tool {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-right: 1.5rem;
      }
      button {
        background: transparent;
        border: none;
        outline: none;
      }
    }
  }
`;
