import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { timeSince } from "../../helpers";

const NotificationModal = ({ closeNotification, visible }) => {
  const notifications = useSelector((state) => state.Store.notifications) || [];

  if (!visible) return null;
  return (
    <NotificatioBox>
      <div className="notification-header">
        <p>Notification</p>
        <img
          src="/assets/icons/close.png"
          alt="close"
          onClick={closeNotification}
        />
      </div>

      <div className="notifications">
        {notifications.map((item, index) => {
          return (
            <div key={index} className="notification">
              <div className="img-sec">
                {
                  <Avatar
                    src={
                      item.message === "Receipt successfully printed"
                        ? "/assets/icons/receiptimg.png"
                        : item.pic
                    }
                    alt={item.message}
                    sx={{ marginRight: 3 }}
                  />
                }
                <p>{item.message}</p>
              </div>
              <p className="time">{timeSince(item?.created_at)} ago</p>
            </div>
          );
        })}
      </div>
    </NotificatioBox>
  );
};

export default NotificationModal;

const NotificatioBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  left: 23.5%;
  right: 23.5%;
  top: 5rem;
  @media screen and (max-width: 768px) {
    left: 15%;
    right: 15%;
  }
  @media screen and (max-width: 600px) {
    left: 10%;
    right: 10%;
  }
  @media screen and (max-width: 480px) {
    left: 5%;
    right: 5%;
  }
  @media screen and (max-width: 380px) {
    left: 1%;
    right: 1%;
  }
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 1.1875rem 1.0625rem;

    p {
      margin: 0;
      font-weight: 600;
      font-size: 1.125rem;
    }
    img {
      cursor: pointer;
    }
  }

  .notifications {
    padding: 1.119375rem 0.5625rem;
    max-height: 70vh;
    overflow-y: auto;
    ::-webkit-scrollbar-thumb {
      background: #919191;
      border-radius: 13px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #cacaca;
    }
    ::-webkit-scrollbar {
      width: 11px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0;
      background-color: #fff;
    }
    .notification {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.375rem 0.5625rem;
      margin-bottom: 0.25rem;

      .time {
        font-size: 0.8rem;
      }
      :hover {
        background-color: #e7f1f5;
        border-radius: 6px;
        cursor: pointer;
      }

      .img-sec {
        display: flex;
        align-items: center;
        padding-right: 1rem;
        p {
          font-weight: 600;
          font-size: 0.85rem;
        }
        img {
          margin-right: 0.5rem;
          width: 48px;
          height: 48px;
        }
      }
    }
    p {
      margin: 0;
    }
  }
`;
