import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  showNotification: false,
  notificationMessage: "",
  notificationType: "message",
  loading: false,
};

let slice = createSlice({
  name: "App",
  initialState,
  reducers: {
    displayNotification(state, action) {
      return { ...state, ...action.payload };
    },
    setLoading(state, action) {
      return { ...state, loading: action.payload };
    },
    clearAppAction() {
      return { ...initialState };
    },
  },
});

export const { displayNotification, setLoading, clearAppAction } =
  slice.actions;

export default slice.reducer;
