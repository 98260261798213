/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import { useHistory } from "react-router";
import CustomSearch from "../../components/search";
import DraftSalesEditForm from "../../components/modals/draftSalesEdit";
import ConfirmationModal from "../../components/modals/confirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesAction } from "../../store/reducers/store";
import { deleteSaleApiCall } from "../../Api/sales";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { ButtonBase } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/system";
import SalesReceipt from "../../components/modals/receiptModal";

const DraftSales = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sales = useSelector((state) => state.Store.storeSales) || [];
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const preparedSales = sales
    .filter((v) => v.status === "draft")
    .map((v) => ({
      ...v,
      attendant: v.staff?.name,
      customer: v.customer?.name,
      customer_no: v.customer?.customer_id,
      payment: v.payment_method,
      item: v.items,
      amount: v.price,
    }));
  const [showEditSalesModal, setShowEditSalesModal] = useState(false);
  // const [showUpgradeSales, setShowUpgradeSales] = useState(false);
  const [salesData, setSalesData] = useState(null);
  const [total, setTotal] = useState(0);
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const editItem = (item) => {
    history.push(`/dashboard/sales/add-new?item=${JSON.stringify(item)}`);
  };
  const [showReceiptModal, setshowReceiptModal] = useState(false);

  const salesTableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Attendant",
      dataIndex: "attendant",
      key: "attendant",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{text.substring(0, 19)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, sale) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/icons/fe_edit.png"
            alt="edit_icon"
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => editItem(sale)}
          />
          <img
            src="/assets/icons/ion_receipt-outline.png"
            alt="edit_icon"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              printSalesData(sale);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.info({ query, date });
    const payload = { drafts: true, page: currentPage };
    if (date) payload.date = date;
    if (query && query !== "") payload.search = query;

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchSalesAction(payload));
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    get(payload);
  }, [dispatch, query, date, currentPage]);

  const selectSalesData = (record) => {
    setSalesData(record);
    setShowEditSalesModal(true);
  };

  const closeEditSales = () => {
    setShowEditSalesModal(false);
    setSalesData(null);
  };

  // const selectUpgradeData = (record) => {
  //   setSalesData(record);
  //   setShowUpgradeSales(true);
  // };

  const closeDeleteSales = () => {
    setShowConfirmationModal(false);
    setSalesData(null);
  };

  const deleteSale = async () => {
    closeDeleteSales();
    // setStaffData(record);
    setDeleting(salesData.id);
    const storeId = salesData.store_id;
    const res = await deleteSaleApiCall(storeId, salesData?.id);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
    }
    notification.notify("success", "Sale Record Deleted");
    setDeleting(null);

    if (error) notification.notify("error", parseEerror(error));
  };

  const getTotal = useCallback(() => {
    let total = 0;
    preparedSales.forEach((v) => {
      total += parseFloat(v.price);
    });
    return parseInt(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preparedSales]);

  useEffect(() => {
    setTotal(getTotal());
  }, [getTotal, preparedSales]);

  const printSalesData = (record) => {
    setSalesData(record);
    setshowReceiptModal(true);
  };

  const closePrintSales = () => {
    setshowReceiptModal(false);
    setSalesData(null);
  };

  return (
    <SalesTable>
      {showEditSalesModal && (
        <DraftSalesEditForm
          visible={showEditSalesModal}
          handleCancel={closeEditSales}
          salesData={salesData}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          visible={showConfirmationModal}
          handleCancel={closeDeleteSales}
          handleOk={deleteSale}
          message="Are you sure you want to delete this?"
          confirmText="Delete"
        />
      )}
      {showReceiptModal && (
        <SalesReceipt
          visible={showReceiptModal}
          handleCancel={closePrintSales}
          salesData={salesData}
        />
      )}
      <div className="sales-tools">
        <div className="search-sales">
          <CustomSearch
            search_placeholder="Search Sales"
            handleSearch={setQuery}
          />
        </div>
        {/* <div className="sales-date">
          <CustomSearchDate
            handleSearch={setDate}
            search_placeholder="Sales Date"
          />
        </div> */}
      </div>
      <CustomTable
        table_columns={salesTableCols}
        table_data={preparedSales}
        // table_pagination={{
        //   // defaultPageSize: 9,
        //   // simple: true,
        // }}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
      <div className="report-total">
        <p className="total-text">Total</p>
        <p>N{total}</p>
      </div>
    </SalesTable>
  );
};

export default DraftSales;

const SalesTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .report-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 1.2rem 4rem;
    font-size: 1.125rem;

    p {
      margin: 0;
      font-weight: 700;
    }
    .total-text {
      font-weight: 700;
    }
  }

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-sales,
    .sales-date {
      margin-right: 1rem;
    }
    .sales-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
