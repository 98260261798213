import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import "./index.css";

const CustomTable = ({
  table_columns,
  table_data,
  table_pagination,
  row_class_name,
  title,
  action,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 20,
          paddingRight: 50,
        }}
      >
        <div>{title}</div>
        <div style={{ cursor: "pointer" }} onClick={action}>
          see all
        </div>
      </div>
      <Table
        columns={table_columns}
        dataSource={table_data}
        scroll={{ x: 700 }}
        pagination={table_pagination}
        rowClassName={() => row_class_name}
        // title={() => title}
      />
    </div>
  );
};

export default CustomTable;
