import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { fetchOrderItemsAction } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import {} from "@mui/material";
import { Box } from "@mui/system";
import { CameraAltRounded } from "@mui/icons-material";
import {
  createOrderItemApiCall,
  updateOrderItemApiCall,
} from "../../Api/orderItem";

const AddEditorderItemForm = ({ visible, handleOk, handleCancel, item }) => {
  const { name, id, image } = item || {};
  const storeId = useSelector((state) => state.Store.selectedStore);

  const [loading, setLoading] = useState();
  const [file, setFile] = useState();
  const [fileUrl, setFileUrl] = useState();

  const dispatch = useDispatch();
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name,
    },
    validationSchema,

    onSubmit: (values) => {
      console.info({ values });
      createItem(values);
    },
  });

  const createItem = async (values) => {
    const { name } = values;
    setLoading(true);

    const fb = new FormData();
    fb.append("name", name);
    if (file) fb.append("image", file);

    const res = id
      ? await updateOrderItemApiCall(storeId, id, fb)
      : await createOrderItemApiCall(storeId, fb);

    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchOrderItemsAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Item Created");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  const selectImage = (e) => {
    console.info({ e: e?.target?.files[0] });
    const img = e.target.files[0];
    setFile(img);
    setFileUrl(URL.createObjectURL(img));
  };

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>
        {item ? "Edit " : "Add "} Order Item
      </h4>
      <ItemForm onSubmit={formik.handleSubmit}>
        <label>Name</label>
        <input
          type="text"
          placeholder="Enter item name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}
        <input
          type="file"
          name="photo"
          id="upload-photo"
          style={{
            opacity: "0",
            position: "absolute",
            zIndex: "-1",
          }}
          onChange={(e) => selectImage(e)}
        />
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          {fileUrl || image ? (
            <Box
              sx={{
                width: "400px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img height="150px" width="150px" alt="" src={fileUrl || image} />
              <label for="upload-photo">
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid grey",
                  }}
                >
                  Change Image
                </Box>
              </label>
            </Box>
          ) : (
            <label for="upload-photo">
              <Box
                sx={{
                  width: "200px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                <CameraAltRounded sx={{ fontSize: "50px" }} />
                <div>Select Image</div>
              </Box>
            </label>
          )}
        </Box>
        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </ItemForm>
    </Modal>
  );
};

export default AddEditorderItemForm;

const ItemForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    ::placeholder {
      color: #acacac;
    }
  }
  .new-store-input,
  select {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
`;
