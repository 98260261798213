import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../action";

let slice = createSlice({
  name: "example",
  initialState: {
    list: [],
    loading: false,
  },
  reducers: {
    fetchedEnrolleeAppointmentSuccess: (example, action) => {
      example.loading = false;
      example.list = action.payload.data.data;
    },
    exampleStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    exampleFailed: (example, action) => {
      example.loading = false;
    },
  },
});

const { exampleSuccess, exampleStart, exampleFailed } = slice.actions;

export default slice.reducer;

const url = "/globallink";

export const fetchEnrolleesDeclinedAppointment = () =>
  apiCallBegan({
    url: url,
    method: "GET",
    onSuccess: exampleSuccess.type,
    onStart: exampleStart.type,
    onError: exampleFailed.type,
  });

//   selectors example

export const getExample = (state) => state.example.list;
