import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const discountsUrl = (storeId) => `${BASE_URL}/${storeId}/discounts`;
const individualDiscountUrl = (storeId, discountId) =>
  `${discountsUrl(storeId)}/${discountId}`;

export function getDiscountsApiCall(storeId) {
  return request({ url: discountsUrl(storeId) });
}

export function getDiscountApiCall(storeId, discountId) {
  return request({ url: individualDiscountUrl(storeId, discountId) });
}

export function createDiscountApiCall(storeId, payload) {
  return request({ url: discountsUrl(storeId), method: "post", payload });
}

export function updateDiscountApiCall(storeId, discountId, payload) {
  return request({
    url: individualDiscountUrl(storeId, discountId),
    method: "put",
    payload,
  });
}

export function deleteDiscountApiCall(storeId, discountId) {
  return request({
    url: individualDiscountUrl(storeId, discountId),
    method: "delete",
  });
}
