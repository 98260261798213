import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaffsAction } from "../../store/reducers/store";
import { LoadingButton } from "@mui/lab";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import WorkingHoursPickerAlt from "../workingHoursPicker/workingHoursPickerAlt";
import { createStaffApiCall } from "../../Api/staffApi";

const AddNewStaffModal = ({ visible, handleOk, handleCancel }) => {
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;

  const stores = useSelector((state) => state.Store.stores) || [];
  const banks = useSelector((state) => state.Store.banks) || [];
  const storeIds = stores.map((v) => v.id);
  // const bankIds = banks.map((v) => v.id);

  // console.info({ bankIds });

  const [loading, setLoading] = useState();
  const [workingHours, setWorkingHours] = useState({
    weekday_start_time: {
      hour: 8,
      min: 0,
    },
    weekday_end_time: {
      hour: 16,
      min: 0,
    },
    saturday_start_time: {
      hour: 9,
      min: 0,
    },
    saturday_end_time: {
      hour: 12,
      min: 0,
    },
    sunday_start_time: {
      hour: 9,
      min: 0,
    },
    sunday_end_time: {
      hour: 12,
      min: 0,
    },
  });

  const dispatch = useDispatch();
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
    // address: yup.string().required("this field is required"),
    phone: yup.number().required("this field is required").min(11),
    account_number: yup.number().required("this field is required").min(10),
    salary: yup.number().required("this field is required").min(1),
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
    role: yup
      .string()
      .required("this field is required")
      .oneOf(["sales_staff", "regular", "sub_admin"], "please select a role"),
    store: yup
      .string()
      .required("this field is required")
      .oneOf([...storeIds], "please select a store"),
    bank_id: yup.string().required("this field is required"),
    // .oneOf([...bankIds], "please select a bank"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      // address: "",
      role: "",
      store: "",
      salary: "",
      account_number: "",
      bank_id: "",
    },
    validationSchema,

    onSubmit: (values) => {
      console.info(values);
      createStaff(values);
    },
  });
  const createStaff = async (values) => {
    setLoading(true);

    values.working_hours = workingHours;
    const storeId = values.store;
    delete values.store;
    const res = await createStaffApiCall(storeId, values);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchStaffsAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Staff Created");
    }
    setLoading(false);

    if (error) {
      notification.notify("error", parseEerror(error));
      values.store = storeId;
    }
  };

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Add New Staff</h4>
      <StaffForm onSubmit={formik.handleSubmit}>
        <label>Staff Name</label>
        <input
          type="text"
          placeholder="Enter staff name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}
        <label>Email</label>
        <input
          type="text"
          placeholder="Enter staff email"
          className="new-store-input"
          name="email"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email && formik.touched.email && (
          <div style={{ color: "red" }}> {formik.errors.email}</div>
        )}
        <label>Phone</label>
        <input
          type="text"
          placeholder="Enter staff phone number"
          className="new-store-input"
          name="phone"
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
        {formik.errors.phone && formik.touched.phone && (
          <div style={{ color: "red" }}> {formik.errors.phone}</div>
        )}
        <label for="role">Role</label> <br />
        <select
          onChange={formik.handleChange}
          value={formik.values.role}
          name="role"
          id="role"
        >
          <option value="">Select Role</option>
          {role === "super_admin" && (
            <option value="sub_admin">Sub Admin</option>
          )}
          <option value="sales_staff">Sales Staff</option>
          <option value="regular">Regular</option>
        </select>
        {formik.errors.role && formik.touched.role && (
          <div style={{ color: "red" }}> {formik.errors.role}</div>
        )}
        <label for="store">Store</label> <br />
        <select
          value={formik.values.store}
          onChange={formik.handleChange}
          name="store"
          id="store"
        >
          <option value="">Select Store</option>
          {stores &&
            stores.map((store) => (
              <option key={store.id} value={store.id}>
                {store.name}
              </option>
            ))}
        </select>
        {formik.errors.store && formik.touched.store && (
          <div style={{ color: "red" }}> {formik.errors.store}</div>
        )}
        <label>Bank</label>
        <br />
        <select
          value={formik.values.bank_id}
          onChange={formik.handleChange}
          name="bank_id"
          id="bank_id"
        >
          <option value="">Select Bank</option>
          {banks &&
            banks.map((bank) => (
              <option key={bank.id} value={bank.id}>
                {bank.name}
              </option>
            ))}
        </select>
        {formik.errors.bank_id && formik.touched.bank_id && (
          <div style={{ color: "red" }}> {formik.errors.bank_id}</div>
        )}
        <label>Account Number</label>
        <input
          type="text"
          placeholder="Enter account number"
          className="new-store-input"
          name="account_number"
          onBlur={formik.handleBlur}
          value={formik.values.account_number}
          onChange={formik.handleChange}
        />
        {formik.errors.account_number && formik.touched.account_number && (
          <div style={{ color: "red" }}> {formik.errors.account_number}</div>
        )}
        <label>Salary</label>
        <input
          type="text"
          placeholder="Enter salary"
          className="new-store-input"
          name="salary"
          onBlur={formik.handleBlur}
          value={formik.values.salary}
          onChange={formik.handleChange}
        />
        {formik.errors.salary && formik.touched.salary && (
          <div style={{ color: "red" }}> {formik.errors.salary}</div>
        )}
        <label>Working Hours</label>
        <WorkingHoursPickerAlt
          workingHours={workingHours}
          setWorkingHours={setWorkingHours}
        />
        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </StaffForm>
    </Modal>
  );
};

export default AddNewStaffModal;

const StaffForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    ::placeholder {
      color: #acacac;
    }
  }
  .new-store-input,
  select {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
`;
