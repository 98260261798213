import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const ordersUrl = (storeId) => `${BASE_URL}/${storeId}/orders`;
const individualOrderUrl = (storeId, orderId) =>
  `${ordersUrl(storeId)}/${orderId}`;

export function getOrdersApiCall(storeId, query) {
  return request({ url: ordersUrl(storeId), query });
}

export function getOrderApiCall(storeId, orderId) {
  return request({ url: individualOrderUrl(storeId, orderId) });
}

export function createOrderApiCall(storeId, payload) {
  return request({ url: ordersUrl(storeId), method: "post", payload });
}

export function updateOrderApiCall(storeId, orderId, payload) {
  return request({
    url: individualOrderUrl(storeId, orderId),
    method: "put",
    payload,
  });
}

export function patchOrderApiCall(storeId, orderId, payload) {
  return request({
    url: `${ordersUrl(storeId)}/${orderId}/update-status`,
    method: "patch",
    payload,
  });
}

export function confirmOrderItemsApiCall(storeId, orderId, payload) {
  return request({
    url: `${individualOrderUrl(storeId, orderId)}/resolve-dispute`,
    method: "post",
    payload,
  });
}

export function disputeOrderItemsApiCall(storeId, orderId, payload) {
  return request({
    url: `${individualOrderUrl(storeId, orderId)}/log-dispute`,
    method: "post",
    payload,
  });
}

export function resolveOrderItemsDisputeApiCall(storeId, orderId, payload) {
  return request({
    url: `${individualOrderUrl(storeId, orderId)}/resolve-dispute`,
    method: "post",
    payload,
  });
}
