import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./index.css";

export const ExportToExcel = ({ apiData, fileName, text_name }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      className="export-report-tool"
      onClick={(e) => exportToCSV(apiData, fileName)}
    >
      <img
        src="/assets/icons/ant-design_cloud-download-outlined.png"
        alt="download"
      />
      <p> Export {text_name}</p>
    </div>
  );
};
