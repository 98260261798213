import React from "react";
import { Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { cloneDeep } from "lodash";

function WorkingHoursPickerAlt({ workingHours = {}, setWorkingHours }) {
  const hours = [...Array(24).keys()];
  const mins = [...Array(60).keys()];

  const handleChange = (val, field, subFiled) => {
    // const tempWorkingHours = { ...workingHours };
    const tempWorkingHours = cloneDeep(workingHours);
    tempWorkingHours[field][subFiled] = parseInt(val);

    console.info(tempWorkingHours);
    setWorkingHours(tempWorkingHours);
    // console.info(val.getHours(), val.getMinutes());
  };

  // useEffect(() => {
  //   const keys = Object.keys(workingHours);
  //   console.info(keys);
  //   keys.forEach((field) => {
  //     if (field === "weekday_start_time")
  //       setVal1s(new Date(workingHours[field]));
  //     if (field === "weekday_end_time") setVal1e(new Date(workingHours[field]));
  //     if (field === "saturday_start_time")
  //       setVal2s(new Date(workingHours[field]));
  //     if (field === "saturday_end_time")
  //       setVal2e(new Date(workingHours[field]));
  //     if (field === "sunday_start_time")
  //       setVal3s(new Date(workingHours[field]));
  //     if (field === "sunday_end_time") setVal3e(new Date(workingHours[field]));
  //   });
  // }, []);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <label>Week Days</label>
        <Stack spacing={3} direction="row">
          <Stack spacing={3} direction="row">
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.weekday_start_time.hour}
                onChange={(e) =>
                  handleChange(e.target.value, "weekday_start_time", "hour")
                }
                name="bank_id"
                id="bank_id"
              >
                <option value="">NA</option>
                {hours &&
                  hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour < 10 ? `0${hour}` : hour}
                    </option>
                  ))}
              </select>
            </Stack>
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.weekday_start_time.min}
                onChange={(e) =>
                  handleChange(e.target.value, "weekday_start_time", "min")
                }
                name="mins"
                id="minsw"
              >
                <option value="">NA</option>
                {mins &&
                  mins.map((min) => (
                    <option key={min} value={min}>
                      {min < 10 ? `0${min}` : min}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            to
          </div>
          <Stack spacing={3} direction="row">
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.weekday_end_time.hour}
                onChange={(e) =>
                  handleChange(e.target.value, "weekday_end_time", "hour")
                }
                name="bank_id"
                id="bank_id"
              >
                <option value="">NA</option>
                {hours &&
                  hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour < 10 ? `0${hour}` : hour}
                    </option>
                  ))}
              </select>
            </Stack>
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.weekday_end_time.min}
                onChange={(e) =>
                  handleChange(e.target.value, "weekday_end_time", "min")
                }
                name="mins"
                id="minsw"
              >
                <option value="">NA</option>
                {mins &&
                  mins.map((min) => (
                    <option key={min} value={min}>
                      {min < 10 ? `0${min}` : min}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
        </Stack>
        <label>Saturdays</label>
        <Stack spacing={3} direction="row">
          <Stack spacing={3} direction="row">
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.saturday_start_time.hour}
                onChange={(e) =>
                  handleChange(e.target.value, "saturday_start_time", "hour")
                }
                name="bank_id"
                id="bank_id"
              >
                <option value="">NA</option>
                {hours &&
                  hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour < 10 ? `0${hour}` : hour}
                    </option>
                  ))}
              </select>
            </Stack>
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.saturday_start_time.min}
                onChange={(e) =>
                  handleChange(e.target.value, "saturday_start_time", "min")
                }
                name="mins"
                id="minsw"
              >
                <option value="">NA</option>
                {mins &&
                  mins.map((min) => (
                    <option key={min} value={min}>
                      {min < 10 ? `0${min}` : min}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            to
          </div>
          <Stack spacing={3} direction="row">
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.saturday_end_time.hour}
                onChange={(e) =>
                  handleChange(e.target.value, "saturday_end_time", "hour")
                }
                name="bank_id"
                id="bank_id"
              >
                <option value="">NA</option>
                {hours &&
                  hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour < 10 ? `0${hour}` : hour}
                    </option>
                  ))}
              </select>
            </Stack>
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.saturday_end_time.min}
                onChange={(e) =>
                  handleChange(e.target.value, "saturday_end_time", "min")
                }
                name="mins"
                id="minsw"
              >
                <option value="">NA</option>
                {mins &&
                  mins.map((min) => (
                    <option key={min} value={min}>
                      {min < 10 ? `0${min}` : min}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
        </Stack>
        <label>Sundays</label>
        <Stack spacing={3} direction="row">
          <Stack spacing={3} direction="row">
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.sunday_start_time.hour}
                onChange={(e) =>
                  handleChange(e.target.value, "sunday_start_time", "hour")
                }
                name="bank_id"
                id="bank_id"
              >
                <option value="">NA</option>
                {hours &&
                  hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour < 10 ? `0${hour}` : hour}
                    </option>
                  ))}
              </select>
            </Stack>
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.sunday_start_time.min}
                onChange={(e) =>
                  handleChange(e.target.value, "sunday_start_time", "min")
                }
                name="mins"
                id="minsw"
              >
                <option value="">NA</option>
                {mins &&
                  mins.map((min) => (
                    <option key={min} value={min}>
                      {min < 10 ? `0${min}` : min}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            to
          </div>
          <Stack spacing={3} direction="row">
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.sunday_end_time.hour}
                onChange={(e) =>
                  handleChange(e.target.value, "sunday_end_time", "hour")
                }
                name="bank_id"
                id="bank_id"
              >
                <option value="">NA</option>
                {hours &&
                  hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour < 10 ? `0${hour}` : hour}
                    </option>
                  ))}
              </select>
            </Stack>
            <Stack spacing={1} direction="row">
              <select
                value={workingHours.sunday_end_time.min}
                onChange={(e) =>
                  handleChange(e.target.value, "sunday_end_time", "min")
                }
                name="mins"
                id="minsw"
              >
                <option value="">NA</option>
                {mins &&
                  mins.map((min) => (
                    <option key={min} value={min}>
                      {min < 10 ? `0${min}` : min}
                    </option>
                  ))}
              </select>
            </Stack>
          </Stack>
        </Stack>
      </LocalizationProvider>
    </div>
  );
}

export default WorkingHoursPickerAlt;
