/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import Security from "./security";
import SalesTax from "./salesTax";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useLocation } from "react-router";
import SalesService from "./salesService";
import Attendance from "./attendance";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import Items from "./Items";
// import Salary from "./salary";

const baseUrl = "/dashboard/settings";
const sideMenus1 = [
  {
    name: "Sales",
    pathname: `${baseUrl}/sales/service`,
  },
  {
    name: "Items",
    pathname: `${baseUrl}/items`,
  },
  // {
  //   name: "Salary",
  //   pathname: `${baseUrl}/salary`,
  // },
  {
    name: "Security",
    pathname: `${baseUrl}/security`,
  },
];

const sideMenus2 = [
  {
    name: "Security",
    pathname: `${baseUrl}/security`,
  },
];
const Settings = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([""]);
  const [selectedCategory, setSelectedCAtegory] = useState(1);
  const user = useSelector((state) => state.User.user) || {};
  const serviceCategories =
    useSelector((state) => state.Store.serviceCategories) || {};
  const { role } = user;
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  const activeSidebar = hasPrivilage ? sideMenus1 : sideMenus2;
  const onClickMenu = (item) => {
    setSelected([item]);
    setSelectedCAtegory(1);
  };

  const selectServiceCategory = (item) => {
    setSelected([item]);
    setSelectedCAtegory(item);
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname?.split("/").pop();
    if (selected !== path) {
      const firstLetter = path.charAt(0);

      const firstLetterCap = firstLetter.toUpperCase();

      const remainingLetters = path.slice(1);

      const capitalizedWord = firstLetterCap + remainingLetters;
      setSelected(capitalizedWord);
    }
  }, [location]);

  return (
    <SettingsContainer>
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={selected}
        className="menu-left"
      >
        {activeSidebar.map(({ name, pathname }, index) => {
          return name === "Sales" ? (
            <>
              <Menu.Item
                onClick={() => {
                  // onClickMenu("");
                  setOpen(!open);
                  // history.push(`${baseUrl}/sales/taxes`);
                }}
                key="allsales"
              >
                <Link to="#">Sales</Link>
              </Menu.Item>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={["0"]}
                  selectedKeys={selected}
                  className="menu-left"
                >
                  <Menu.Item
                    style={{
                      backgroundColor:
                        location.pathname === `${baseUrl}/sales/taxes`
                          ? "#e6f7ff"
                          : "",
                      paddingLeft: 40,
                    }}
                    key="taxes"
                    onClick={() => onClickMenu("taxes")}
                  >
                    <Link to={`${baseUrl}/sales/taxes`}>Taxes</Link>
                  </Menu.Item>
                  {serviceCategories?.map((item) => (
                    <Menu.Item
                      onClick={() => selectServiceCategory(item.id)}
                      key="services"
                      style={{
                        backgroundColor:
                          location.pathname === `${baseUrl}/sales/services` &&
                          selectedCategory === item.id
                            ? "#e6f7ff"
                            : "",
                        paddingLeft: 40,
                      }}
                    >
                      <Link to={`${baseUrl}/sales/services`}>{item.name}</Link>
                    </Menu.Item>
                  ))}
                </Menu>
              </Collapse>
            </>
          ) : (
            <Menu.Item key={name} onClick={() => onClickMenu(name)}>
              <Link to={pathname}>{name}</Link>
            </Menu.Item>
          );
        })}
      </Menu>

      <div className="menu-right" style={{ height: "87vh", padding: 0 }}>
        {location.pathname === `${baseUrl}/security` ? (
          <Security />
        ) : location.pathname === `${baseUrl}/sales/taxes` && hasPrivilage ? (
          <SalesTax />
        ) : location.pathname === `${baseUrl}/sales/services` &&
          hasPrivilage ? (
          <SalesService selectedCategory={selectedCategory} />
        ) : location.pathname === `${baseUrl}/sales/attendance` &&
          hasPrivilage ? (
          <Attendance />
        ) : location.pathname === `${baseUrl}/items` && hasPrivilage ? (
          <Items />
        ) : (
          <Security />
        )}
      </div>
    </SettingsContainer>
  );
};

export default Settings;

const SettingsContainer = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .menu-left {
    background: #ffffff;
    border-radius: 10px;
    flex: 0.3;
    margin-right: 1rem;
    color: #000;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 1rem;
      display: flex;
    }
  }
  .menu-right {
    flex: 0.7;
    background: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    height: 100vh;
  }
`;
