import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearchDate from "../../components/search/dateSearch";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayRoleAction } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { Box, Typography } from "@mui/material";
import { ExportToExcel } from "../../components/exportFile";
import { LoadingButton } from "@mui/lab";
import { payStaffApiCall } from "../../Api/payroll";
import { Redirect } from "react-router";

const Payroll = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role === "super_admin";
  const [monthId, setMonthId] = useState(null);
  const payroll = useSelector((state) => state.Store.payroll) || [];
  const storeId = useSelector((state) => state.Store.selectedStore);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("pending");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let month = months[d.getMonth()];

  const preparedPayroll = payroll.filter((v) => v.status === currentTab);

  const salesTableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Late Coming",
      dataIndex: "late_comings",
      key: "late_comings",
    },
    {
      title: "Early Leaving",
      dataIndex: "early_leaving",
      key: "early_leaving",
    },
    {
      title: "Bonus",
      dataIndex: "bonus",
      key: "bonus",
      render: (text, sale) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <select>
            <option>N</option>
          </select>
        </div>
      ),
    },
    {
      title: "Net Salary",
      dataIndex: "net_salary",
      key: "net_salary",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "bonus",
      render: (id, sale) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* {currentTab === "pending" && (
            <LoadingButton
              disabled={sale.status !== "pending"}
              sx={{
                width: "60px",
                backgroundColor: "var(--secondary-bg-color)",
                color: "white",
              }}
              onClick={() => pay(id)}
              loading={loading === id}
            >
              Pay
            </LoadingButton>
          )} */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const payload = { pending: true };
    if (date) payload.date = date;

    (async () => {
      const id = await dispatch(fetchPayRoleAction(payload));
      // console.info({ id });
      setMonthId(id);
    })();
  }, [dispatch, date]);

  const pay = async (id) => {
    setLoading(id);
    const res = await payStaffApiCall(storeId, id);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchPayRoleAction());
      notification.notify("success", "Payment Completed");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  const defaultStyles = {
    border: "1px solid #0088c0",
    cursor: "pointer",
    borderRadius: "3px",
    width: "150px",
    padding: "10px",
    textAlign: "center",
  };

  const getTotal = () => {
    let total = 0;
    preparedPayroll.forEach((v) => (total += parseFloat(v.salary)));
    return total;
  };

  useEffect(() => {
    setDate(null);
  }, [currentTab]);

  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <PayrollTable>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Box
            variant={currentTab === "pending" ? "contained" : "outlined"}
            onClick={() => setCurrentTab("pending")}
            sx={
              currentTab === "pending"
                ? {
                    backgroundColor: "#0088c0",
                    color: "white",
                    ...defaultStyles,
                  }
                : {
                    backgroundColor: "transparent",
                    color: "#0088c0",
                    ...defaultStyles,
                  }
            }
          >
            Pending
          </Box>{" "}
          <Box
            sx={
              currentTab === "paid"
                ? {
                    backgroundColor: "#0088c0",
                    color: "white",
                    marginLeft: "-12px",
                    ...defaultStyles,
                  }
                : {
                    backgroundColor: "transparent",
                    marginLeft: "-12px",
                    color: "#0088c0",
                    ...defaultStyles,
                  }
            }
            variant={currentTab === "paid" ? "contained" : "outlined"}
            onClick={() => setCurrentTab("paid")}
          >
            Paid Out
          </Box>{" "}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ marginRight: 4 }} variant="h5">
              Pending Payroll For{" "}
              {date && currentTab === "paid"
                ? months[new Date(date).getMonth()]
                : month}
              ,
              {date && currentTab === "paid"
                ? new Date(date).getFullYear()
                : d.getFullYear()}
            </Typography>
            {currentTab === "paid" && (
              <div className="sales-date">
                <CustomSearchDate
                  handleSearch={setDate}
                  search_placeholder="Payroll Date"
                />
              </div>
            )}
          </Box>
          <ExportToExcel
            apiData={preparedPayroll}
            fileName="sales report"
            text_name="Report"
          />
        </Box>
      </Box>
      <CustomTable
        table_columns={salesTableCols}
        table_data={preparedPayroll}
        table_pagination={{
          defaultPageSize: 9,
          simple: true,
        }}
      />
      <div className="report-total">
        <p className="total-text">Total</p>
        <p>N{getTotal()}</p>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {currentTab === "pending" && (
          <LoadingButton
            sx={{
              width: "600px",
              backgroundColor: "var(--secondary-bg-color)",
              color: "white",
            }}
            onClick={() => pay(monthId)}
            loading={loading === monthId}
          >
            Pay All
          </LoadingButton>
        )}
      </Box>
    </PayrollTable>
  );
};

export default Payroll;

const PayrollTable = styled.div`
  .report-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 1.2rem 4rem;
    font-size: 1.125rem;

    p {
      margin: 0;
      font-weight: 700;
    }
    .total-text {
      font-weight: 700;
    }
  }
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-sales,
    .sales-date {
      margin-right: 1rem;
    }
    .sales-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
