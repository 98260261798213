import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearchDate from "../../components/search/dateSearch";
import ConfirmationModal from "../../components/modals/confirmationModal";
import FilterModal from "../../components/modals/filterModal";
import { ExportToExcel } from "../../components/exportFile";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesAction } from "../../store/reducers/store";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { Box } from "@mui/system";

const SalesReport = () => {
  const dispatch = useDispatch();
  const sales = useSelector((state) => state.Store.storeSales);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateT0, setDateT0] = useState(null);
  const [total, setTotal] = useState(0);
  const [filteredSales, setFilteredSales] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  const [filter, setFilter] = useState(null);
  let preparedSales = sales.map((v) => ({
    ...v,
    attendant: v.staff?.name,
    customer: v.customer?.name,
    payment: v.payment_method,
    item: v.items,
    amount: v.price,
  }));
  // preparedSales = preparedSales.filter((v) =>
  //   filter ? v.attendant?.toLowerCase().includes(filter.toLowerCase()) : true
  // );

  const salesTableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text) => (
        <span>
          {text
            ?.replace("onground_wallet_sale", "Wallet(on-ground)")
            .replace("wallet", "Wallet(in-app)")}
        </span>
      ),
    },
    {
      title: "Attendant",
      dataIndex: "attendant",
      key: "attendant",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{text.substring(0, 19)}</span>,
    },
  ];

  useEffect(() => {
    console.info("fetching info");
    setFilter({});

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchSalesAction(payload));
      console.info({ data });
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    // get(payload);
    if (dateT0 && dateFrom)
      get({
        report: true,
        date_range: true,
        start_at: dateFrom,
        end_at: dateT0,
        page: currentPage,
      });
    else if (dateT0 || dateFrom) return;
    else
      get({
        // recent: true,
        page: currentPage,
      });
  }, [dispatch, dateFrom, dateT0, currentPage]);

  const handleClose = (val) => {
    setFilter({ ...val });
    setShowFilterModal(false);
  };

  useEffect(() => {
    console.info(filter);
    let filtered = preparedSales;
    if (filter?.value && filter.value !== "")
      filtered = filtered.filter((v) =>
        v.attendant?.toLowerCase().includes(filter.value.toLowerCase())
      );
    if (filter?.service_type)
      filtered = filtered.filter((v) => v.service_type === filter.service_type);
    if (filter?.payment)
      filtered = filtered.filter((v) => v.payment === filter.payment);

    setFilteredSales(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sales]);

  const getTotal = useCallback(() => {
    let total = 0;
    filteredSales.forEach((v) => {
      total += parseFloat(v.price);
    });
    return parseInt(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSales]);

  useEffect(() => {
    setTotal(getTotal());
  }, [getTotal, sales]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Sales  Report";
    const headers = [
      ["ID", "	Customer", "Item", "Amount", "Payment", "Attendant.", "Date"],
    ];

    const data = filteredSales.map((elt) => [
      elt.id,
      elt.customer,
      elt.item,
      elt.amount,
      elt.payment,
      elt.attendant,
      elt.date,
    ]);
    data.push([]);
    data.push(["total", "", "", "", "", "", getTotal()]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  return (
    <SalesTable>
      `{" "}
      {/* <SalesEditForm
          visible={showEditSalesModal}
          handleCancel={() => setShowEditSalesModal(false)}
        />` */}
      <ConfirmationModal
        visible={showConfirmationModal}
        handleCancel={() => setShowConfirmationModal(false)}
        message="Are you sure you want to delete this?"
        confirmText="Delete"
      />
      <div className="sales-tools">
        <FilterModal
          visible={showFilterModal}
          handleClose={handleClose}
          handleCancel={() => setShowFilterModal(false)}
        />
        <div className="sales-tools-inner">
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
            className="date-to"
          >
            from
            <CustomSearchDate
              handleSearch={setDateFrom}
              search_placeholder="Sales Date"
            />
          </div>

          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
            className="date-to"
          >
            to
            <CustomSearchDate
              handleSearch={setDateT0}
              search_placeholder="Sales Date"
            />
          </div>

          <button
            className="add-filter-btn"
            onClick={() => setShowFilterModal(true)}
          >
            <img src="/assets/icons/add_circle_outline.png" alt="add store" />
            Add Filter
          </button>
        </div>
        {hasPrivilage && (
          <ExportToExcel
            apiData={[...filteredSales, { id: "total", amount: getTotal() }]}
            fileName="sales report"
            text_name="excel"
          />
        )}
        {hasPrivilage && (
          <div
            className="export-report-tool"
            style={{ cursor: "pointer", color: "#0088c0", fontWeight: "700" }}
            onClick={exportPDF}
          >
            <img
              src="/assets/icons/ant-design_cloud-download-outlined.png"
              alt="download"
            />
            Export pdf
          </div>
        )}
      </div>
      <CustomTable
        table_columns={salesTableCols}
        table_data={filteredSales}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
      <div className="report-total">
        <p className="total-text">Total</p>
        <p>N{total}</p>
      </div>
    </SalesTable>
  );
};

export default SalesReport;

const SalesTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 1rem 1rem 0rem 1rem;

    .sales-tools-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .date-from,
      .date-to {
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .date-to {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .add-filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.4rem 0rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: 12rem;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        white-space: nowrap;
        background-color: var(--secondary-bg-color);
        color: #fff;
        border: none;

        img {
          margin-right: 0.5rem;
        }
        @media screen and (max-width: 480px) {
          .add-filter-btn {
            width: 10rem;
          }
        }
        @media screen and (max-width: 380px) {
          .add-filter-btn {
            white-space: pre-wrap;
            width: auto;
            padding: 0.2rem 0.4rem;
          }
        }
      }
    }
  }
  .report-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 1.2rem 4rem;
    font-size: 1.125rem;

    p {
      margin: 0;
      font-weight: 700;
    }
    .total-text {
      font-weight: 700;
    }
  }
`;
