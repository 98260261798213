// import { Button } from "@mui/material";
import React from "react";

const Config = () => {
  // const device = useRef(null);

  // function setup(device) {
  //   return device
  //     .open()
  //     .then(() => device.selectConfiguration(1))
  //     .then(() => device.claimInterface(0));
  // }

  // function print() {
  //   var string = document.getElementById("printContent").value + "\n";
  //   var encoder = new TextEncoder();
  //   var data = encoder.encode(string);
  //   device.transferOut(1, data).catch((error) => {
  //     console.log(error);
  //   });
  // }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Button sx={{ height: "40px" }} onClick={getDevices}>
        getDevices
      </Button> */}
    </div>
  );
};

export default Config;
