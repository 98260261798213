import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
// import CustomSearch from "../../components/search";
// import CustomSearchDate from "../../components/search/dateSearch";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceApiCall } from "../../Api/attendance";
import { setLoading } from "../../store/reducers/app";
import { LoadingButton } from "@mui/lab";
import AttendanceModal from "../../components/modals/attendanceModal";
import { Redirect } from "react-router";
import { ButtonBase } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/system";

const Attendance = () => {
  const storeId = useSelector((state) => state.Store.selectedStore);
  const user = useSelector((state) => state.User.user) || {};

  const [Attendance, setAttendance] = useState([]);
  const [showModal, setShowModal] = useState(false);
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  const dispatch = useDispatch();
  const salesTableCols = [
    // {
    //   title: "ID",
    //   dataIndex: "staff_id",
    //   key: "staff_id",
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Clock In",
      dataIndex: "clocked_in_at",
      key: "clock in",
      render: (text, record) =>
        text === null ? (
          <LoadingButton
            onClick={() => clockUserIn(record)}
            className="add-sale-btn"
          >
            Clock In
          </LoadingButton>
        ) : (
          <div>{text}</div>
        ),
    },
    {
      title: "Clock out",
      dataIndex: "clocked_out_at",
      key: "clock out",
      render: (text, record) =>
        !record.clocked_out_at && record.clocked_in_at ? (
          <LoadingButton
            className="add-sale-btn"
            onClick={() => clockUserOut(record)}
          >
            Clock Out
          </LoadingButton>
        ) : (
          <div>{text && text}</div>
        ),
    },
  ];
  const fetchAttendance = useCallback(async () => {
    dispatch(setLoading(true));
    const { success, resData, error } = await getAttendanceApiCall(storeId, {
      page: currentPage,
    });
    console.info({ success, resData, error });
    if (success && resData) setAttendance(resData?.data || []);
    var lastPageNo = resData?.last_page;
    if (lastPageNo) {
      setLastPage(lastPageNo);
      if (currentPage > lastPageNo) setCurrentPage(1);
    }

    dispatch(setLoading(false));
  }, [dispatch, storeId, currentPage]);

  useEffect(() => {
    fetchAttendance();
  }, [fetchAttendance]);

  const clockUserIn = async (record) => {
    setShowModal("clock_in");
  };
  const handleCancel = () => {
    setShowModal();
  };
  const clockUserOut = async (record) => {
    setShowModal("clock_out");
  };
  const { role } = user;
  const hasPrivilage = role === "super_admin" || role === "sub_admin";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }
  return (
    <SalesTable>
      {showModal && (
        <AttendanceModal
          visible={showModal != null}
          handleCancel={handleCancel}
          data={showModal}
          fetchAttendance={fetchAttendance}
          type={showModal}
        />
      )}
      {/* <div className="sales-tools">
        <div className="search-sales">
          <CustomSearch search_placeholder="Search Staff ID" />
        </div>
        <div className="sales-date">
          <CustomSearchDate search_placeholder="Sales Date" />
        </div>
        <button
          className="add-sale-btn"
          onClick={() => history.push("/dashboard/sales/add-new")}
        >
          Clock In
        </button> 
      </div> */}
      <CustomTable
        table_columns={salesTableCols}
        table_data={Attendance}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </SalesTable>
  );
};

export default Attendance;

const SalesTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-sales,
    .sales-date {
      margin-right: 1rem;
    }
    .sales-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
