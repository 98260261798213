import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import "./index.css";

const CustomTable = ({
  table_columns,
  table_data,
  table_pagination,
  row_class_name,
  title,
}) => {
  return (
    <Table
      columns={table_columns}
      dataSource={table_data}
      scroll={{ x: 700 }}
      pagination={table_pagination}
      rowClassName={() => row_class_name}
      title={() => title}
      style={{ minHeight: "70vh" }}
    />
  );
};

export default CustomTable;
