import React, { useEffect, useState } from "react";
import DashboardCard from "../../components/dashboardCard";
import DashboardActionsButtons from "../../components/dashboardActionButtons";
import CustomTable from "../../components/customTable2";
import styled from "styled-components";
import CustomChart from "./customchart";
import { Redirect, useHistory } from "react-router";
import { salesTableCols } from "../../data";
import { useDispatch } from "react-redux";
import { fetchStoreAction } from "../../store/reducers/store";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [showPrice, setShowPrice] = useState("1,700,567.00");
  const history = useHistory();
  const storeInformation =
    useSelector((state) => state.Store.storeInformation) || {};
  const sales = useSelector((state) => state.Store.storeSales) || [];
  const preparedSales = sales.slice(0, 5).map((v) => ({
    ...v,
    attendant: v.staff?.name,
    customer: v.customer?.name,
    payment: v.payment_method,
    item: v.items,
    amount: v.price,
    date: v.date?.substring(0, 19),
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStoreAction());
  }, [dispatch]);

  const handleShowPrice = () => {
    if (showPrice === "*****") {
      setShowPrice("1,700,567.00");
    } else {
      setShowPrice("*****");
    }
  };

  const cardData = [
    {
      name: "Add New Sales",
      action: () => history.push("/dashboard/sales/add-new"),
    },
    {
      name: "Add New Customer",
      action: () => history.push("/dashboard/customer?add-new"),
    },
    {
      name: "Add New Staff",
      action: () => history.push("/dashboard/staff?add-new"),
    },
  ];

  const dashboardCardData = [
    {
      key: "sales",
      numberOfSSctivity: 4678,
      tagName: "Number of Sales",
      bgColor: "#C5FFCE",
      action: () => history.push("/dashboard/sales"),
    },
    {
      key: "orders",
      numberOfSSctivity: 1500,
      tagName: "Number of Orders",
      bgColor: "#F4ECDD",
      action: () => history.push("/dashboard/order/neworders"),
    },
    {
      key: "debtors",
      numberOfSSctivity: 78,
      tagName: "Number of Debtors",
      bgColor: "#EFDADA",
      action: () => history.push("/dashboard/sales/pending"),
    },
    {
      key: "customers",
      numberOfSSctivity: 3000,
      tagName: "Number of Customers",
      bgColor: "#DEE0EF",
      action: () => history.push("/dashboard/customer"),
    },
    {
      key: "staff",
      numberOfSSctivity: 68,
      tagName: "Number of Staff",
      bgColor: "#DDF4F5",
      action: () => history.push("/dashboard/staff"),
    },
  ];

  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role !== "regular";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <div>
      <DashBoardOverView>
        {dashboardCardData.map((item, index) => {
          return (
            <div key={index}>
              <DashboardCard
                tags={item.tagName}
                number_of_activities={storeInformation[item.key] || 0}
                bgColor={item.bgColor}
                action={item.action}
              />
            </div>
          );
        })}
      </DashBoardOverView>

      <DashBoardGraphSection>
        <div className="graph-section">
          <div
            style={{
              width: "99%",
              height: "100%",
              margin: "auto",
            }}
          >
            <CustomChart />
          </div>
        </div>
        <div className="card-section">
          <div className="card">
            <div className="card-header">
              <p>Total sales</p>
              <select value={2022}>
                {/* <option style={{ color: "black" }}>2019</option>
                <option style={{ color: "black" }}>2020</option>
                <option style={{ color: "black" }}>2021</option> */}
                <option style={{ color: "black" }} value={2022}>
                  2022
                </option>
              </select>
            </div>
            <div className="card-footer">
              <p>N{storeInformation.total_sales}</p>
              <i className="fa fa-eye" onClick={handleShowPrice}></i>
            </div>
          </div>
          <div className="buttons">
            {cardData.map((item, index) => {
              return (
                <DashboardActionsButtons
                  key={index}
                  button_text={item.name}
                  action={item.action}
                />
              );
            })}
          </div>
        </div>
      </DashBoardGraphSection>

      {/* dashboard table */}
      <DashBoardTableSection>
        <CustomTable
          title={<p>Recent Sales</p>}
          table_columns={salesTableCols}
          table_data={preparedSales}
          table_pagination={false}
          action={() => history.push("/dashboard/sales")}
        />
      </DashBoardTableSection>
    </div>
  );
};

export default Dashboard;
const DashBoardOverView = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1.9375rem;
`;

const DashBoardGraphSection = styled.div`
  margin-top: 1.4375rem;
  display: grid;
  grid-template-columns: [col1-start] 2fr [col1-end col2-start] 1fr [col2-end];
  gap: 2rem;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }

  .graph-section {
    height: 100%;
    padding: 2.564375rem 0.3325rem;
    background: #ffffff;
    border-radius: 10px;
    height: 368px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .card-section {
    height: 368px;
    padding: 1.25rem;
    background: #ffffff;
    border-radius: 10px;

    .card {
      background: linear-gradient(90deg, #606c88 0%, #3f4c6b 100%);
      border-radius: 9px;
      padding: 1.25rem 1.3125rem;
      color: #fff;
      height: 40%;
      display: flex;
      justify-content: space-between;

      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          color: #fcfcfc;
          letter-spacing: 0.44px;
          margin-right: 1rem;
          font-siz: 1rem;
        }
        select {
          border: 1px solid #c0c0c0;
          border-radius: 3px;
          width: 91px;
          height: 29px;
          background: transparent;
        }
      }
      .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          color: #fcfcfc;
          letter-spacing: 0.44px;
          font-size: 1.625rem;
          margin-right: 1rem;
        }
        i {
          color: #dadada;
          cursor: pointer;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 1.25rem;
    }
  }
`;

const DashBoardTableSection = styled.div`
  margin-top: 1.4375rem;
  background: #ffffff;
  border-radius: 10px;
  padding: 1rem;

  p {
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0;
  }
`;
