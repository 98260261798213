/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./index.css";
import DiscountSale from "./discount";
import ServiceSale from "./service";
import { Tabs } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesAction, setCustomers } from "../../store/reducers/store";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { createSaleApiCall, updateSaleApiCall } from "../../Api/sales";
import { useHistory } from "react-router";
import { DatePicker } from "@mui/x-date-pickers";
import { debounce } from "lodash";
import { getCustomersApiCall } from "../../Api/customerApi";
import { ArrowDropDown } from "@mui/icons-material";

const { TabPane } = Tabs;

const AddNewSales = () => {
  const [loading, setLoading] = useState();
  const [saleId, setSaleId] = useState();
  const [customerLoading, setCustomerLoading] = useState();
  const [services, setServices] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [options, setOptions] = useState([]);
  const [optionNames, setOptionNames] = useState({});
  const [floorStaffs, setFloorStaffs] = useState([]);
  const [floorStaffNames, setFloorStaffNames] = useState({});
  const storeservices = useSelector((state) => state.Store.services) || [];
  const customers = useSelector((state) => state.Store.customers);
  const staffs = useSelector((state) => state.Store.storeStaffs);
  const tax = useSelector((state) => state.Store.tax);
  const selectedStore = useSelector((state) => state.Store.selectedStore) || [];
  const [search, setSearch] = useState("");
  const [tokenFieldVisible, setTokenFieldVisible] = useState(false);

  const serviceCategories =
    useSelector((state) => state.Store.serviceCategories) || {};
  const storeservicesHash = {};
  storeservices?.forEach((v) => {
    storeservicesHash[v.id] = v;
  });
  const dispatch = useDispatch();
  let validationSchema = yup.object({
    customer_id: yup.string().required("this field is required"),
    floor_attendant: yup.string().required("this field is required"),
    staff_id: yup.string().required("this field is required"),
    date: yup.string().required("this field is required"),
    items: yup.string().required("this field is required"),
    service_type: yup
      .string()
      .required("this field is required")
      .oneOf(["self-service", "drop-off"], "please select a service"),
    payment_method: yup.string().required("this field is required").oneOf(
      [
        // "cash", "transfer",
        "pos",
        "pending",
        "wallet",
        "transfer",
        "onground_wallet_sale",
      ],
      "please select a payment method"
    ),
    ...(tokenFieldVisible
      ? { transaction_token: yup.string().required("this field is required") }
      : {}),
  });

  const status = useRef();
  const formik = useFormik({
    initialValues: {
      customer_id: "",
      floor_attendant: "",
      staff_id: "",
      service_type: "",
      payment_method: "",
      date: "",
      items: "",
      transaction_token: "",
    },
    validationSchema,

    onSubmit: (values) => {
      createSale({ ...values, status: status.current });
    },
  });

  useEffect(() => {
    setTokenFieldVisible(
      formik.values.payment_method === "onground_wallet_sale" ? true : false
    );
  }, [formik.values.payment_method]);
  const history = useHistory();

  const createSale = async (values) => {
    // const storeId = values.store;

    if (services.length === 0)
      return notification.notify("error", "select at least one service");

    setLoading(true);

    values.services = services.map((v) => ({
      service_id: v.service_id,
      quantity: v.quantity,
    }));

    values.discounts = discounts;
    const data = { ...values };
    if (!tokenFieldVisible) delete data.transaction_token;
    const res = saleId
      ? await updateSaleApiCall(selectedStore, saleId, {
          ...data,
          status: "live",
        })
      : await createSaleApiCall(selectedStore, data);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      // await dispatch(fetchSalesAction());
      // formik.resetForm();
      setTimeout(() => {
        // window.location.reload();
        // history.push(`/dashboard/sales/add-new`);
        window.history.back();
      }, 1000);
    }
    notification.notify("success", "sale Record Created");
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };
  const getTotal = useCallback(() => {
    let total = 0;
    services.forEach((v) => {
      console.info({ v });

      total += parseInt(
        (storeservicesHash[v.service_id]?.price || v.price || 0) *
          parseInt(v?.quantity || "0")
      );
    });
    console.info({ total });

    return total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  useEffect(() => {
    console.info({ services });
    setTotal(getTotal());
  }, [getTotal, services]);

  useEffect(() => {
    if (!customers?.length > 0) return;
    const temp1 = [];
    const temp2 = {};
    customers.forEach((v) => {
      temp1.push(v.customer_id);
      temp2[v.customer_id] = v.name;
    });
    setOptions(temp1);
    setOptionNames(temp2);
  }, [customers]);

  useEffect(() => {
    if (search.length > 1) getcustomers({ search });
  }, [search]);

  const getcustomers = useCallback(
    debounce(
      // eslint-disable-next-line consistent-return
      async (query) => {
        setCustomerLoading(true);
        const { success, resData, error } = await getCustomersApiCall(query);
        setCustomerLoading(false);
        if (success && resData) dispatch(setCustomers(resData?.data || []));
      },
      [500]
    ),
    []
  );

  useEffect(() => {
    if (!staffs?.length > 0) return;
    const temp1 = [];
    const temp2 = {};
    staffs.forEach((v) => {
      if (v.role === "regular") {
        temp1.push(v.user_id);
        temp2[v.user_id] = v.user.name;
      }
    });
    setFloorStaffs(temp1);
    setFloorStaffNames(temp2);
  }, [staffs]);

  const handleSubmit = (val) => {
    status.current = val;
    formik.submitForm();
  };

  const params = new URLSearchParams(history.location.search);
  const item = params.get("item");

  useEffect(() => {
    if (item) {
      const itemParsed = JSON.parse(item);
      console.info({ itemParsed });
      const {
        customer_id,
        customer_no,
        floor_attendant,
        staff,
        service_type,
        payment_method,
        date,
        items,
        customer,
        sale_services,
        id,
      } = itemParsed;
      if (sale_services)
        setServices(
          sale_services.map((v) => ({
            service_id: v.service_id,
            quantity: v?.service_units,
            name: v.service_name,
            price: v.service_unit_price,
          }))
        );
      formik.setValues({
        customer_id: customer_no,
        floor_attendant,
        staff_id: staff?.staff_number,
        service_type,
        payment_method,
        date: date.substring(0, 16),
        items,
      });
      setSaleId(id);
      if (!optionNames[customer_id]) {
        setOptions((v) => [...v, customer_no]);
        setOptionNames((v) => ({ ...v, [customer_no]: customer }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleDateSelect = (value) => {
    console.info({ value });
    formik.handleChange({
      type: "change",
      target: { value: value?._d, name: "date" },
    });
  };

  return (
    <NewSalesContainer>
      <div className="left">
        <Tabs defaultActiveKey="1">
          {serviceCategories?.map((item) => (
            <TabPane tab={item.name} key={item.id}>
              <ServiceSale
                serviceCategoryId={item.id}
                services={services}
                setServices={setServices}
              />
            </TabPane>
          ))}
          <TabPane tab="Discount" key={serviceCategories?.length + 1 || 2}>
            <DiscountSale discounts={discounts} setDiscounts={setDiscounts} />
          </TabPane>
        </Tabs>
      </div>
      <div className="right">
        <h3>New Sale</h3>
        <form onSubmit={formik.handleSubmit}>
          <label>Customer ID</label>
          <br />

          <Autocomplete
            id="customer_id"
            name="customer_id"
            size="small"
            freeSolo
            defaultValue={formik.values.customer_id}
            value={formik.values.customer_id}
            sx={{ border: "1px solid #c4c4c4", borderRadius: "5px" }}
            options={options}
            getOptionLabel={(option) => optionNames[option] || ""}
            onChange={(v, value) =>
              formik.handleChange({
                ...v,
                type: "change",
                target: { value, name: "customer_id" },
              })
            }
            filterOptions={(x) => x}
            error={
              formik.touched.customer_id && Boolean(formik.errors.customer_id)
            }
            helperText={formik.touched.customer_id && formik.errors.customer_id}
            renderInput={(params) => (
              <TextField
                {...params}
                // value={formik.values.customer_id}
                onChange={(e) => setSearch(e.target.value)}
                name="customer_id"
                error={
                  formik.touched.customer_id &&
                  Boolean(formik.errors.customer_id)
                }
                helperText={
                  formik.touched.customer_id && formik.errors.customer_id
                }
                sx={{ border: "none" }}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {customerLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
          <label>Floor Attendant</label>
          <br />
          <Autocomplete
            id="floor_attendant"
            name="floor_attendant"
            size="small"
            // sx={{ width: 300 }}
            sx={{ border: "1px solid #c4c4c4", borderRadius: "5px" }}
            options={floorStaffs}
            getOptionLabel={(option) => floorStaffNames[option] || ""}
            value={formik.values.floor_attendant}
            defaultValue={formik.values.floor_attendant}
            onChange={(v, value) =>
              formik.handleChange({
                ...v,
                type: "change",
                target: { value, name: "floor_attendant" },
              })
            }
            error={
              formik.touched.floor_attendant &&
              Boolean(formik.errors.floor_attendant)
            }
            helperText={
              formik.touched.floor_attendant && formik.errors.floor_attendant
            }
            renderInput={(params) => (
              <TextField
                {...params}
                value={formik.values.floor_attendant}
                onChange={formik.handleChange}
                name="floor_attendant"
                error={
                  formik.touched.customer_id &&
                  Boolean(formik.errors.floor_attendant)
                }
                helperText={
                  formik.touched.floor_attendant &&
                  formik.errors.floor_attendant
                }
                sx={{ border: "none" }}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
          {/* <input
            type="text"
            placeholder="Enter ID of customer"
            name="customer_id"
            id="customer_id"
            onBlur={formik.handleBlur}
            value={formik.values.customer_id}
            onChange={formik.handleChange}
          /> */}
          {/* {formik.errors.customer_id && formik.touched.customer_id && (
            <div style={{ color: "red" }}> {formik.errors.customer_id}</div>
          )}

          <br /> */}
          <label>Staff ID</label>
          <br />
          <input
            type="text"
            placeholder="Enter staff ID"
            name="staff_id"
            id="staff_id"
            onBlur={formik.handleBlur}
            value={formik.values.staff_id}
            onChange={formik.handleChange}
            // autocomplete="off"
          />
          {formik.errors.staff_id && formik.touched.staff_id && (
            <div style={{ color: "red" }}> {formik.errors.staff_id}</div>
          )}
          <br />
          <label>Service Type</label>
          <br />
          <select
            name="service_type"
            id="service_type"
            required
            onBlur={formik.handleBlur}
            value={formik.values.service_type}
            onChange={formik.handleChange}
          >
            <option value="">Select service type</option>
            <option value="self-service">Self Service</option>
            <option value="drop-off">Drop Off</option>
          </select>
          {formik.errors.service_type && formik.touched.service_type && (
            <div style={{ color: "red" }}> {formik.errors.service_type}</div>
          )}
          <br />
          <label>Payment Method</label>
          <br />
          <select
            name="payment_method"
            id="payment_method"
            onBlur={formik.handleBlur}
            value={formik.values.payment_method}
            onChange={formik.handleChange}
            required
          >
            <option value="" hidden>
              Select payment method
            </option>
            {/* <option value="cash">Cash</option>
            <option value="transfer">Transfer</option> */}
            <option value="pos">Pos</option>
            <option value="pending">Pending</option>
            {/* <option value="walet">Walet</option> */}
            <option value="onground_wallet_sale">Wallet</option>
            <option value="transfer">Transfer</option>
          </select>
          {formik.errors.payment_method && formik.touched.payment_method && (
            <div style={{ color: "red" }}> {formik.errors.payment_method}</div>
          )}
          <br />
          {tokenFieldVisible && (
            <>
              <label>Customer Token</label>
              <br />
              <input
                type="text"
                placeholder="Enter token"
                name="transaction_token"
                id="transaction_token"
                onBlur={formik.handleBlur}
                value={formik.values.transaction_token}
                onChange={formik.handleChange}
                // autocomplete="off"
              />
              {formik.errors.transaction_token &&
                formik.touched.transaction_token && (
                  <div style={{ color: "red" }}>
                    {" "}
                    {formik.errors.transaction_token}
                  </div>
                )}
              <br />
            </>
          )}
          <label>Date</label>
          <br />
          <DatePicker
            type="date"
            placeholder="Choose date"
            name="date"
            id="date"
            onBlur={formik.handleBlur}
            value={formik.values.date}
            defaultValue={formik.values.date}
            sx={{ width: "100%" }}
            onChange={(e) => handleDateSelect(e)}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
          />
          {formik.errors.date && formik.touched.date && (
            <div style={{ color: "red" }}> {formik.errors.date}</div>
          )}
          <br />
          <label>Item</label>
          <br />
          <input
            type="text"
            placeholder="Input items"
            name="items"
            id="items"
            onBlur={formik.handleBlur}
            value={formik.values.items}
            onChange={formik.handleChange}
          />
          {formik.errors.items && formik.touched.items && (
            <div style={{ color: "red" }}> {formik.errors.items}</div>
          )}
        </form>

        <div className="item-summary">
          {services.map((item, index) => {
            return (
              <div className="item-info" key={index}>
                <div
                  className=""
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {storeservicesHash[item.service_id]?.name}
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    X{item?.quantity}
                  </p>
                </div>
                <p style={{ display: "flex" }}>
                  N
                  {(storeservicesHash[item.service_id]?.price ?? item?.price) *
                    item?.quantity}
                </p>
              </div>
            );
          })}
          <div className="total">
            <p>{`Tax`.concat("-")} </p>
            <p>%{tax}</p>
          </div>
          <div className="total">
            <p>{`Total`.concat("-")} </p>
            <p>N{total + (total * tax) / 100}</p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <LoadingButton
            loading={loading && status.current === "draft"}
            onClick={() => handleSubmit("draft")}
            loadingPosition="end"
            className="submit-store-btn"
            sx={{ width: "49%" }}
          >
            Save Draft
          </LoadingButton>

          <LoadingButton
            loading={loading && status.current === "live"}
            onClick={() => handleSubmit("live")}
            loadingPosition="end"
            fullWidth
            sx={{ width: "49%" }}
            className="submit-store-btn"
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </NewSalesContainer>
  );
};

export default AddNewSales;

const NewSalesContainer = styled.div`
  display: grid;
  grid-template-columns: [col1-start] 1.3fr [col1-end col2-start] 2fr [col2-end];
  gap: 2rem;
  //   align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }
  fieldset {
    border: none !important;
  }

  .left {
    // height: calc(100vh - 7rem);
    // height: 992px;
    background: #ffffff;
    border-radius: 15px;
    padding: 1rem 2rem;
  }
  .right {
    // height: calc(100vh - 7rem);
    // height: 992px;
    background: #ffffff;
    border-radius: 15px;
    padding: 1rem 2rem;
    h3 {
      font-size: 1.25rem;
    }
    .MuiAutocomplete-input {
      border: none !important;
    }

    form {
      label {
        margin-top: 0.8rem;
        font-size: 1rem;
      }
      input,
      select {
        width: 100%;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        padding: 0.5rem;
        outline: none;
        background: transparent;
        margin-top: 0.5rem;

        ::placeholder {
          color: #acacac;
        }
      }
    }
    .item-summary {
      margin-top: 3rem;
      .item-info,
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .total {
        font-weight: 700;
      }
    }
    .submit-store-btn {
      color: #fff;
      background: var(--secondary-bg-color);
      border-radius: 5px;
      margin-top: 2.5rem;
      width: 49%;
    }
    .add-new-sales-btn {
      background: var(--secondary-bg-color);
      border-radius: 5px;
      padding: 0.5rem;
      color: #ffffff;
      width: 70%;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      margin: 2rem auto;
    }
  }
`;
