/* eslint-disable eqeqeq */
import { useEffect, useRef } from "react";

export function parseEerror(error) {
  return JSON.stringify(error).replaceAll('"', "").replaceAll("_", " ");
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export function timeSince(date) {
  // '2022-03-27T15:17:40.000000Z'

  console.info({ date }, new Date(date));

  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} year${
      Math.floor(interval) == 1 ? "" : "s"
    }`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} month${
      Math.floor(interval) == 1 ? "" : "s"
    }`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} day${Math.floor(interval) == 1 ? "" : "s"}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hour${
      Math.floor(interval) == 1 ? "" : "s"
    }`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} minute${
      Math.floor(interval) == 1 ? "" : "s"
    }`;
  }
  return `${Math.floor(interval)} second${
    Math.floor(interval) == 1 ? "" : "s"
  }`;
}
