import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const customersUrl = `${BASE_URL}/customers`;
const individualCustomerUrl = (customerId) => `${customersUrl}/${customerId}`;

export function getCustomersApiCall(query) {
  return request({ url: customersUrl, query });
}

export function getCustomerApiCall(customerId) {
  return request({ url: individualCustomerUrl(customerId) });
}

export function createCustomerApiCall(payload) {
  return request({ url: customersUrl, method: "post", payload });
}

export function updateCustomerApiCall(customerId, payload) {
  return request({
    url: individualCustomerUrl(customerId),
    method: "put",
    payload,
  });
}

export function deleteCustomerApiCall(customerId) {
  return request({
    url: individualCustomerUrl(customerId),
    method: "delete",
  });
}

export function creditCustomerApiCall(customerId, payload) {
  return request({
    url: `${individualCustomerUrl(customerId)}/credit`,
    method: "patch",
    payload,
  });
}

export function debitCustomerApiCall(customerId, payload) {
  return request({
    url: `${individualCustomerUrl(customerId)}/debit`,
    method: "patch",
    payload,
  });
}
