import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const purchaseUrl = (storeId) => `${BASE_URL}/${storeId}/purchase-items`;

export function getPurchaseItemApiCall(storeId, query) {
  return request({ url: purchaseUrl(storeId), query });
}

export function createPurchaseItemApiCall(storeId, payload) {
  return request({ url: purchaseUrl(storeId), method: "post", payload });
}

export function getPurchaseItemHistoryApiCall(storeId, itemId, query) {
  return request({
    url: `${purchaseUrl(storeId)}/${itemId}/purchase-inventories`,
    query,
  });
}

export function createPurchaseItemHistoryApiCall(storeId, itemId, payload) {
  return request({
    url: `${purchaseUrl(storeId)}/${itemId}/purchase-inventories`,
    payload,
    method: "post",
  });
}
