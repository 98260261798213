import {
  Autocomplete,
  ButtonBase,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  TextField,
} from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const FilterModal = ({ handleClose, visible, handleCancel }) => {
  const staffs = useSelector((state) => state.Store.storeStaffs);

  const [options, setoptions] = useState([]);
  const [value, setValue] = React.useState();
  const [state, setState] = useState({});

  const handleChange = (field, value) => {
    const temp = { ...state };
    setState({ ...temp, [field]: value });
  };

  const clearAll = () => {
    setState({});
  };

  useEffect(() => {
    const temp = [];
    if (staffs?.length > 0) {
      staffs.forEach((v) => (v.user?.name ? temp.push(v.user.name) : null));
    }
    setoptions([...temp]);
  }, [staffs]);

  if (!visible) return null;
  return (
    <FilterBox>
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h3>Filter by</h3>
      <form>
        <label>Enter staff name below</label>
        <br />
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          options={options}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            // <input
            //   {...params}
            //   placeholder="Enter Name"
            //   style={{
            //     width: "100%",
            //     border: "1px solid #c4c4c4",
            //     borderRadius: "5px",
            //     padding: "0.5rem",
            //     outline: "none",
            //     background: "transparent",
            //     paddingLeft: "10px",
            //     paddingRight: "10px",
            //   }}
            // />
            <TextField
              {...params}
              // value={search}
              // onChange={(e) => setSearch(e.target.value)}
              variant="standard"
              disableUnderline
              sx={{
                width: "100%",
                border: "1px solid #c4c4c4",
                borderRadius: "5px",
                padding: "0.5rem",
                outline: "none",
                background: "transparent",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
        {/* <input
          onChange={(e) => setState(e.target.value)}
          onBlur={(e) => handleClose(e.target.value)}
          type="text"
        /> */}
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography
            sx={{
              color: "var(--secondary-bg-color)",
              cursor: "pointer",
              marginRight: 5,
            }}
            onClick={clearAll}
          >
            {" "}
            clear all
          </Typography>
        </div>
        <FormGroup sx={{ mt: 5 }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChange("staff", e.target.checked ? true : null)
                }
              />
            }
            label="Staff"
          />
        </FormGroup>
        <FormGroup sx={{ mt: 5, fontSize: 50 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.service_type === "drop-off"}
                onChange={(e) =>
                  handleChange(
                    "service_type",
                    e.target.checked ? "drop-off" : null
                  )
                }
              />
            }
            label="Drop Off"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.service_type === "self-service"}
                onChange={(e) =>
                  handleChange(
                    "service_type",
                    e.target.checked ? "self-service" : null
                  )
                }
              />
            }
            label="Self Service"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.service_type === "pickup"}
                onChange={(e) =>
                  handleChange(
                    "service_type",
                    e.target.checked ? "pickup" : null
                  )
                }
              />
            }
            label="Pickup"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.service_type === "pickup-and-delivery"}
                onChange={(e) =>
                  handleChange(
                    "service_type",
                    e.target.checked ? "pickup-and-delivery" : null
                  )
                }
              />
            }
            label="Pickup and Delivery "
          />
        </FormGroup>{" "}
        <FormGroup sx={{ mt: 5 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "pos"}
                onChange={(e) =>
                  handleChange("payment", e.target.checked ? "pos" : null)
                }
              />
            }
            label="POS"
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "cash"}
                onChange={(e) =>
                  handleChange("payment", e.target.checked ? "cash" : null)
                }
              />
            }
            label="Cash"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "transfer"}
                onChange={(e) =>
                  handleChange("payment", e.target.checked ? "transfer" : null)
                }
              />
            }
            label="Transfer"
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "onground_wallet_sale"}
                onChange={(e) =>
                  handleChange(
                    "payment",
                    e.target.checked ? "onground_wallet_sale" : null
                  )
                }
              />
            }
            label="Wallet(on-ground)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "wallet"}
                onChange={(e) =>
                  handleChange("payment", e.target.checked ? "wallet" : null)
                }
              />
            }
            label="Wallet(in-app)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "transfer"}
                onChange={(e) =>
                  handleChange("payment", e.target.checked ? "transfer" : null)
                }
              />
            }
            label="Transfer"
          />
        </FormGroup>{" "}
        <FormGroup sx={{ mt: 5 }}>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={state?.payment === "wallet"}
                onChange={(e) =>
                  handleChange("payment", e.target.checked ? "wallet" : null)
                }
              />
            }
            label="Wallet"
          /> */}
        </FormGroup>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonBase
            className="add-filter-btn"
            onClick={(e) => handleClose({ ...state, value })}
          >
            Done
          </ButtonBase>
        </div>
      </form>
    </FilterBox>
  );
};

export default FilterModal;

const FilterBox = styled.div`
  .add-filter-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 12rem;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--secondary-bg-color);
    color: #fff;
    border: none;

    img {
      margin-right: 0.5rem;
    }
    @media screen and (max-width: 480px) {
      .add-filter-btn {
        width: 10rem;
      }
    }
    @media screen and (max-width: 380px) {
      .add-filter-btn {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
    }
  }

  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  left: 30%;
  right: 30%;
  top: 5rem;
  max-width: 500px;
  z-index: 9;
  padding: 3rem 1rem;
  @media screen and (max-width: 900px) {
    top: 7rem;
  }
  @media screen and (max-width: 768px) {
    left: 25%;
    right: 25%;
  }
  @media screen and (max-width: 600px) {
    left: 15%;
    top: 8rem;
  }
  right: 15%;
  @media screen and (max-width: 480px) {
    left: 10%;
    right: 10%;
    top: 10rem;
  }
  @media screen and (max-width: 380px) {
    left: 1%;
    right: 1%;
  }
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 1rem;

    p {
      margin: 0;
      font-weight: 600;
    }
    img {
      cursor: pointer;
    }
  }

  .notifications {
    padding: 1rem 0.5rem;
    max-height: 70vh;
    overflow-y: auto;
    ::-webkit-scrollbar-thumb {
      background: #919191;
      border-radius: 13px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #cacaca;
    }
    ::-webkit-scrollbar {
      width: 11px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0;
      background-color: #fff;
    }
    .notification {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem;
      margin-bottom: 0.25rem;

      .time {
        font-size: 0.8rem;
      }
      :hover {
        background-color: #e7f1f5;
        border-radius: 6px;
        cursor: pointer;
      }

      .img-sec {
        display: flex;
        align-items: center;
        padding-right: 1rem;
        p {
          font-weight: 600;
          font-size: 0.85rem;
        }
        img {
          margin-right: 0.5rem;
          width: 48px;
          height: 48px;
        }
      }
    }
    p {
      margin: 0;
    }
  }
`;
