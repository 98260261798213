import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";

import * as yup from "yup";
import { useFormik } from "formik";
import { createStoreApiCall } from "../../Api/storApi";
import { useDispatch } from "react-redux";
import { fetchStoresAction } from "../../store/reducers/store";
import { LoadingButton } from "@mui/lab";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";

export const NewStoreModal = ({ visible, handleOk, handleCancel }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,

    onSubmit: (values) => {
      createStore(values);
    },
  });
  const createStore = async (values) => {
    setLoading(true);
    const res = await createStoreApiCall(values);
    const { success, error } = res;

    if (success) {
      await dispatch(fetchStoresAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Store Created");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!visible) return false;

  return (
    <Modal
      centered
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      style={{ padding: "5rem 1.875rem" }}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: "-7.83px",
          right: "-8.83px",
          cursor: "pointer",
          width: "2.604375rem",
          height: "2.604375rem",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center", fontSize: "1.25rem" }}>
        Add New Store
      </h4>
      <StoreForm onSubmit={formik.handleSubmit}>
        <label>Store Name</label>
        <input
          type="text"
          placeholder="Enter store name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}

        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </StoreForm>
    </Modal>
  );
};

const StoreForm = styled.form`
  margin-top: 2.9375rem;
  label {
    font-size: 1.125rem;
  }
  input {
    margin-top: 0.8125rem;
    outline: none;
    border: none;
    width: 100%;
    padding: 0.8125rem 1.375rem;
    font-size: 1.125rem;

    ::placeholder {
      color: #acacac;
      font-size: 1.125rem;
    }
  }
  .new-store-input {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    backgound-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 3.1875rem;
    font-size: 1.125rem;
  }
`;
