import React from "react";
import styled from "styled-components";

const DashboardActionsButtons = ({ button_text, action }) => {
  return (
    <DashboardActionButton onClick={action}>
      {button_text}
    </DashboardActionButton>
  );
};

export default DashboardActionsButtons;

const DashboardActionButton = styled.button`
  outline: none;
  background: transparent;
  border: 1px solid var(--secondary-border-color);
  border-radius: 7px;
  width: 100%;
  margin-top: 0.75rem;
  padding: 0.6875rem;
  color: var(--secondary-txt-color);
  text-align: center;
`;
