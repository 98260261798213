import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearchDate from "../../components/search/dateSearch";
import AddPayoutForm from "../../components/modals/addPayout";
import PayoutsReceipt from "../../components/modals/receiptModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayOutsAction } from "../../store/reducers/store";
import { Grid, Typography } from "@mui/material";
import { ExportToExcel } from "../../components/exportFile";
import { Redirect } from "react-router";
import { Box } from "@mui/system";

const Payout = () => {
  const dispatch = useDispatch();
  const payouts = useSelector((state) => state.Store.payouts) || [];
  const hashTable = useSelector((state) => state.Store.hashTable) || {};
  const [date, setDate] = useState(null);
  const banksHash = hashTable.banks || {};
  // console.info()
  const preparedPayouts = payouts;
  // .map((v) => ({
  //   ...v,
  //   attendant: v.staff?.name,
  //   name: v.customer?.name,
  //   phone: v.customer?.phone,
  //   payment: v.payment_method,
  //   item: v.items,
  //   amount: v.price,
  // }));
  const [showAddPayoutModal, setShowAddPayoutModal] = useState(false);
  const [salesData, setPayoutsData] = useState(null);

  const [showReceiptModal, setshowReceiptModal] = useState(false);

  const salesTableCols = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Bank",
      dataIndex: "bank_id",
      key: "bank_id",
      render: (text) => <span>{banksHash[text]?.name ?? "N/A"}</span>,
    },
    {
      title: "Acount No",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/icons/ion_receipt-outline.png"
            alt="edit_icon"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              printPayoutsData(record);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const payload = {};
    if (date) payload.date = date;

    dispatch(fetchPayOutsAction(payload));
  }, [dispatch, date]);

  const getTotal = () => {
    let total = 0;
    preparedPayouts.forEach((v) => (total += parseFloat(v.amount)));
    return total;
  };
  const printPayoutsData = (record) => {
    setPayoutsData(record);
    setshowReceiptModal(true);
  };

  const closeAddPayout = () => {
    setShowAddPayoutModal(false);
    setPayoutsData(null);
  };

  const closePrintPayouts = () => {
    setshowReceiptModal(false);
    setPayoutsData(null);
  };
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <PayoutTable>
      {showAddPayoutModal && (
        <AddPayoutForm
          visible={showAddPayoutModal}
          handleCancel={closeAddPayout}
          salesData={salesData}
        />
      )}
      {showReceiptModal && (
        <PayoutsReceipt
          visible={showReceiptModal}
          handleCancel={closePrintPayouts}
          salesData={salesData}
        />
      )}
      <div className="sales-tools">
        {/* <Typography variant="h5" sx={{ marginRight: 2 }}>
          Payout For {date ? months[new Date(date).getMonth()] : month},
          {date ? new Date(date).getFullYear() : d.getFullYear()}
        </Typography> */}
        <Grid container>
          <Grid item xs={12} sm={6} md={3} sx={{ paddingRight: "10px" }}>
            <div
              className="sales-date"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ p: 1 }}>From </Typography>
              <CustomSearchDate
                handleSearch={setDate}
                search_placeholder="Payouts Date"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              paddingRight: "10px",
            }}
          >
            <div
              className="sales-date"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ p: 1 }}>To </Typography>
              <CustomSearchDate
                handleSearch={setDate}
                search_placeholder="Payouts Date"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ width: "80%" }}>
              <button
                className="add-sale-btn"
                onClick={() => setShowAddPayoutModal(true)}
              >
                <img
                  src="/assets/icons/add_circle_outline.png"
                  alt="add store"
                />
                Add Payout
              </button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ExportToExcel
              apiData={preparedPayouts}
              fileName="sales report"
              text_name="Report"
            />
          </Grid>
        </Grid>
      </div>
      <CustomTable
        table_columns={salesTableCols}
        table_data={preparedPayouts}
        table_pagination={{
          defaultPageSize: 9,
          simple: true,
        }}
      />
      <div className="report-total">
        <p className="total-text">Total</p>
        <p>N{getTotal()}</p>
      </div>
    </PayoutTable>
  );
};

export default Payout;

const PayoutTable = styled.div`
  .report-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem auto;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 1.2rem 4rem;
    font-size: 1.125rem;

    p {
      margin: 0;
      font-weight: 700;
    }
    .total-text {
      font-weight: 700;
    }
  }
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-sales,
    .sales-date {
      margin-right: 1rem;
    }
    .sales-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
