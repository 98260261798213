import { Close, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createDiscountApiCall,
  deleteDiscountApiCall,
  updateDiscountApiCall,
} from "../../Api/discount";
import { updateTaxsApiCall } from "../../Api/tax";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import {
  fetchDiscountsAction,
  fetchTaxAction,
} from "../../store/reducers/store";

const SalesTax = () => {
  const storeDiscounts = useSelector((state) => state.Store.discounts);
  const storeId = useSelector((state) => state.Store.selectedStore);
  const storeTtax = useSelector((state) => state.Store.tax);
  const [tax, setTax] = useState();

  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeDiscounts.length > 0) setDiscounts(storeDiscounts);
  }, [storeDiscounts]);

  useEffect(() => {
    if (storeTtax) setTax(storeTtax);
  }, [storeTtax]);

  const addDiscount = () => {
    setDiscounts([...discounts, { name: "", price: "" }]);
  };

  const handleChange = (index, field, value) => {
    const temp = [...discounts];
    const discountToChange = temp[index];
    console.info(discountToChange);
    temp.splice(index, 1, { ...discountToChange, [field]: value });
    setDiscounts([...temp]);
  };

  const saveDiscount = async (discountIndex) => {
    setLoading(`s${discountIndex}`);

    console.info(discounts[discountIndex]);
    const discountId = discounts[discountIndex]?.id;
    const res = discountId
      ? await updateDiscountApiCall(storeId, discountId, {
          name: discounts[discountIndex]?.name,
          percentage_off: discounts[discountIndex]?.percentage_off,
        })
      : await createDiscountApiCall(storeId, discounts[discountIndex]);
    const { success, resData, error } = res;
    setLoading(null);

    console.info({ success, resData, error });
    if (success) {
      notification.notify(
        "success",
        discountId ? "Discount Updated" : "Discount Saved"
      );
      dispatch(fetchDiscountsAction());
    }

    if (error) notification.notify("error", parseEerror(error));
  };

  const deleteDiscount = async (discountIndex) => {
    const discountId = discounts[discountIndex]?.id;

    if (discountId) {
      setLoading(`d${discountIndex}`);
      const res = await deleteDiscountApiCall(storeId, discountId);
      const { success, resData, error } = res;

      console.info({ success, resData, error });
      setLoading(null);

      if (success) {
        dispatch(fetchDiscountsAction());
        notification.notify("success", "Discount Deleted");
      }

      if (error) notification.notify("error", parseEerror(error));
      return;
    }

    const temp = [...discounts];
    temp.splice(discountIndex, 1);
    setDiscounts(temp);
  };

  const saveTax = async () => {
    setLoading(`tax`);

    const res = await updateTaxsApiCall(storeId, { tax });
    const { success, resData, error } = res;
    setLoading(null);

    console.info({ success, resData, error });
    if (success) {
      notification.notify("success", "Tax Saved");
      dispatch(fetchTaxAction());
    }

    if (error) notification.notify("error", parseEerror(error));
  };

  return (
    <Box
      gap={2}
      sx={{
        backgroundColor: "rgb(240, 242, 245)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          flex: "0.3",
          mb: 2,
        }}
      >
        <Grid sx={{ p: 5 }} container>
          <Grid
            sx={{ display: "flex", justifyContent: "center", p: 3 }}
            item
            sm={12}
          >
            <Typography variant="h5">Taxes</Typography>
          </Grid>
          <Grid item sm={12} md={6} sx={{ p: 2 }}>
            <div className="input">
              <input
                // type={showPassword}
                placeholder="Enter Vat"
                name="vat"
                id="vat"
                onChange={(e) => setTax(e.target.value)}
                value={tax}
                // onChange={formik.handleChange}
                style={{
                  width: "100%",
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "0.5rem",
                  outline: "none",
                  background: "transparent",
                }}
              />
            </div>
          </Grid>

          <Grid item sm={12} md={6} sx={{ p: 2 }}>
            <LoadingButton
              sx={{ color: "white", backgroundColor: "#0088c0" }}
              fullWidth
              loading={loading === "tax"}
              onClick={saveTax}
            >
              Save
            </LoadingButton>{" "}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{ backgroundColor: "#ffffff", borderRadius: "10px", flex: "0.7" }}
      >
        <Grid sx={{ p: 7 }} container>
          <Grid
            sx={{ display: "flex", justifyContent: "center", p: 3 }}
            item
            sm={12}
          >
            <Typography variant="h5">Discount</Typography>
          </Grid>
          {discounts.map((v, i) => (
            <Grid
              key={`${i}${v.name}${v.percentage_off}`}
              item
              sx={12}
              container
            >
              <Grid item sm={10} sx={{ paddingRight: 2 }}>
                <div className="input">
                  <input
                    // type={showPassword}
                    placeholder="Enter Discount"
                    name="name"
                    id="name"
                    defaultValue={v.name}
                    onBlur={(e) => handleChange(i, "name", e.target.value)}
                    key={`${i}${v.name}${v.percentage_off}`}
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      padding: "0.5rem",
                      outline: "none",
                      background: "transparent",
                    }}
                  />
                </div>
              </Grid>
              <Grid item sm={1} sx={{ paddingRight: 1 }}>
                <div className="input">
                  <input
                    // type={showPassword}
                    placeholder="%"
                    name="percentage_off"
                    id="percentage_off"
                    defaultValue={v.percentage_off}
                    onBlur={(e) =>
                      handleChange(i, "percentage_off", e.target.value)
                    }
                    key={`${i}${v.name}${v.percentage_off}`}
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      padding: "0.5rem",
                      outline: "none",
                      background: "transparent",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      // marginLeft: "10px",
                    }}
                  />
                </div>
              </Grid>

              <Grid
                item
                sm={1}
                sx={{
                  // paddingLeft: 2,
                  // paddingRight: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {loading === `s${i}` ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "green",
                      backgroundColor: "lightGreen",
                      marginLeft: "auto",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 1,
                    }}
                    onClick={() => saveDiscount(i)}
                  >
                    <Save />
                  </Box>
                )}
                {loading === `d${i}` ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "red",
                      backgroundColor: "#FFCACA",
                      marginLeft: "auto",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 1,
                    }}
                    onClick={() => deleteDiscount(i)}
                  >
                    <Close />
                  </Box>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid
          sx={{ display: "flex", justifyContent: "center", p: 3 }}
          item
          sm={12}
          container
        >
          <Grid
            sx={{ display: "flex", justifyContent: "center", p: 1 }}
            item
            sm={12}
          >
            <LoadingButton
              sx={{ color: "white", backgroundColor: "#0088c0", width: "50%" }}
              onClick={addDiscount}
            >
              Add New Discount
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SalesTax;
