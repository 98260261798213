import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { createCustomerApiCall } from "../../Api/customerApi";
import { fetchStoreAction } from "../../store/reducers/store";

const AddCustomerModal = ({ visible, handleOk, handleCancel }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
    address: yup.string().required("this field is required"),
    phone: yup.number().required("this field is required").min(11),
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
    },
    validationSchema,

    onSubmit: (values) => {
      createCustomer(values);
    },
  });

  const createCustomer = async (values) => {
    setLoading(true);
    const res = await createCustomerApiCall(values);
    const { success, error } = res;
    //console.info({ resData, error });
    if (success) {
      await dispatch(fetchStoreAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Customer Created");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Add New Customer</h4>
      <CustomerForm onSubmit={formik.handleSubmit}>
        <label>Customer Name</label>
        <input
          type="text"
          placeholder="Enter customer name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}
        <label>Email</label>
        <input
          type="text"
          placeholder="Enter customer email address"
          className="new-store-input"
          name="email"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email && formik.touched.email && (
          <div style={{ color: "red" }}> {formik.errors.email}</div>
        )}
        <label>Phone</label>
        <input
          type="text"
          placeholder="Enter customer phone number"
          className="new-store-input"
          name="phone"
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
        {formik.errors.phone && formik.touched.phone && (
          <div style={{ color: "red" }}> {formik.errors.phone}</div>
        )}
        <label>Address</label>
        <input
          type="text"
          placeholder="Enter customer address"
          className="new-store-input"
          name="address"
          onBlur={formik.handleBlur}
          value={formik.values.address}
          onChange={formik.handleChange}
        />
        {formik.errors.address && formik.touched.address && (
          <div style={{ color: "red" }}> {formik.errors.address}</div>
        )}

        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </CustomerForm>
    </Modal>
  );
};

export default AddCustomerModal;

const CustomerForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    ::placeholder {
      color: #acacac;
    }
  }
  .new-store-input {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
`;
