import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import { useHistory } from "react-router";
import CustomSearch from "../../components/search";
import CustomSearchDate from "../../components/search/dateSearch";
import SalesEditForm from "../../components/modals/salesEdit";
import SalesReceipt from "../../components/modals/receiptModal2";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesAction } from "../../store/reducers/store";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, ButtonBase } from "@mui/material";

const AllSales = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sales = useSelector((state) => state.Store.storeSales) || [];
  // const loading = useSelector((state) => state.App.loading) || [];
  const hashTable = useSelector((state) => state.Store.hashTable) || {};
  const customers = hashTable.customers || {};
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(null);
  const staffs = hashTable.staffs || {};
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  const preparedSales = sales.map((v) => ({
    ...v,
    attendant: v.staff?.name,
    customer: v.customer?.name,
    payment: v.payment_method,
    item: v.items,
    amount: v.price,
    customerPhone: v.customer.phone,
    customerAddress: v.customer.address,
  }));
  console.info({ preparedSales, staffs, customers, sales });
  const [showEditSalesModal, setShowEditSalesModal] = useState(false);
  const [salesData, setSalesData] = useState(null);

  const [showReceiptModal, setshowReceiptModal] = useState(false);
  const salesTableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      render: (text) => (
        <span>
          {text
            ?.replace("onground_wallet_sale", "Wallet(on-ground)")
            .replace("wallet", "Wallet(in-app)")}
        </span>
      ),
    },
    {
      title: "Attendant",
      dataIndex: "attendant",
      key: "attendant",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{text.substring(0, 19)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/icons/fe_edit.png"
            alt="edit_icon"
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => selectSalesData(record)}
          />
          <img
            src="/assets/icons/ion_receipt-outline.png"
            alt="edit_icon"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              printSalesData(record);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.info({ query, date });
    const payload = { page: currentPage };
    if (date) payload.date = date;
    if (query && query !== "") payload.search = query;
    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchSalesAction(payload));
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    get(payload);
  }, [dispatch, query, date, currentPage]);

  const selectSalesData = (record) => {
    setSalesData(record);
    setShowEditSalesModal(true);
  };
  const printSalesData = (record) => {
    setSalesData(record);
    setshowReceiptModal(true);
  };

  const closeEditSales = () => {
    setShowEditSalesModal(false);
    setSalesData(null);
  };

  const closePrintSales = () => {
    setshowReceiptModal(false);
    setSalesData(null);
  };
  return (
    <SalesTable>
      {showEditSalesModal && (
        <SalesEditForm
          visible={showEditSalesModal}
          handleCancel={closeEditSales}
          salesData={salesData}
        />
      )}
      {showReceiptModal && (
        <SalesReceipt
          visible={showReceiptModal}
          handleCancel={closePrintSales}
          salesData={salesData}
        />
      )}
      <div className="sales-tools">
        <div className="search-sales">
          <CustomSearch
            search_placeholder="Search Sales"
            handleSearch={setQuery}
          />
        </div>
        <div className="sales-date">
          <CustomSearchDate
            handleSearch={setDate}
            search_placeholder="Sales Date"
          />
        </div>
        <button
          className="add-sale-btn"
          onClick={() => history.push("/dashboard/sales/add-new")}
        >
          <img src="/assets/icons/add_circle_outline.png" alt="add store" />
          Add New Sale
        </button>
      </div>
      <CustomTable
        table_columns={salesTableCols}
        table_data={preparedSales}
        // table_pagination={{
        //   defaultPageSize: 9,
        //   // simple: true,
        // }}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </SalesTable>
  );
};

export default AllSales;

const SalesTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .sales-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-sales,
    .sales-date {
      margin-right: 1rem;
    }
    .sales-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
