import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const notificatitonsUrl = `${BASE_URL}/notifications`;
const banksUrl = `${BASE_URL}/banks`;

export function getNotificatitonsApiCall() {
  return request({ url: notificatitonsUrl });
}

export function getBanksApiCall() {
  return request({ url: banksUrl });
}
