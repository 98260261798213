import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const storesUrl = `${BASE_URL}/stores`;
const messagingUrl = (storeId) => `${BASE_URL}/${storeId}/messages`;
const individualStoreUrl = (storeId) => `${storesUrl}/${storeId}`;

export function getStoresApiCall() {
  return request({ url: storesUrl });
}

export function getStoreApiCall(storeId) {
  return request({ url: individualStoreUrl(storeId) });
}

export function createStoreApiCall(payload) {
  return request({ url: storesUrl, method: "post", payload });
}

export function updateStoreApiCall(storeId, payload) {
  return request({ url: individualStoreUrl(storeId), method: "post", payload });
}

export function sendMessageApiCall(storeId, payload) {
  return request({ url: messagingUrl(storeId), method: "post", payload });
}

export function getMessagesApiCall(storeId, query) {
  return request({ url: messagingUrl(storeId), method: "get", query });
}

export function getMessageApiCall(storeId, id) {
  return request({ url: `${messagingUrl(storeId)}/${id}`, method: "get" });
}
