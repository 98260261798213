import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const taxsUrl = (storeId) => `${BASE_URL}/${storeId}/settings/tax`;

export function getTaxsApiCall(storeId) {
  return request({ url: taxsUrl(storeId) });
}

export function updateTaxsApiCall(storeId, payload) {
  return request({
    url: taxsUrl(storeId),
    method: "put",
    payload,
  });
}
