import { combineReducers } from "redux";
import exampleReducer from "./exampleReducer";
import searchReducer from "./search";
import userReducer from "./user";
import storeReducer from "./store";
import AppReducer from "./app";

export default combineReducers({
  example: exampleReducer,
  search: searchReducer,
  User: userReducer,
  Store: storeReducer,
  App: AppReducer,
});
