import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
// import { salesOverallData } from "../../data";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesAction } from "../../store/reducers/store";
import { LoadingButton } from "@mui/lab";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { updateSaleApiCall } from "../../Api/sales";

const SalesEditForm = ({ visible, handleOk, handleCancel, salesData = {} }) => {
  const [loading, setLoading] = useState();
  // const [services, setServices] = useState([]);
  // const [total, setTotal] = useState(0);
  const storeservices = useSelector((state) => state.Store.services) || [];
  const selectedStore = useSelector((state) => state.Store.selectedStore) || [];
  const dispatch = useDispatch();
  const storeservicesHash = {};
  storeservices.forEach((v) => {
    storeservicesHash[v.id] = v;
  });
  let validationSchema = yup.object({
    customer_id: yup.string().required("this field is required"),
    staff_id: yup.string().required("this field is required"),
    payment_method: yup.string().required("this field is required"),
    date: yup.string().required("this field is required"),
    items: yup.string().required("this field is required"),
    service_type: yup
      .string()
      .required("this field is required")
      .oneOf(["self-service", "drop-off"], "please select a service"),
  });
  const initialValues = {
    customer_id: salesData?.customer_id,
    staff_id: salesData?.staff_id,
    service_type: salesData?.service_type,
    payment_method: salesData?.payment_method,
    date: parseISOString(salesData?.date),
    items: salesData?.items,
  };

  console.info(initialValues);
  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (values) => {
      updateSale(values);
    },
  });

  const updateSale = async (values) => {
    setLoading(true);

    // const storeId = values.store;
    salesData.payment_method = values.payment_method;

    const res = await updateSaleApiCall(selectedStore, salesData.id, {
      payment_method: values.payment_method,
    });
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      formik.resetForm();
      await dispatch(fetchSalesAction());
      notification.notify("success", "sale Record Updated");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
    handleCancel();
  };

  // const getTotal = useCallback(() => {
  //   let total = 0;
  //   services.forEach((v) => {
  //     total += parseInt(
  //       (storeservicesHash[v.service_id]?.price || 0) * (v?.quantity || 0)
  //     );
  //   });
  //   return total;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [services]);

  // useEffect(() => {
  //   setTotal(getTotal());
  // }, [getTotal, services]);

  function parseISOString(s) {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Edit Sale</h4>
      <SalesForm onSubmit={formik.handleSubmit}>
        <label>Customer ID</label>
        <input
          type="text"
          placeholder="CO3345"
          name="customer_id"
          value={formik.values.customer_id}
          id="customer_id"
          onChange={formik.handleChange}
        />
        {formik.errors.customer_id && formik.touched.customer_id && (
          <div style={{ color: "red" }}> {formik.errors.customer_id}</div>
        )}
        <br />
        <label>Staff ID</label>
        <input
          value={formik.values.staff_id}
          type="text"
          placeholder="232311"
          name="staff_id"
          id="staff_id"
          onChange={formik.handleChange}
        />
        {formik.errors.staff_id && formik.touched.staff_id && (
          <div style={{ color: "red" }}> {formik.errors.staff_id}</div>
        )}
        <br />
        <label>Payment Method</label>
        <br />{" "}
        <select
          name="payment_method"
          id="payment_method"
          onBlur={formik.handleBlur}
          value={formik.values.payment_method}
          onChange={formik.handleChange}
          required
        >
          <option value="" hidden>
            Select payment method
          </option>
          <option hidden value="pending">
            Pending
          </option>
          {/* <option value="cash">Cash</option>
          <option value="transfer">Transfer</option> */}
          <option value="pos">Pos</option>
          <option value="onground_wallet_sale">Wallet</option>

          {/* <option value="walet">Walet</option> */}
        </select>
        {formik.errors.payment_method && formik.touched.payment_method && (
          <div style={{ color: "red" }}> {formik.errors.payment_method}</div>
        )}
        <br />
        <label>Date</label>
        <input
          type="date"
          placeholder="Enter customer address"
          name="date"
          id="date"
          onChange={formik.handleChange}
          value={formik.values.date}
        />
        {formik.errors.date && formik.touched.date && (
          <div style={{ color: "red" }}> {formik.errors.date}</div>
        )}
        <br />
        <label>Item</label>
        <input
          type="text"
          placeholder="2 Senator wear, 4 jeans"
          name="item"
          id="item"
          value={formik.values.items}
          onChange={formik.handleChange}
        />
        {formik.errors.items && formik.touched.items && (
          <div style={{ color: "red" }}> {formik.errors.items}</div>
        )}
        <br />
        <div className="sales-summary">
          {salesData?.sale_services.map((item, index) => {
            return (
              <div className="item-info" key={index}>
                <p className="item-name">{item.service_name}</p>
                <p>
                  N
                  {parseInt(item.service_unit_price || 0) *
                    parseInt(item.service_units || 0)}
                </p>
              </div>
            );
          })}
          <div className="total">
            <p>{`Total`.concat("-")} </p>
            <p>N{salesData?.amount || 0}</p>
          </div>
        </div>
        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </SalesForm>
    </Modal>
  );
};

export default SalesEditForm;

const SalesForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
    ::placeholder {
      color: #acacac;
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    .submit-sale-btn {
      color: #fff;
      background: var(--secondary-bg-color);
      border-radius: 5px;
      margin-top: 2.5rem;
      width: 60%;
    }
  }

  .sales-summary {
    margin-top: 3rem;
  }
  .sales-summary .item-info,
  .sales-summary .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sales-summary .total {
    font-weight: 600;
  }
`;
