/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Menu } from "antd";
import Purchase from "./purchase";
import Sales from "./sales";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { useLocation } from "react-router";
import Attendance from "./attendance";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import "./index.css";

const baseUrl = "/dashboard/inventory";
const sideMenus1 = [
  {
    name: "Sales",
    pathname: `${baseUrl}/sales`,
  },
  {
    name: "Purchase",
    pathname: `${baseUrl}/purchase`,
  },
];

const Inventory = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const [selected, setSelected] = useState([
    location.pathname.includes("purchase") ? "Purchase" : "Sales",
  ]);
  const [selectedCategory, setSelectedCAtegory] = useState(1);
  const user = useSelector((state) => state.User.user) || {};
  const serviceCategories =
    useSelector((state) => state.Store.serviceCategories) || {};
  const { role } = user;
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  const onClickMenu = (item) => {
    setSelected([item]);
  };

  const selectServiceCategory = (item) => {
    setSelected([item]);
    setSelectedCAtegory(item);
  };

  return (
    <InventoryContainer>
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={selected}
        className="menu-left"
      >
        {sideMenus1.map(({ name, pathname }, index) => {
          return (
            <Menu.Item key={name} onClick={() => onClickMenu(name)}>
              <Link to={pathname}>{name}</Link>
            </Menu.Item>
          );
        })}
      </Menu>

      <div className="menu-right" style={{ height: "87vh", padding: 0 }}>
        {location.pathname.includes("purchase") ? <Purchase /> : <Sales />}
      </div>
    </InventoryContainer>
  );
};

export default Inventory;

const InventoryContainer = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .menu-left {
    background: #ffffff;
    border-radius: 10px;
    flex: 0.3;
    margin-right: 1rem;
    color: #000;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 1rem;
      display: flex;
    }
  }
  .menu-right {
    flex: 0.7;
    background: #ffffff;
    border-radius: 10px;
    padding: 2rem;
  }
`;
