import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearch from "../../components/search";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { getPurchaseItemHistoryApiCall } from "../../Api/purchaseItem";
import AddItemEntryForm from "../../components/modals/addItemEntry";
import { setLoading } from "../../store/reducers/app";
import { getServiceItemHistoryApiCall } from "../../Api/service";

const InventoryItemDetails = () => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState(null);
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  // user privilage check
  // const { role } = useSelector((state) => state?.User?.user || {});
  const storeId = useSelector((state) => state.Store.selectedStore);

  const params = useParams();
  const { id, type, name } = params;

  // const hasPrivilage = role === "sub_admin" || role === "super_admin";

  const dispatch = useDispatch();

  const tableCols = [
    {
      title: "Reference",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "User name",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "User role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text.replace("_", " ")}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text.substring(0, 16)}
        </div>
      ),
    },
  ];
  const get = async (payload) => {
    dispatch(setLoading(true));

    const { success, resData } = await (type === "purchase"
      ? getPurchaseItemHistoryApiCall(storeId, id, payload)
      : getServiceItemHistoryApiCall(storeId, id, payload));
    dispatch(setLoading(false));

    if (resData && success) {
      setItems(
        resData?.data?.map((v) => ({
          ...v,
          role: v.user?.role,
          user: v.user?.name,
        })) || []
      );
      var lastPageNo = resData?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    }
  };
  useEffect(() => {
    const payload = { page: currentPage };
    if (query) payload.search = query;
    get(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query, currentPage, storeId, id]);

  const handleChange = async (data) => {
    // add page no to query
    // if (data != "") setCurrentPage(1);
    setQuery(data);
    //console.info("test");
  };

  return (
    <CustomerTable>
      <Box
        sx={{
          display: "flex",
          // justifyContent: "center",
          padding: "20px",
          fontSize: "24px",
        }}
      >
        {name}
      </Box>

      <AddItemEntryForm
        visible={showModal}
        handleCancel={() => setShowModal(false)}
        type={type}
        id={id}
        handleOk={get}
      />

      <div className="customer-tools">
        <div className="search-container">
          <CustomSearch
            handleSearch={handleChange}
            search_placeholder="Search "
          />
        </div>
        <button
          className="add-customer-btn"
          onClick={() => {
            if (showModal) {
              setShowModal(false);
            } else {
              setShowModal(true);
            }
          }}
        >
          <img src="/assets/icons/add_circle_outline.png" alt="add customer" />
          Add New Entry
        </button>
      </div>
      <CustomTable
        table_columns={tableCols}
        table_data={items}
        // table_pagination={{
        //   defaultPageSize: 9,
        //   simple: true,
        // }}
        row_class_name={"row-class"}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </CustomerTable>
  );
};

export default InventoryItemDetails;

const CustomerTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;
  .customer-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-container {
      margin-right: 1.5rem;
    }
    .add-customer-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
