import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import AddNewStaffModal from "../../components/modals/addNewStaff";
import StaffEditForm from "../../components/modals/staffEditModal";
import CustomSearch from "../../components/search";
import { useDispatch, useSelector } from "react-redux";
// import { CircularProgress } from "@mui/material";
import { fetchStaffsAction } from "../../store/reducers/store";
import { Redirect, useLocation, useHistory } from "react-router";
import { ButtonBase, CircularProgress } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/system";
import { parseEerror } from "../../helpers";
import { toggleStaffApiCall } from "../../Api/staffApi";
import notification from "../../common/Alert";

const Staff = () => {
  const staffs = useSelector((state) => state.Store.storeStaffs) || [];
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(null);
  const [query, setQuery] = useState(null);
  const [staffData, setStaffData] = useState(null);
  const [showEditStaffModal, setShowEditStaffModal] = useState(false);
  const dispatch = useDispatch();
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);

  const location = useLocation();
  const { search, pathname } = location;
  const history = useHistory();

  useEffect(() => {
    if (search === "?add-new") setShowModal(true);
  }, [search]);

  useEffect(() => {
    if (search === "?add-new" && showModal) history.replace(pathname);
  }, [history, pathname, search, showModal]);

  const preparedStaffData = staffs.map((staff) => ({
    ...staff,
    name: staff.user?.name,
    phone: staff.user?.phone,
    email: staff.user?.email,
    store: "Lekki Store",
  }));

  async function onChange(record) {
    setLoading(record.id);
    const storeId = record.store_id;
    const res = await toggleStaffApiCall(storeId, record?.id);
    setLoading();
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchStaffsAction({ page: currentPage }));
    }
    notification.notify("success", "Staff Record Updated");
    setTimeout(() => {
      setLoading(null);
    }, 2000);

    if (error) notification.notify("error", parseEerror(error));
  }

  const tableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "staff_number",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Status",
      dataIndex: "is_available",
      key: "is_available",
      render: (val, record) =>
        loading === record?.id ? (
          <CircularProgress size={25} />
        ) : (
          // eslint-disable-next-line eqeqeq
          <Switch checked={val == 1} onChange={() => onChange(record)} />
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/icons/fe_edit.png"
            alt="edit_icon"
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => selectStaffData(record)}
          />
          {/* {deleting === record.id ? (
            <CircularProgress size={20} />
          ) : (
            <img
              src="/assets/icons/delete.png"
              alt="edit_icon"
              style={{ cursor: "pointer" }}
              onClick={() => deleteStaffData(record)}
            />
          )} */}
        </div>
      ),
    },
  ];

  const selectStaffData = (record) => {
    setStaffData(record);
    setShowEditStaffModal(true);
  };

  const closeEditStaff = () => {
    setShowEditStaffModal(false);
    setStaffData(null);
  };

  useEffect(() => {
    const payload = { page: currentPage };

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchStaffsAction(payload));
      // console.info({ data });
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    if (query) payload.search = query;
    get(payload);
  }, [dispatch, query, currentPage]);

  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <StaffTable>
      <AddNewStaffModal
        visible={showModal}
        handleCancel={() => setShowModal(false)}
      />

      {showEditStaffModal && (
        <StaffEditForm
          visible={showEditStaffModal}
          handleCancel={closeEditStaff}
          staffData={staffData}
        />
      )}
      {/* <SalesReceipt
        visible={showReceiptModal}
        handleCancel={() => setshowReceiptModal(false)}
      /> */}

      <div className="staff-tools">
        <div className="search-container">
          <CustomSearch
            handleSearch={setQuery}
            search_placeholder="Search Staff"
          />
        </div>
        <button
          className="add-new-staff-btn"
          onClick={() => {
            if (showModal) {
              setShowModal(false);
            } else {
              setShowModal(true);
            }
          }}
        >
          <img src="/assets/icons/add_circle_outline.png" alt="add staff" />
          Add New Staff
        </button>
      </div>
      <CustomTable
        table_columns={tableCols}
        table_data={preparedStaffData}
        // table_pagination={{
        //   defaultPageSize: 9,
        //   simple: true,
        // }}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </StaffTable>
  );
};

export default Staff;

const StaffTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;
  .staff-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-container {
      margin-right: 1.5rem;
    }
    .add-new-staff-btn {
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        width: 10rem;
      }
      @media screen and (max-width: 380px) {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }
`;
