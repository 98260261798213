import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable";
import styled from "styled-components";
import CustomSearch from "../../components/search";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAction } from "../../store/reducers/store";
import { ButtonBase } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/system";

import { Redirect } from "react-router";
import OrderItemsModal from "../../components/modals/viewOrderItemsModal";

const CompletedOrders = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(null);
  const [data, setData] = useState(null);
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  const [viewVisible, setViewVisible] = useState(false);
  const orders = useSelector((state) => state.Store.orders) || [];
  const preparedOrders = orders.map((v) => ({
    ...v,
    phone: v.customer?.phone,
    name: v.customer?.name,
    payment: v.payment_method,
    item: v.items,
    amount: v.price,
  }));
  const orderTableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Service",
      dataIndex: "service_type",
      key: "service",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span>Completed</span>,
    },
    {
      title: "Action",
      dataIndex: "price",
      key: "price",
      render: (text, order) => (
        <div>
          <button
            className="text-light pointer btn btn-sm"
            style={{
              background: "#0088C0",
            }}
            onClick={() => handleView(order)}
          >
            View Items
          </button>
        </div>
      ),
    },
  ];
  // useEffect(() => {
  //   dispatch(fetchOrdersAction({ completed: true }));
  // }, [dispatch]);

  useEffect(() => {
    const payload = { completed: true, page: currentPage };

    if (query && query !== "") payload.search = query;

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchOrdersAction(payload));
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    get(payload);
  }, [dispatch, query, currentPage]);
  const closeView = (order) => {
    setViewVisible(false);
    setData(null);
  };

  const handleView = (order) => {
    setData(order);
    setViewVisible(true);
  };
  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role !== "regular";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <SalesTable>
      {viewVisible && (
        <OrderItemsModal
          visible={viewVisible}
          data={data}
          handleCancel={closeView}
          type={"view"}
        />
      )}
      <div className="order-tools">
        <div className="search-order">
          <CustomSearch
            handleSearch={setQuery}
            search_placeholder="Search Order"
          />
        </div>
      </div>
      <CustomTable
        table_columns={orderTableCols}
        table_data={preparedOrders}
        table_pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "end",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ArrowBackIos />
          </ButtonBase>
          <Box
            sx={{
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
          >
            {currentPage}
          </Box>
          <ButtonBase
            sx={{
              color: "blue",
              backgroundColor: "grey",
              marginLeft: "auto",
              width: "40px",
              height: "40px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
            }}
            disabled={currentPage >= lastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            <ArrowForwardIos />
          </ButtonBase>
        </div>
      </div>
    </SalesTable>
  );
};

export default CompletedOrders;

const SalesTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

  .order-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;
  }
`;
