import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { createPurchaseItemApiCall } from "../../Api/purchaseItem";
import { fetchItemsAction } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";

const AddItemForm = ({ visible, handleOk, handleCancel }) => {
  const storeId = useSelector((state) => state.Store.selectedStore);

  const [loading, setLoading] = useState();

  const dispatch = useDispatch();
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
    quantity: yup.string().required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      quantity: "",
    },
    validationSchema,

    onSubmit: (values) => {
      console.info({ values });
      createItem(values);
    },
  });
  const createItem = async (values) => {
    setLoading(true);

    delete values.store;
    const res = await createPurchaseItemApiCall(storeId, values);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchItemsAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Item Created");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Add Item</h4>
      <ItemForm onSubmit={formik.handleSubmit}>
        <label>Item Name</label>
        <input
          type="text"
          placeholder="Enter item name"
          className="new-store-input"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}

        <label>Quantity</label>
        <input
          type="text"
          placeholder="Enter quantity"
          className="new-store-input"
          name="quantity"
          onBlur={formik.handleBlur}
          value={formik.values.quantity}
          onChange={formik.handleChange}
        />
        {formik.errors.quantity && formik.touched.quantity && (
          <div style={{ color: "red" }}> {formik.errors.quantity}</div>
        )}
        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
        >
          Submit
        </LoadingButton>
      </ItemForm>
    </Modal>
  );
};

export default AddItemForm;

const ItemForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    ::placeholder {
      color: #acacac;
    }
  }
  .new-store-input,
  select {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
  }
  .submit-store-btn {
    color: #fff;
    background: var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 2.5rem;
  }
`;
