import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const staffsUrl = (storeId) => `${BASE_URL}/${storeId}/staffs`;
const individualStaffUrl = (storeId, staffId) =>
  `${staffsUrl(storeId)}/${staffId}`;

export function getStaffsApiCall(storeId, query) {
  return request({ url: staffsUrl(storeId), query });
}

export function getStaffApiCall(storeId, staffId) {
  return request({ url: individualStaffUrl(storeId, staffId) });
}

export function createStaffApiCall(storeId, payload) {
  return request({ url: staffsUrl(storeId), method: "post", payload });
}

export function updateStaffApiCall(storeId, staffId, payload) {
  return request({
    url: individualStaffUrl(storeId, staffId),
    method: "put",
    payload,
  });
}

export function deleteStaffApiCall(storeId, staffId) {
  return request({
    url: individualStaffUrl(storeId, staffId),
    method: "delete",
  });
}

export function toggleStaffApiCall(storeId, staffId) {
  return request({
    url: `${individualStaffUrl(storeId, staffId)}/availability`,
    method: "patch",
  });
}
