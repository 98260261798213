import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { fetchStoreAction } from "../../store/reducers/store";
import { LoadingButton } from "@mui/lab";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { updateCustomerApiCall } from "../../Api/customerApi";

const CustomerEditForm = ({ visible, handleOk, handleCancel, data }) => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  let validationSchema = yup.object({
    name: yup.string().required("this field is required"),
    address: yup.string().required("this field is required"),
    phone: yup.number().required("this field is required").min(11),
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      address: data?.address || "",
      email: data?.email || "",
      phone: data?.phone || "",
    },
    validationSchema,

    onSubmit: (values) => {
      updateCustomer(values);
    },
  });

  const updateCustomer = async (values) => {
    setLoading(true);
    const res = await updateCustomerApiCall(data.id, values);
    const { success, resData, error } = res;
    console.info({ resData, error });
    if (success) {
      await dispatch(fetchStoreAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Customer Updated");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Edit Customer</h4>
      <CustomerForm onSubmit={formik.handleSubmit}>
        <label>Customer Name</label>
        <input
          type="text"
          placeholder="Promise"
          name="name"
          id="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && formik.touched.name && (
          <div style={{ color: "red" }}> {formik.errors.name}</div>
        )}
        <label>Email</label>
        <input
          type="text"
          placeholder="Enter customer email address"
          className="new-store-input"
          name="email"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email && formik.touched.email && (
          <div style={{ color: "red" }}> {formik.errors.email}</div>
        )}
        <label>Phone</label>
        <input
          type="text"
          placeholder="Enter customer phone number"
          className="new-store-input"
          name="phone"
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
        {formik.errors.phone && formik.touched.phone && (
          <div style={{ color: "red" }}> {formik.errors.phone}</div>
        )}
        <label>Customer Address</label>
        <input
          type="text"
          placeholder="2 Senator road"
          name="address"
          id="address"
          onBlur={formik.handleBlur}
          value={formik.values.address}
          onChange={formik.handleChange}
        />
        {formik.errors.address && formik.touched.address && (
          <div style={{ color: "red" }}> {formik.errors.address}</div>
        )}

        <LoadingButton
          loading={loading}
          onClick={formik.submitForm}
          loadingPosition="end"
          fullWidth
          className="submit-store-btn"
          sx={{
            color: "#fff",
            background: "#0088c0",
            borderRadius: "5px",
            marginTop: "2.5rem",
            width: "100%",
          }}
        >
          Submit
        </LoadingButton>
      </CustomerForm>
    </Modal>
  );
};

export default CustomerEditForm;

const CustomerForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
    ::placeholder {
      color: #acacac;
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    .submit-sale-btn {
      color: #fff;
      background: var(--secondary-bg-color);
      border-radius: 5px;
      margin-top: 2.5rem;
      width: 60%;
    }
  }

  .sales-summary {
    margin-top: 3rem;
  }
  .sales-summary .item-info,
  .sales-summary .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sales-summary .total {
    font-weight: 600;
  }
`;
