import React, { useRef, useState } from "react";
import { Modal, Typography } from "antd";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { fetchStoreAction } from "../../store/reducers/store";
import { LoadingButton } from "@mui/lab";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import {
  creditCustomerApiCall,
  debitCustomerApiCall,
} from "../../Api/customerApi";
import { Box } from "@mui/system";

const CustomerWalletEditModal = ({ visible, handleOk, handleCancel, data }) => {
  const [loading, setLoading] = useState();
  const [transactionType, setTransactionType] = useState("credit");
  const dispatch = useDispatch();
  let validationSchema = yup.object({
    amount: yup.number().required("this field is required"),
    password: yup.string().required("this field is required"),
    ...(transactionType === "debit"
      ? { transaction_token: yup.string().required("this field is required") }
      : {}),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      password: "",
      transaction_token: "",
    },
    validationSchema,

    onSubmit: (values) => {
      updateCustomer(values);
    },
  });

  const submitRef = useRef();

  const updateCustomer = async (values) => {
    setLoading(true);

    const payload = { ...values };
    if (transactionType === "credit") delete payload.transaction_token;

    const res =
      transactionType === "credit"
        ? await creditCustomerApiCall(data.id, payload)
        : await debitCustomerApiCall(data.id, payload);
    const { success, resData, error } = res;
    console.info({ resData, error });
    if (success) {
      if (resData.status === "success") await dispatch(fetchStoreAction());
      handleCancel();
      formik.resetForm();
      notification.notify(resData.status, resData.message);
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  function submitForm(val) {
    submitRef.current = val;
    formik.handleSubmit();
  }

  if (!visible) return false;

  return (
    <Modal
      title={null}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <img
        src="/assets/icons/ic_round-cancel.png"
        alt="close"
        style={{
          position: "absolute",
          top: -8,
          right: -8,
          cursor: "pointer",
          width: "30px",
        }}
        onClick={handleCancel}
      />
      <h4 style={{ textAlign: "center" }}>Customer Wallet</h4>

      <CustomerForm onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Wallet Ballance
          </Typography>
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            ₦{data?.balance}
          </Typography>
        </Box>
        <label>Amount</label>
        <input
          type="text"
          placeholder="Enter Amount"
          name="amount"
          id="amount"
          onBlur={formik.handleBlur}
          value={formik.values.amount}
          onChange={formik.handleChange}
        />
        {formik.errors.amount && formik.touched.amount && (
          <div style={{ color: "red" }}> {formik.errors.amount}</div>
        )}
        <label>Password</label>
        <input
          type="text"
          placeholder="Enter Password"
          name="password"
          id="password"
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        {formik.errors.password && formik.touched.password && (
          <div style={{ color: "red" }}> {formik.errors.password}</div>
        )}
        <label>Type</label>
        <select
          value={transactionType}
          onChange={(e) => setTransactionType(e.target.value)}
          required
        >
          <option value="credit">Credit</option>
          <option value="debit">Debit</option>
        </select>
        {transactionType === "debit" && (
          <>
            <label>Customer Token</label>
            <br />
            <input
              type="text"
              placeholder="Enter token"
              name="transaction_token"
              id="transaction_token"
              onBlur={formik.handleBlur}
              value={formik.values.transaction_token}
              onChange={formik.handleChange}
              // autocomplete="off"
            />
            {formik.errors.transaction_token &&
              formik.touched.transaction_token && (
                <div style={{ color: "red" }}>
                  {" "}
                  {formik.errors.transaction_token}
                </div>
              )}
            <br />
          </>
        )}

        <div style={{ display: "flex" }}>
          <LoadingButton
            loading={loading}
            onClick={() => submitForm("credit")}
            loadingPosition="end"
            fullWidth
            className="submit-store-btn"
            sx={{
              color: "#fff",
              background: "#0088c0",
              borderRadius: "5px",
              marginTop: "2.5rem",
              width: "100%",
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </CustomerForm>
    </Modal>
  );
};

export default CustomerWalletEditModal;

const CustomerForm = styled.form`
  label {
    margin-top: 0.5rem;
  }
  input,
  select {
    outline: none;
    border: none;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 0.5rem;
    ::placeholder {
      color: #acacac;
    }
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    .submit-sale-btn {
      color: #fff;
      background: var(--secondary-bg-color);
      border-radius: 5px;
      margin-top: 2.5rem;
      width: 60%;
    }
  }

  .sales-summary {
    margin-top: 3rem;
  }
  .sales-summary .item-info,
  .sales-summary .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sales-summary .total {
    font-weight: 600;
  }
`;
