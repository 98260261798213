import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const salesUrl = (storeId) => `${BASE_URL}/${storeId}/sales`;
const individualSaleUrl = (storeId, saleId) => `${salesUrl(storeId)}/${saleId}`;

export function getSalesApiCall(storeId, query) {
  return request({ url: salesUrl(storeId), query });
}

export function getSaleApiCall(storeId, saleId) {
  return request({ url: individualSaleUrl(storeId, saleId) });
}

export function createSaleApiCall(storeId, payload) {
  return request({ url: salesUrl(storeId), method: "post", payload });
}

export function updateSaleApiCall(storeId, saleId, payload) {
  return request({
    url: individualSaleUrl(storeId, saleId),
    method: "put",
    payload,
  });
}

export function deleteSaleApiCall(storeId, saleId) {
  return request({
    url: individualSaleUrl(storeId, saleId),
    method: "delete",
  });
}
