import React, { useState } from "react";
import "./index.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { ReactComponent as AuthImg } from "./images/auth-img.svg";
import { resetPasswordApiCall } from "../../Api/AuthApi";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { useHistory, useLocation } from "react-router";
import { LoadingButton } from "@mui/lab";

const ResetPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const token = location.search?.replace("?token=", "");
  const [loading, setLoading] = useState(false);

  const resetPassword = async (values) => {
    setLoading(true);
    const { password } = values;

    const res = await resetPasswordApiCall({ password, token });
    const { success, error } = res;
    setLoading(false);

    if (success) {
      notification.notify("success", "Password Changed");
      history.push("/");
    }
    if (error) notification.notify("error", parseEerror(error));
  };

  // console.info({ token });

  let validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "password should be at least 8 characters long")
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // )
      .required("this field is required"),
    passwordConfirmation: yup
      .string()
      .required("this field is required")
      .oneOf([yup.ref("password")], "Your passwords do not match."),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema,

    onSubmit: (values) => {
      // console.info(values);
      resetPassword(values);
    },
  });

  return (
    <div className="auth-container">
      <div className="auth-container-inner">
        <div className="auth-left">
          <h2>Reset your Password</h2>
          <form onSubmit={formik.handleSubmit}>
            <label>New Password</label>
            <br />
            <input
              type="password"
              placeholder="Enter your password"
              name="password"
              id="password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.errors.password && formik.touched.password && (
              <div style={{ color: "red" }}> {formik.errors.password}</div>
            )}
            <br />
            <label>Confirm New Password</label>
            <br />
            <input
              type="password"
              placeholder="Enter your password Confirmation"
              name="passwordConfirmation"
              id="passwordConfirmation"
              onBlur={formik.handleBlur}
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
            />
            {formik.errors.password && formik.touched.passwordConfirmation && (
              <div style={{ color: "red" }}>
                {formik.errors.passwordConfirmation}
              </div>
            )}
            <br />
            <LoadingButton
              loadingPosition="end"
              loading={loading}
              type="submit"
            >
              Save New Password
            </LoadingButton>
          </form>
        </div>
        <div className="auth-right">
          <AuthImg style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
