import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const expensesUrl = (storeId) => `${BASE_URL}/${storeId}/expenses`;

export function getExpensesApiCall(storeId, query) {
  return request({ url: expensesUrl(storeId), query });
}

export function createExpensesApiCall(storeId, payload) {
  return request({ url: expensesUrl(storeId), method: "post", payload });
}
