import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashBoard from "../pages/dashboard";
import NotFound from "../components/notfound";
import Customer from "../pages/customer";
import AyoboStore from "../pages/ayoboStore";
import AddNewCustomer from "../pages/addCustomer";
import AddNewSales from "../pages/addSales";
import AllSales from "../pages/sales/all";
import PendingSales from "../pages/sales/pending";
import SalesReport from "../pages/sales/report";
import AddNewStaff from "../pages/addStaff";
import Staff from "../pages/staff";
import Settings from "../pages/settings";
import SalesService from "../pages/settings/salesService";
import SalesTax from "../pages/settings/salesTax";
import NewOrders from "../pages/orders/neworders";
import CompletedOrders from "../pages/orders/completed";
import Attendance from "../pages/attendance/attendance";
import { useSelector } from "react-redux";
import Payout from "../pages/payments/payout";
import Payroll from "../pages/payments/payroll";
import Messaging from "../pages/messaging";
import Expenses from "../pages/expenses";
import Inventory from "../pages/inventory";
import ServiceReport from "../pages/serviceReport";
import DraftSales from "../pages/sales/drafts";
import MessageHistory from "../pages/messaging/history";
import InventoryItemDetails from "../pages/inventory/inventoryItemDetails";

const DashboardRoutes = () => {
  const baseUrl = "/dashboard";
  const loggedIn = useSelector((state) => state.User.loggedIn);

  if (!loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route path={baseUrl} exact component={DashBoard} />
      <Route exact path={`${baseUrl}/store`} component={AyoboStore} />
      <Route exact path={`${baseUrl}/staff/add-new`} component={AddNewStaff} />
      <Route exact path={`${baseUrl}/sales/add-new`} component={AddNewSales} />
      <Route exact path={`${baseUrl}/sales`} component={AllSales} />
      <Route exact path={`${baseUrl}/sales/pending`} component={PendingSales} />
      <Route exact path={`${baseUrl}/sales/drafts`} component={DraftSales} />
      <Route exact path={`${baseUrl}/sales/report`} component={SalesReport} />
      <Route exact path={`${baseUrl}/order/neworders`} component={NewOrders} />
      <Route
        exact
        path={`${baseUrl}/order/completed`}
        component={CompletedOrders}
      />
      <Route exact path={`${baseUrl}/customer`} component={Customer} />
      <Route exact path={`${baseUrl}/attendance`} component={Attendance} />
      <Route
        exact
        path={`${baseUrl}/customer/add-new`}
        component={AddNewCustomer}
      />
      <Route exact path={`${baseUrl}/staff`} component={Staff} />
      <Route exact path={`${baseUrl}/settings*`} component={Settings} />
      <Route
        exact
        path={`${baseUrl}/settings/sales/service`}
        component={SalesService}
      />
      <Route
        exact
        path={`${baseUrl}/settings/sales/taxes`}
        component={SalesTax}
      />

      <Redirect
        exact
        from={`${baseUrl}/settings`}
        to={`${baseUrl}/settings/sales/service`}
      />
      <Route exact path={`${baseUrl}/payment/payout`} component={Payout} />
      <Route exact path={`${baseUrl}/payment/payroll`} component={Payroll} />
      <Route exact path={`${baseUrl}/messaging/new`} component={Messaging} />
      <Route
        exact
        path={`${baseUrl}/messaging/history`}
        component={MessageHistory}
      />
      <Route exact path={`${baseUrl}/expenses`} component={Expenses} />
      <Route
        exact
        path={`${baseUrl}/inventory/:type/item-history/:name/:id`}
        component={InventoryItemDetails}
      />
      <Route exact path={`${baseUrl}/inventory*`} component={Inventory} />

      <Route
        exact
        path={`${baseUrl}/inventory/sales`}
        component={SalesService}
      />

      <Route
        exact
        path={`${baseUrl}/inventory/purchase`}
        component={SalesService}
      />

      <Route
        exact
        path={`${baseUrl}/service-report/:category`}
        component={ServiceReport}
      />
      <Route
        exact
        path={`${baseUrl}/service-report*`}
        component={ServiceReport}
      />
      <Route
        exact
        path={`${baseUrl}/inventory/sales`}
        component={SalesService}
      />
      <Route
        exact
        path={`${baseUrl}/inventory/purchase`}
        component={SalesService}
      />

      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardRoutes;
