import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const payrollUrl = (storeId) => `${BASE_URL}/${storeId}/payroll`;
const individualStaffUrl = (storeId, staffId) =>
  `${payrollUrl(storeId)}/${staffId}`;

export function getPayrollApiCall(storeId, query) {
  return request({ url: payrollUrl(storeId), query });
}

export function payStaffApiCall(storeId, staffId, payload) {
  return request({
    url: individualStaffUrl(storeId, staffId),
    method: "post",
    payload,
  });
}

export function payStaffsApiCall(storeId, payload) {
  return request({
    url: individualStaffUrl(storeId),
    method: "post",
    payload,
  });
}
