import React, { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import { changePasswordApiCall } from "../../Api/AuthApi";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
import { LoadingButton } from "@mui/lab";

const Security = () => {
  // changePasswordApiCall;
  const [loading, setLoading] = useState(false);
  const resetPassword = async (values) => {
    setLoading(true);
    const res = await changePasswordApiCall(values);
    const { success, error } = res;
    setLoading(false);

    if (success) {
      notification.notify("success", "Password updated successfully.");
    }
    if (error) notification.notify("error", parseEerror(error));
    formik.resetForm();
  };

  let validationSchema = yup.object({
    current_password: yup
      .string()
      .min(8, "password should be at least 8 characters long")
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // )
      .required("this field is required"),
    password: yup
      .string()
      .min(8, "password should be at least 8 characters long")
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // )
      .required("this field is required"),
    password_confirmation: yup
      .string()
      .required("this field is required")
      .oneOf([yup.ref("password")], "Your passwords do not match."),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
      current_password: "",
    },
    validationSchema,

    onSubmit: (values) => {
      console.info(values);
      resetPassword(values);
    },
  });

  return (
    <SecurityWrapper>
      <h3>Change Password</h3>
      <form onSubmit={formik.handleSubmit}>
        <input
          type="password"
          placeholder="Enter old password"
          name="current_password"
          id="current_password"
          onBlur={formik.handleBlur}
          value={formik.values.current_password}
          onChange={formik.handleChange}
        />
        {formik.errors.current_password && formik.touched.current_password && (
          <div style={{ color: "red" }}> {formik.errors.current_password}</div>
        )}
        <br />
        <input
          type="password"
          placeholder="Enter new password"
          name="password"
          id="password"
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        {formik.errors.password && formik.touched.password && (
          <div style={{ color: "red" }}> {formik.errors.password}</div>
        )}

        <br />
        <input
          type="password"
          placeholder="Confirm new password"
          name="password_confirmation"
          id="password_confirmation"
          onBlur={formik.handleBlur}
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
        />
        {formik.errors.password_confirmation &&
          formik.touched.password_confirmation && (
            <div style={{ color: "red" }}>
              {" "}
              {formik.errors.password_confirmation}
            </div>
          )}
        <br />
        <LoadingButton loading={loading} onClick={formik.submitForm}>
          Save
        </LoadingButton>
      </form>
    </SecurityWrapper>
  );
};

export default Security;

const SecurityWrapper = styled.div`
  width: 50%;
  margin: auto;

  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 900px) {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
  }
  h3 {
    text-align: center;
    margin-bottom: 2rem;
  }
  input {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 1rem;
    outline: none;
    background: transparent;
    width: 100%;
    margin: 1rem 0rem;
  }
  button {
    background: var(--secondary-bg-color);
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    padding: 1rem;
    outline: none;
    border: none;
    width: 100%;
    margin: 1rem 0rem;
    color: #fff;
  }
`;
