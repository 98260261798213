import React from "react";
import "./index.css";
import { Modal } from "antd";
import Logo from "../../assets/images/logo.png";
import { table, getBorderCharacters } from "table";
// import {
//   Br,
//   Cut,
//   Line,
//   Printer,
//   Text,
//   Row,
//   render,
//   Image,
// } from "react-thermal-printer";
import { EscPos } from "@tillpos/xml-escpos-helper";
import { img2 } from "../../assets/constant";

async function connectAndPrint(data) {
  console.info({ data });
  try {
    const device = await navigator.usb.requestDevice({ filters: [] });

    if (device === null) {
      return;
    }

    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);
    await device.transferOut(1, data);
  } catch (error) {
    console.log(error);
  }
}

// ....................................

function printImage(salesData) {
  const template = `<?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">     
      <image mode="DOUBLE_WIDTH_HEIGHT" density="d24">
        {{base64PngImage}}
      </image>
    <line-feed />

       <text-line>WASHRYTE LAUNDRY </text-line>
    </align>    

    <align mode="center">     
     <text>2ND FLOOR, LENNOX MALL, 3 ADMIRALTY WAY, \n </text-line>
     <text>LEKKI PHAE 1, LEKKI, LAGOS.\n</text>
    </align>    
    <align mode="center">
      <text-line>0805 930 3818</text-line>
      <text-line> customerservice@washryte.com.ng </text-line> 
    </align>
    <line-feed />

    <align mode="center">
     <text >Thank you for choosing Washryte.\n</text> 
     <text >For record purpose, a breakdown of service(s) \n</text> 
     <text >offered. Reach out to us for any complaints. \n  </text> 
    </align>
    
    <line-feed />
    <line-feed />



    <text-line>Customer:          {{customer}}</text-line>
    <text-line>Phone:             {{customerPhone}}</text-line>
    <text-line>Address:           {{customerAddress}}</text-line>
    <text-line>Attendant:         {{attendant}}</text-line>
    <text-line>Date:              {{date}}</text-line>
    <text-line>Current Credit:            {{}}</text-line>
    <text-line>Current Debit:            {{}}</text-line>
    <text-line>Status:            {{status}}</text-line>
    
    



    <line-feed />

    <text-line>{{servicesoutput}}</text-line>

    <line-feed />


    <text-line>{{discountsoutput}}</text-line>
    <text-line>---------------------------------------------</text-line>
    <text-line>    </text-line>

  <align mode="left">
        <text-line >Items Count                        {{itemsCount}}</text-line>
    </align>

      <align mode="left">
      <bold>
        <text-line >Subtotal                           {{subtotal}}</text-line>
      </bold>
    </align>

    <align mode="left">
        <text-line >VAT(7.5%)                          {{vat}}</text-line>
    </align>
     <align mode="left">
      <bold>
        <text-line >Total                              {{total}}</text-line>
      </bold>
    </align>


    <line-feed />
    <align mode="center">
      <text-line >Thank you for your patronage.</text-line>
    </align>
    <line-feed />


     

    <align mode="center">     
         <text-line> powered by waizytech engineering</text-line>
    </align>   

     <line-feed />
    <line-feed />

    <paper-cut />

  </document>`;

  const services = salesData.sale_services?.map((item, index) => [
    item.service_name,
    "N" + item.service_unit_price,
    "X" + item.service_units,
    "N" + item.total,
  ]);
  services.unshift(["---------------", "----------", "-----", "----------"]);
  services.unshift(["Item", "price", "Qty", "Value"]);
  //     <Row left="Item:" center="Qty" right="Value" />

  const discounts = salesData.sale_discounts?.map((item, index) => [
    item.discount_name,
    "",
    "",
    item.discount_percentage_off + "%",
  ]);

  discounts.push([""]);

  const options = {
    border: getBorderCharacters(`void`), // it hides table borders
    columns: {
      // If you want to set same width for all cells,just remove this property
      0: {
        width: 15,
      },
      1: {
        width: 10,
      },
      2: {
        width: 5,
      },
    },
  };
  const servicesoutput = table(services, options);
  const discountsoutput = table(discounts, options);

  const vat = (7.5 / 100) * salesData?.price;

  const itemsCount = salesData.sale_services.reduce(
    (p, c) => p + parseInt(c.service_units),
    0
  );

  const input = {
    base64PngImage: img2,
    servicesoutput,
    discountsoutput,
    customer: salesData?.customer,
    attendant: salesData?.attendant,
    date: `${salesData?.created_at.substring(
      0,
      10
    )}    ${salesData?.created_at.substring(10, 16)}`,
    status: salesData?.payment_method,
    total: salesData?.price,
    itemsCount,
    vat,
    subtotal: salesData?.price - vat,
    customerPhone: salesData.customerPhone,
    customerAddress: salesData.customerAddress,
  };

  const buffer = EscPos.getBufferFromTemplate(template, input);

  console.info({ buffer, input, services, discounts });

  return buffer;
}
// ......................................

const printFn = async (salesData) => {
  try {
    connectAndPrint(printImage(salesData));
  } catch (error) {
    console.info({ error });
  }
};

const SalesReceipt = ({ visible, handleCancel, salesData }) => {
  const Btns = ({ handleCancel }) => {
    return (
      <div className="receipt-tools" id="section-to-remove">
        <button
          className="receipt-print-btn"
          onClick={() => printFn(salesData)}
        >
          Print
        </button>
        <button onClick={handleCancel} className="receipt-cancel-btn">
          Cancel
        </button>
      </div>
    );
  };

  if (!visible) return null;

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <div className="receipt-container" style={{ fontWeight: "bold" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <img src={Logo} alt="logo" />
        </div>
        <h3>Thanks for your Patronage</h3>
        <p className="recept-message">
          This is to thank you for your patronage and for record purpose, a
          breakdown of service(s) offered. Reach out to us on{" "}
          <a href="tel: 0813443232">0813443232</a> for any complaints.
        </p>

        <ul style={{ marginTop: "15px" }}>
          <li>
            <span> Customer: </span>
            {salesData?.customer}
          </li>
          <li>
            <span> Attendant: </span>
            {salesData?.attendant}
          </li>
          <li>
            <span> Date: </span>
            {salesData?.created_at.substring(0, 11)}
          </li>
          <li>
            <span>Status: </span>
            {salesData.payment_method}
          </li>
        </ul>

        {/* <div className="item-lists">
          <p className="left">{salesData?.items}</p>
          <p>N{salesData?.price}</p>
        </div> */}

        <div className="sales-summary" style={{ marginTop: "15px" }}>
          <div className="item-info">
            <p className="item-name">
              Item
              <p className="">Qty</p>
            </p>

            <p>Value</p>
          </div>
          {salesData.sale_services?.map((item, index) => {
            return (
              <div className="item-info" key={index}>
                <p className="item-name">
                  {item.service_name}
                  <p className="">X {item.service_units}</p>
                </p>

                <p>N{item.service_unit_price}</p>
              </div>
            );
          })}
          {salesData.sale_discounts?.map((item, index) => {
            return (
              <div className="item-info" key={index}>
                <p className="item-name">{item.discount_name}</p>
                <p>{item.discount_percentage_off}%</p>
              </div>
            );
          })}
          <div className="item-info">
            <p className="item-name">VAT</p>
            <p>7.5%</p>
          </div>
          <div className="total">
            <p>{`Total`.concat("-")} </p>
            <p>{salesData?.price}</p>
          </div>
        </div>

        <Btns handleCancel={handleCancel} />
        <div
          style={{
            fontSize: "10px",
            marginTop: "20px",
            marginBottom: "-50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          powered by waizytech engineering
        </div>
      </div>
    </Modal>
  );
};

export default SalesReceipt;
