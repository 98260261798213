/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Menu } from "antd";
// import Security from "./security";
// import ServiceReportTax from "./serviceReportTax";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import { useLocation } from "react-router";
// import ServiceReportService from "./serviceReportService";
// import Attendance from "./attendance";
import { ButtonBase, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/system";
import CustomSearch from "../../components/search";
import CustomSearchDate from "../../components/search/dateSearch";
import CustomTable from "../../components/customTable";
import { ExportToExcel } from "../../components/exportFile";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { setLoading } from "../../store/reducers/app";
import { getServiceReportApiCall } from "../../Api/service";
import { useParams } from "react-router-dom";

// import Salary from "./salary";

const baseUrl = "/dashboard/service-report";

const ServiceReport = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([""]);
  const [selectedCategory, setSelectedCAtegory] = useState(1);
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateT0, setDateT0] = useState(null);

  var [lastPage, setLastPage] = useState(1);
  const params = useParams();

  const { category } = params;

  const storeId = useSelector((state) => state.Store.selectedStore);

  var [currentPage, setCurrentPage] = useState(1);
  const user = useSelector((state) => state.User.user) || {};
  const serviceCategories =
    useSelector((state) => state.Store.serviceCategories) || [];

  const catRef = {};
  serviceCategories.forEach((v) => {
    catRef[v.name.replace(" ", "-").toLowerCase()] = v.id;
  });
  const { role } = user;
  const hasPrivilage = role === "sub_admin" || role === "super_admin";
  const onClickMenu = (item) => {
    setSelected([item]);
    setSelectedCAtegory(1);
  };

  const selectServiceCategory = (item) => {
    setSelected([item]);
    setSelectedCAtegory(item);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Service  Report";
    const headers = [["ID", "	Name", "Net", "Tax", "Total"]];

    const data = items.map((elt) => [
      elt.id,
      elt.name,
      elt.net,
      elt.tax,
      elt.total,
    ]);
    data.push([]);
    // data.push(["total", "", "", "", "", "", getTotal()]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };
  const dispatch = useDispatch();
  const tableCols = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },

    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];
  const get = async (payload, categoryId) => {
    dispatch(setLoading(true));

    const { success, resData } = await getServiceReportApiCall(
      storeId,
      categoryId,
      payload
    );
    dispatch(setLoading(false));

    if (resData && success) {
      console.info({ resData });
      setItems(
        resData?.map((v) => ({
          ...v,
          role: v.user?.role,
          user: v.user?.name,
        })) || []
      );
      var lastPageNo = resData?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    }
  };
  // useEffect(() => {
  //   const payload = { page: currentPage };
  //   if (query) payload.search = query;
  //   if (category) get(payload, catRef[category]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [query, currentPage, category]);

  useEffect(() => {
    if (!category) return;
    const payload = { page: currentPage };
    if (dateT0 && dateFrom)
      get(
        {
          report: true,
          date_range: true,
          start_at: dateFrom,
          end_at: dateT0,
          page: currentPage,
        },
        catRef[category]
      );
    else if (dateT0 || dateFrom) return;
    else get(payload, catRef[category]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dateFrom, dateT0, currentPage]);

  return (
    <ServiceReportContainer>
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={selected}
        className="menu-left"
      >
        {serviceCategories.map(({ name }, index) => {
          return (
            <Menu.Item key={name} onClick={() => onClickMenu(name)}>
              <Link to={`${baseUrl}/${name.replace(" ", "-").toLowerCase()}`}>
                {name}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>

      <div className="menu-right" style={{ height: "87vh", padding: 0 }}>
        {category && (
          <ServiceReportTable>
            {/* {showEditServiceReportModal && (
            <ServiceReportEditForm
              visible={showEditServiceReportModal}
              handleCancel={closeEditServiceReport}
              serviceReportData={serviceReportData}
            />
          )} */}
            {/* {showReceiptModal && (
            <ServiceReportReceipt
              visible={showReceiptModal}
              handleCancel={closePrintServiceReport}
              serviceReportData={serviceReportData}
            />
          )} */}
            <div className="service-report-tools">
              {/* <FilterModal
              visible={showFilterModal}
              handleClose={handleClose}
              handleCancel={() => setShowFilterModal(false)}
            /> */}
              <div className="service-report-tools-inner">
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="date-from"
                >
                  from
                  <CustomSearchDate
                    handleSearch={setDateFrom}
                    search_placeholder="Sales Date"
                  />
                </div>

                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="date-to"
                >
                  to
                  <CustomSearchDate
                    handleSearch={setDateT0}
                    search_placeholder="Sales Date"
                  />
                </div>

                {/* <button
                className="add-filter-btn"
                // onClick={() => setShowFilterModal(true)}
              >
                <img
                  src="/assets/icons/add_circle_outline.png"
                  alt="add store"
                />
                Add Filter
              </button> */}
              </div>
              {hasPrivilage && (
                <ExportToExcel
                  apiData={[
                    ...items,
                    // { id: "total", amount: getTotal() },
                  ]}
                  fileName="serviceReport report"
                  text_name="excel"
                />
              )}
              {hasPrivilage && (
                <div
                  className="export-report-tool"
                  style={{
                    cursor: "pointer",
                    color: "#0088c0",
                    fontWeight: "700",
                  }}
                  onClick={exportPDF}
                >
                  <img
                    src="/assets/icons/ant-design_cloud-download-outlined.png"
                    alt="download"
                  />
                  Export pdf
                </div>
              )}
            </div>
            <CustomTable
              table_columns={tableCols}
              table_data={items}
              // table_pagination={{
              //   defaultPageSize: 9,
              //   // simple: true,
              // }}
              table_pagination={false}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
              }}
            >
              <div
                style={{ display: "flex", justifyContent: "end", width: 120 }}
              >
                <ButtonBase
                  sx={{
                    color: "blue",
                    backgroundColor: "grey",
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                  // disabled={currentPage <= 1}
                  // onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <ArrowBackIos />
                </ButtonBase>
                <Box
                  sx={{
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                >
                  {currentPage}
                </Box>
                <ButtonBase
                  sx={{
                    color: "blue",
                    backgroundColor: "grey",
                    marginLeft: "auto",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                  }}
                  // disabled={currentPage >= lastPage}
                  // onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <ArrowForwardIos />
                </ButtonBase>
              </div>
            </div>
          </ServiceReportTable>
        )}
        {/* {location.pathname === `${baseUrl}/security` ? (
          <Security />
        ) : location.pathname === `${baseUrl}/serviceReport/taxes` && hasPrivilage ? (
          <ServiceReportTax />
        ) : location.pathname === `${baseUrl}/serviceReport/services` &&
          hasPrivilage ? (
          <ServiceReportService selectedCategory={selectedCategory} />
        ) : location.pathname === `${baseUrl}/serviceReport/attendance` &&
          hasPrivilage ? (
          <Attendance />
        ) : (
          <Security />
        )} */}
      </div>
    </ServiceReportContainer>
  );
};

export default ServiceReport;

const ServiceReportContainer = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .menu-left {
    background: #ffffff;
    border-radius: 10px;
    flex: 0.3;
    margin-right: 1rem;
    color: #000;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 1rem;
      display: flex;
    }
  }
  .menu-right {
    flex: 0.7;
    background: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    height: 100vh;
  }
`;

const ServiceReportTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;

 

  .service-report-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;
justify-content: space-between;
     .service-report-tools-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .date-from,
      .date-to {
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .date-to {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .add-filter-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.4rem 0rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: 12rem;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        white-space: nowrap;
        background-color: var(--secondary-bg-color);
        color: #fff;
        border: none;

        img {
          margin-right: 0.5rem;
        }
        @media screen and (max-width: 480px) {
          .add-filter-btn {
            width: 10rem;
          }
        }
        @media screen and (max-width: 380px) {
          .add-filter-btn {
            white-space: pre-wrap;
            width: auto;
            padding: 0.2rem 0.4rem;
          }
        }
      }
    }
  }

    .search-serviceReport,
    .service-report-date {
      margin-right: 1rem;
    }
    .service-report-date {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .add-sale-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;

      img {
        margin-right: 0.5rem;
      }
      @media screen and (max-width: 480px) {
        .add-sale-btn {
          width: 10rem;
        }
      }
      @media screen and (max-width: 380px) {
        .add-sale-btn {
          white-space: pre-wrap;
          width: auto;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }
`;
