import React from "react";
import "./index.css";

const NotFound = () => {
  return (
    <div className="not-found">
      <p>Page Not Found</p>
    </div>
  );
};

export default NotFound;
