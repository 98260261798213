/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Box, Checkbox, Grid } from "@mui/material";
import CustomSearch from "../../components/search";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomersAction,
  fetchSalesAction,
} from "../../store/reducers/store";
import { sendMessageApiCall } from "../../Api/storApi";
import { parseEerror } from "../../helpers";
import notification from "../../common/Alert";
import { Redirect } from "react-router";

// import Salary from "./salary";

const Messaging = () => {
  const sales = useSelector((state) => state.Store.storeSales) || [];
  const storeId = useSelector((state) => state.Store.selectedStore);

  const [search, setSearch] = useState("");
  const customers = useSelector((state) => state.Store.customers);
  const preparedSales = sales
    .filter((v) => v.payment_method === "pending")
    ?.filter((v) =>
      search === ""
        ? true
        : v.customer?.name?.toLowerCase().includes(search.toLowerCase())
    );
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [selected1, setSelected1] = useState({});
  const [selected2, setSelected2] = useState({});
  const [loading, setLoading] = useState(true);
  const [allCustomers, setAllCustomers] = useState();
  const [message, setMessage] = useState("");

  const [currentTab, setCurrentTab] = useState("pending");
  const dispatch = useDispatch();
  const defaultStyles = {
    border: "1px solid #0088c0",
    cursor: "pointer",
    borderRadius: "3px",
    width: "50%",
    padding: "10px",
    textAlign: "center",
  };

  useEffect(() => {
    if (customers?.length > 0) {
      if (currentPage === 1) setDisplayedCustomers(customers);
      else setDisplayedCustomers((v) => [...v, ...customers]);
    }
  }, [customers]);

  useEffect(() => {
    dispatch(fetchSalesAction({ pending: true }));
    dispatch(fetchCustomersAction());
  }, [dispatch]);

  const handleChange1 = (id, val, name) => {
    console.info({ name, val });
    const temp = { ...selected1 };
    if (val) return setSelected1({ ...temp, [id]: name });
    delete temp[id];
    setSelected1({ ...temp });
    console.info({ temp });
  };

  const handleChange2 = (id, val, name) => {
    console.info({ name, val });
    const temp = { ...selected2 };
    if (val) return setSelected2({ ...temp, [id]: name });
    delete temp[id];
    setSelected2({ ...temp });
    console.info({ temp });
  };
  const selectAll1 = (val) => {
    const temp = {};
    preparedSales.forEach((v) => (temp[v.customer_id] = v.customer.name));
    setSelected1(val ? { ...temp } : {});
    // setAllSelected({ ...allSelected, pending: preparedSales.length });
  };

  const selectAll2 = (val) => {
    const temp = {};
    displayedCustomers.forEach((v) => (temp[v.id] = v.name));
    setSelected2(val ? { ...temp } : {});
  };

  const sendMessage = async (type) => {
    setLoading(type);
    // general;
    const payload = {
      message,
      type,
    };
    if (allCustomers) payload["general"] = true;
    else payload["recipients"] = Object.keys({ ...selected1, ...selected2 });

    console.info({ payload });
    const res = await sendMessageApiCall(storeId, payload);
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      notification.notify(
        "success",
        type === "email" ? "Email Sent" : "Message Sent"
      );
      // setMessage("");
      setSelected1({});
      setSelected2({});
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);
  useEffect(() => {
    const payload = { page: currentPage };

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchCustomersAction(payload));
      // //console.info({ data });
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    if (currentTab !== "customer") return;
    if (search) payload.search = search;
    get(payload);
  }, [dispatch, search, currentPage]);

  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role !== "regular";

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    console.info({ bottom });
    if (bottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <MessagingContainer>
      <div className="menu-left" style={{ height: "87vh", padding: 0 }}>
        <div className="topLeft">
          <div
            style={{ display: "flex", justifyContent: "center", width: "80%" }}
          >
            <Box sx={{ display: "flex", width: "100%", marginLeft: "6px" }}>
              <Box
                variant={currentTab === "pending" ? "contained" : "outlined"}
                onClick={() => setCurrentTab("pending")}
                sx={
                  currentTab === "pending"
                    ? {
                        backgroundColor: "#0088c0",
                        color: "white",
                        ...defaultStyles,
                      }
                    : {
                        backgroundColor: "transparent",
                        color: "#0088c0",
                        ...defaultStyles,
                      }
                }
              >
                Pending Sales
              </Box>{" "}
              <Box
                sx={
                  currentTab === "customer"
                    ? {
                        backgroundColor: "#0088c0",
                        color: "white",
                        marginLeft: "-12px",
                        ...defaultStyles,
                      }
                    : {
                        backgroundColor: "transparent",
                        marginLeft: "-12px",
                        color: "#0088c0",
                        ...defaultStyles,
                      }
                }
                variant={currentTab === "customer" ? "contained" : "outlined"}
                onClick={() => setCurrentTab("customer")}
              >
                Customers
              </Box>{" "}
            </Box>
          </div>
          <div style={{ width: "76%" }}>
            <CustomSearch
              search_placeholder="Search"
              handleSearch={setSearch}
            />
          </div>
        </div>
        <div className="bottomLeft" onScroll={handleScroll}>
          {currentTab === "customer" && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 0.1 }}>
                <Checkbox
                  key={currentTab}
                  checked={allCustomers}
                  onChange={(e) => setAllCustomers(!allCustomers)}
                />
              </div>
              <div style={{ flex: 0.6 }}>All Customers</div>
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 0.1 }}>
              <Checkbox
                key={currentTab}
                checked={
                  currentTab === "pending"
                    ? Object.keys(selected1).length === preparedSales.length
                    : Object.keys(selected2).length ===
                      displayedCustomers.length
                }
                onChange={(e) =>
                  currentTab === "pending"
                    ? selectAll1(e.target.checked)
                    : selectAll2(e.target.checked)
                }
              />
            </div>
            <div style={{ flex: 0.6 }}>Mark all</div>
            <div style={{ flex: 0.3 }}>
              {Object.keys(currentTab === "pending" ? selected1 : selected2)
                ?.length || 0}{" "}
              selected
            </div>
          </div>

          {currentTab === "pending" &&
            preparedSales.map((v) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 20,
                  paddingTop: 20,
                  borderBottom: "1px solid #D8D8D8",
                }}
              >
                <div style={{ flex: 0.1 }}>
                  <Checkbox
                    checked={selected1[v.customer_id] ?? false}
                    onChange={(e) =>
                      handleChange1(
                        v.customer_id,
                        e.target.checked,
                        v.customer.name
                      )
                    }
                  />
                </div>
                <div style={{ flex: 0.6 }}>
                  <div>{v.customer.name}</div>
                  <div style={{ fontWeight: "bold" }}>N{v.price}</div>
                </div>
                <div style={{ flex: 0.3 }}>PM6789</div>
              </div>
            ))}

          {currentTab === "customer" &&
            displayedCustomers.map((v) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 20,
                  paddingTop: 20,
                  borderBottom: "1px solid #D8D8D8",
                }}
              >
                <div style={{ flex: 0.1 }}>
                  <Checkbox
                    checked={selected2[v.id] ?? false}
                    onChange={(e) =>
                      handleChange2(v.id, e.target.checked, v.name)
                    }
                  />
                </div>
                <div style={{ flex: 0.6 }}>
                  <div>{v.name}</div>
                  {/* <div style={{ fontWeight: "bold" }}>N{v.price}</div> */}
                </div>
                <div style={{ flex: 0.3 }}>PM6789</div>
              </div>
            ))}
        </div>
      </div>
      <div className="menu-right" style={{ height: "87vh", padding: 20 }}>
        <div
          style={{
            lineHeight: "1.5em",
            height: "5em",
            overflowY: "auto",
            fontSize: 13,
          }}
        >
          {Object.values({ ...selected1, ...selected2 })?.join(",")}.
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          Compose Message
        </div>

        <textarea
          id="w3review"
          name="w3review"
          rows="15"
          placeholder="Type your message"
          defaultValue={message}
          onBlur={(e) => setMessage(e.target.value)}
          style={{
            border: "1px solid #c4c4c4",
            borderRadius: "5px",
            backgroundColor: "transparent",
            marginTop: "0.5rem",
            width: "100%",
            padding: 10,
          }}
        ></textarea>
        <div style={{ width: "100%", marginTop: 50 }}>
          <Grid container sx={{}}>
            <Grid item sm={5}>
              <LoadingButton
                sx={{
                  width: "100%",
                  backgroundColor: "var(--secondary-bg-color)",
                  color: "white",
                }}
                onClick={() => sendMessage("email")}
                loading={loading === "email"}
              >
                Send Email
              </LoadingButton>
            </Grid>
            <Grid sm={2} />
            <Grid item sm={5}>
              <LoadingButton
                sx={{
                  width: "100%",
                  backgroundColor: "var(--secondary-bg-color)",
                  color: "white",
                }}
                onClick={() => sendMessage("sms")}
                loading={loading === "sms"}
              >
                Send Message
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </MessagingContainer>
  );
};

export default Messaging;

const MessagingContainer = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .menu-left {
    // background: #ffffff;
    border-radius: 10px;
    flex: 0.4;
    margin-right: 1rem;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-right: 0;
      margin-bottom: 1rem;
      display: flex;
    }

    .topLeft {
      background: #ffffff;
      border-radius: 10px;
      flex: 0.25;
      margin-right: 1rem;
      color: #000;
      flex-direction: column;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      // @media screen and (max-width: 600px) {
      //   flex-direction: column;
      //   margin-right: 0;
      //   margin-bottom: 1rem;
      //   display: flex;
      // }
    }
    .bottomLeft {
      background: #ffffff;
      border-radius: 10px;
      flex: 0.73;
      margin-right: 1rem;
      padding: 10px;
      color: #000;
      overflow-y: auto;
      // @media screen and (max-width: 600px) {
      //   flex-direction: column;
      //   margin-right: 0;
      //   margin-bottom: 1rem;
      //   display: flex;
      // }
    }
  }
  .menu-right {
    flex: 0.6;
    background: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    height: 100vh;
  }
`;
