import React, { useEffect, useState } from "react";
import StoreCard from "../../components/ayoboStoreCard";
import { NewStoreModal } from "../../components/modals/newStore";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchStoresAction } from "../../store/reducers/store";
import { Redirect } from "react-router";

const AyoboStore = () => {
  const stores = useSelector((state) => state.Store.stores);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStoresAction());
  }, [dispatch]);

  const user = useSelector((state) => state.User.user) || {};
  const { role } = user;
  const hasPrivilage = role !== "regular";
  if (!hasPrivilage) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <>
      <NewStoreModal
        visible={showModal}
        handleCancel={() => setShowModal(false)}
      />
      <div className="ayobo-store">
        <div className="ayobo-store-header">
          <p className="select-store-text">Select store</p>
          <div className="ayobo-store-tools">
            <button className="delete-ayobo-store-btn">
              <img
                src="/assets/icons/fluent_delete-16-regular.png"
                alt="delete store"
              />
              Delete Selected
            </button>
            <button
              className="add-ayobo-store-btn"
              onClick={() => {
                if (showModal) {
                  setShowModal(false);
                } else {
                  setShowModal(true);
                }
              }}
            >
              <img src="/assets/icons/add_circle_outline.png" alt="add store" />
              Add New Store
            </button>
          </div>
        </div>
        <div className="ayobo-store-list">
          {stores &&
            stores?.map((item, index) => {
              return (
                <div key={index} className="ayobo-list">
                  <StoreCard
                    store_id={item?.id}
                    icon={"/assets/icons/ion_storefront-outline-dark.png"}
                    store_location={item?.name}
                    staff_number={item?.staffs_count}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default AyoboStore;
