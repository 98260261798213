import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, ButtonBase } from "@mui/material";

import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import CustomTable from "../../components/customTable";
import CustomSearch from "../../components/search";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchServicesAction } from "../../store/reducers/store";
import { Link } from "react-router-dom";

const Sales = ({ selectedCategory }) => {
  const services = useSelector((state) => state.Store.services || []);
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState(null);
  var [currentPage, setCurrentPage] = useState(1);
  var [lastPage, setLastPage] = useState(1);

  const tableCols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "staff_number",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Available Quantity",
      dataIndex: "current_stock",
      key: "current_stock",
      render: (text) => <span>{text && parseInt(text)}</span>,
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "status",
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text?.substring(0, 16)}
        </div>
      ),
    },
    {
      title: "History",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#0088c0",
            cursor: "pointer",
          }}
        >
          <Link
            to={`/dashboard/inventory/sales/item-history/${record?.name}/${record?.id}`}
          >
            view
          </Link>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = { page: currentPage, with_inventory: true };

    const get = async (payload) => {
      // add page no to query
      const data = await dispatch(fetchServicesAction({ query: payload }));
      console.info({ data });
      var lastPageNo = data?.meta?.last_page;
      if (lastPageNo) {
        setLastPage(lastPageNo);
        if (currentPage > lastPageNo) setCurrentPage(1);
      }
    };
    if (query) payload.search = query;
    get(payload);
  }, [dispatch, query, currentPage]);

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        flex: "0.7",
        height: "70vh",
      }}
    >
      <ItemTable>
        {/* {showEditStaffModal && (
          <StaffEditForm
            visible={showEditStaffModal}
            handleCancel={closeEditStaff}
            staffData={staffData}
          />
        )} */}
        {/* <SalesReceipt
        visible={showReceiptModal}
        handleCancel={() => setshowReceiptModal(false)}
      /> */}

        <div className="item-tools">
          <div className="search-container">
            <CustomSearch
              // handleSearch={setQuery}
              search_placeholder="Search Item"
            />
          </div>
        </div>
        <CustomTable
          table_columns={tableCols}
          table_data={services}
          // table_pagination={{
          //   defaultPageSize: 9,
          //   simple: true,
          // }}
          table_pagination={false}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <div style={{ display: "flex", justifyContent: "end", width: 120 }}>
            <ButtonBase
              sx={{
                color: "blue",
                backgroundColor: "grey",
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <ArrowBackIos />
            </ButtonBase>
            <Box
              sx={{
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
            >
              {currentPage}
            </Box>
            <ButtonBase
              sx={{
                color: "blue",
                backgroundColor: "grey",
                marginLeft: "auto",
                width: "40px",
                height: "40px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
              disabled={currentPage >= lastPage}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <ArrowForwardIos />
            </ButtonBase>
          </div>
        </div>
      </ItemTable>
    </Box>
  );
};

export default Sales;

const ItemTable = styled.div`
  background: #ffffff;
  border-radius: 9px;
  padding: 1rem;
  .item-tools {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 1rem 0rem 1rem;

    .search-container {
      margin-right: 1.5rem;
    }
    .add-new-item-btn {
      background-color: var(--secondary-bg-color);
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      width: 12rem;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        width: 10rem;
      }
      @media screen and (max-width: 380px) {
        white-space: pre-wrap;
        width: auto;
        padding: 0.2rem 0.4rem;
      }
      img {
        margin-right: 0.5rem;
      }
    }
  }
`;
