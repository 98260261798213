import React, { useCallback, useState } from "react";
import styled from "styled-components";
import "./index.css";
import { Tabs } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import ServiceSale from "../../pages/addSales/service";
import DiscountSale from "../../pages/addSales/discount";
import { updateOrderApiCall } from "../../Api/order";
import { fetchOrdersAction } from "../../store/reducers/store";
import notification from "../../common/Alert";
import { parseEerror } from "../../helpers";
const { TabPane } = Tabs;

const style = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80vh",
    bgcolor: "white",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    // overflowY: "auto",
  },
};

const SetPrice = ({ visible, handleOk, handleCancel, data }) => {
  const [loading, setLoading] = useState();
  const [services, setServices] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const storeservices = useSelector((state) => state.Store.services) || [];
  const storeId = useSelector((state) => state.Store.selectedStore);
  const storeservicesHash = {};
  storeservices.forEach((v) => {
    storeservicesHash[v.id] = v;
  });
  const serviceCategories =
    useSelector((state) => state.Store.serviceCategories) || {};
  storeservices.forEach((v) => {
    storeservicesHash[v.id] = v;
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      customer_id: data?.customer_id,
      staff_id: data?.staff_id,
      service_type: data?.service_type,
      payment_method: data?.payment_method,
      date: data?.date,
      items: data?.items,
    },

    onSubmit: (values) => {
      updateOrder();
    },
  });
  const updateOrder = async (values) => {
    setLoading(true);

    const res = await updateOrderApiCall(storeId, data.id, {
      services,
      discounts,
    });
    const { success, resData, error } = res;

    console.info({ success, resData, error });
    if (success) {
      await dispatch(fetchOrdersAction());
      handleCancel();
      formik.resetForm();
      notification.notify("success", "Order Updated");
    }
    setLoading(false);

    if (error) notification.notify("error", parseEerror(error));
  };

  const getTotal = useCallback(() => {
    let total = 0;
    services.forEach((v) => {
      total += parseInt(
        (storeservicesHash[v.service_id]?.price || 0) * (v?.quantity || 0)
      );
    });
    return total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  if (!visible) return false;

  return (
    <Modal
      open={visible}
      onClose={handleCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style.container}>
        <img
          src="/assets/icons/ic_round-cancel.png"
          alt="close"
          style={{
            position: "absolute",
            top: -8,
            right: -8,
            cursor: "pointer",
            width: "30px",
          }}
          onClick={handleCancel}
        />
        <NewSalesContainer>
          <div className="left">
            <Tabs defaultActiveKey="1">
              {serviceCategories?.map((item) => (
                <TabPane tab={item.name} key={item.id}>
                  <ServiceSale
                    serviceCategoryId={item.id}
                    services={services}
                    setServices={setServices}
                  />
                </TabPane>
              ))}
              <TabPane tab="Discount" key="2">
                <div style={{ overflowY: "auto", height: "60vh" }}>
                  <DiscountSale
                    discounts={discounts}
                    setDiscounts={setDiscounts}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
          <div className="right">
            <h3>New Order</h3>
            <form onSubmit={formik.handleSubmit}>
              <label>Customer ID</label>
              <br />
              <input
                type="text"
                placeholder="Enter ID of customer"
                name="customer_id"
                id="customer_id"
                onBlur={formik.handleBlur}
                value={formik.values.customer_id}
                onChange={formik.handleChange}
                disabled
              />
              {formik.errors.customer_id && formik.touched.customer_id && (
                <div style={{ color: "red" }}> {formik.errors.customer_id}</div>
              )}

              <br />
              <label>Staff ID</label>
              <br />
              <input
                type="text"
                placeholder="Enter staff ID"
                name="staff_id"
                id="staff_id"
                onBlur={formik.handleBlur}
                value={formik.values.staff_id}
                onChange={formik.handleChange}
                disabled
              />
              {formik.errors.staff_id && formik.touched.staff_id && (
                <div style={{ color: "red" }}> {formik.errors.staff_id}</div>
              )}

              <br />
              <label>Service Type</label>
              <br />
              <select
                name="service_type"
                id="service_type"
                required
                onBlur={formik.handleBlur}
                value={formik.values.service_type}
                onChange={formik.handleChange}
                disabled
              >
                <option value="">Select service type</option>
                <option value="self-service">Self Service</option>
                <option value="drop-off">Drop Off</option>
                <option value="pickup">Pickup</option>
              </select>
              {formik.errors.service_type && formik.touched.service_type && (
                <div style={{ color: "red" }}>
                  {" "}
                  {formik.errors.service_type}
                </div>
              )}
              <br />
              <label>Payment Method</label>
              <br />
              <select
                name="payment_method"
                id="payment_method"
                onBlur={formik.handleBlur}
                value={formik.values.payment_method}
                onChange={formik.handleChange}
                required
                disabled
              >
                <option value="" hidden>
                  Select payment method
                </option>
                <option value="cash">Cash</option>
                <option value="transfer">Transfer</option>
                <option value="pos">Pos</option>
                <option value="pending">Pending</option>
                <option value="walet">Walet</option>
              </select>
              {formik.errors.payment_method &&
                formik.touched.payment_method && (
                  <div style={{ color: "red" }}>
                    {" "}
                    {formik.errors.payment_method}
                  </div>
                )}
              <br />
              <label>Date</label>
              <br />
              <input
                type="date"
                placeholder="Choose date"
                name="date"
                id="date"
                onBlur={formik.handleBlur}
                value={formik.values.date}
                onChange={formik.handleChange}
                disabled
              />
              {formik.errors.date && formik.touched.date && (
                <div style={{ color: "red" }}> {formik.errors.date}</div>
              )}
              <br />
              <label>Item</label>
              <br />
              <input
                type="text"
                placeholder="Input items"
                name="items"
                id="items"
                onBlur={formik.handleBlur}
                value={formik.values.items}
                onChange={formik.handleChange}
                disabled
              />
              {formik.errors.items && formik.touched.items && (
                <div style={{ color: "red" }}> {formik.errors.items}</div>
              )}
            </form>

            <div className="item-summary">
              {services.map((item, index) => {
                return (
                  <div className="item-info" key={index}>
                    <div
                      className=""
                      style={{
                        width: "70%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {storeservicesHash[item.service_id]?.name}

                      <span>X{item?.quantity}</span>
                    </div>
                    <p style={{ display: "flex" }}>
                      N
                      {storeservicesHash[item.service_id]?.price *
                        item?.quantity}
                    </p>
                  </div>
                );
              })}
              <div className="total">
                <p>{`Total`.concat("-")} </p>
                <p>N{getTotal()}</p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                loading={loading}
                onClick={formik.submitForm}
                loadingPosition="end"
                fullWidth
                className="submit-store-btn"
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </NewSalesContainer>
      </Box>
    </Modal>
  );
};

export default SetPrice;

const NewSalesContainer = styled.div`
  display: grid;
  grid-template-columns: [col1-start] 1.3fr [col1-end col2-start] 2fr [col2-end];
  gap: 2rem;
  //   align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }

  .left {
    // height: calc(100vh - 7rem);
    // height: 992px;
    background: #ffffff;
    border-radius: 15px;
    padding: 1rem 2rem;
    overflow-y: auto;
    height: 70vh;
  }
  .right {
    // height: calc(100vh - 7rem);
    height: 70vh;
    background: #ffffff;
    border-radius: 15px;
    padding: 1rem 2rem;
    overflow-y: auto;

    h3 {
      font-size: 1.25rem;
    }

    form {
      label {
        margin-top: 0.8rem;
        font-size: 1rem;
      }
      input,
      select {
        width: 100%;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        padding: 0.5rem;
        outline: none;
        background: transparent;
        margin-top: 0.5rem;

        ::placeholder {
          color: #acacac;
        }
      }
    }
    .item-summary {
      margin-top: 3rem;
      .item-info,
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .total {
        font-weight: 700;
      }
    }
    .submit-store-btn {
      color: #fff;
      background: var(--secondary-bg-color);
      border-radius: 5px;
      margin-top: 2.5rem;
      width: 80%;
    }
    .add-new-sales-btn {
      background: var(--secondary-bg-color);
      border-radius: 5px;
      padding: 0.5rem;
      color: #ffffff;
      width: 70%;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      margin: 2rem auto;
    }
  }
`;
