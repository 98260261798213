export const ayoboStoreData = [
  {
    id: 1,
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "ilorin",
    staff_number: 14,
  },
  {
    id: 2,
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
  {
    icon: "/assets/icons/ion_storefront-outline-dark.png",
    store_location: "Ikeja",
    staff_number: 14,
  },
];
export const dashboardCardData = [
  {
    key: "sales",
    numberOfSSctivity: 4678,
    tagName: "Number of Sales",
    bgColor: "#C5FFCE",
  },
  {
    key: "orders",
    numberOfSSctivity: 1500,
    tagName: "Number of Orders",
    bgColor: "#F4ECDD",
  },
  {
    key: "debtors",
    numberOfSSctivity: 78,
    tagName: "Number of Debtors",
    bgColor: "#EFDADA",
  },
  {
    key: "customers",
    numberOfSSctivity: 3000,
    tagName: "Number of Customers",
    bgColor: "#DEE0EF",
  },
  {
    key: "staff",
    numberOfSSctivity: 68,
    tagName: "Number of Staff",
    bgColor: "#DDF4F5",
  },
];

export const dashboardTableData = [
  {
    key: "1",
    id: "1",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "2",
    id: "2",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "3",
    id: "3",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "4",
    id: "4",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
];
export const salesTableData = [
  {
    key: "1",
    id: "1",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "2",
    id: "2",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "3",
    id: "3",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "4",
    id: "4",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "5",
    id: "5",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "6",
    id: "6",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "7",
    id: "7",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "8",
    id: "8",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "9",
    id: "9",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },

  {
    key: "10",
    id: "10",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
  {
    key: "11",
    id: "11",
    customer: "Christian Omenogor",
    item: "2 Senator wear, 4 jeans",
    amount: "N6000.00",
    payment: "Transfer",
    attendant: "Promise Nwan",
    date: "01-05-2021",
  },
];
export const customerTableData = [
  {
    key: "1",
    id: "1",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: () => console.log("hey"),
  },
  {
    key: "2",
    id: "2",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "3",
    id: "3",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "4",
    id: "4",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "5",
    id: "5",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "6",
    id: "6",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "7",
    id: "7",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "8",
    id: "8",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "9",
    id: "9",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "10",
    id: "10",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "11",
    id: "11",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "12",
    id: "12",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
  {
    key: "13",
    id: "13",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    address: "okolobia ojuoja",
    service: "Transfer",
    action: "",
  },
];

export const staffTableData = [
  {
    key: "1",
    id: "1",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "2",
    id: "2",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "3",
    id: "3",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "4",
    id: "4",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "5",
    id: "5",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "6",
    id: "6",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "7",
    id: "7",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "8",
    id: "8",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "9",
    id: "9",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "10",
    id: "10",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "11",
    id: "11",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "12",
    id: "12",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
  {
    key: "13",
    id: "13",
    name: "Christian Omenogor",
    email: "omenogor@gmail.com",
    phone: "N6000.00",
    store: "Transfer",
    role: "Transfer",
    salary: "Transfer",
    status: "active",
    action: () => console.log("hey"),
  },
];

export const salesTableCols = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Payment",
    dataIndex: "payment",
    key: "payment",
  },
  {
    title: "Attendant",
    dataIndex: "attendant",
    key: "attendant",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

export const notificationData = [
  {
    pic: "/assets/images/user.png",
    message: "Chris Omen  placed an order for pickup",
    time: 3,
  },
  {
    pic: "/assets/icons/receiptimg.png",
    message: "Receipt successfully printed",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Chris Omen  placed an order for pickup",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Receipt successfully printed",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Chris Omen  placed an order for pickup",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Receipt successfully printed",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Chris Omen  placed an order for pickup",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Receipt successfully printed",
    time: 3,
  },
  {
    pic: "/assets/images/user.png",
    message: "Chris Omen  placed an order for pickup",
    time: 3,
  },
];
export const salesOverallData = [
  {
    name: "Bleach ---------------------------",
    amount: 100,
  },
  {
    name: "Fabric Softner ------------------",
    amount: 200,
  },
  {
    name: "Ironing 3 in 1 female wears ---",
    amount: 200,
  },
];

export const staffData = [
  {
    WeekDay: "Bleach ---------------------------",
    amount: 100,
  },
  {
    Saturday: "Fabric Softner ------------------",
    amount: 200,
  },
  {
    Sunday: "Ironing 3 in 1 female wears ---",
    amount: 200,
  },
];
