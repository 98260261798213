import React, { useState } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import UserImage from "../assets/icons/user.png";
import DashboardRoutes from "./dashboardRoutes";
import "../components/sidebar/header.css";
import NotificationModal from "../components/modals/notificationModal";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "../helpers";
import { fetchNotoficationAction } from "../store/reducers/store";

const { Content } = Layout;

const Dashboard = () => {
  const { pathname } = useLocation();
  const loading = useSelector((state) => state.App.loading);
  const user = useSelector((state) => state.User.user);
  const notifications = useSelector((state) => state.Store.notifications) || [];
  const loggedInStaffNumber = user?.staff?.staff_number;

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleNotification = () => {
    if (showModal === false) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  useInterval(() => dispatch(fetchNotoficationAction()), 500000);

  return (
    <Layout
      style={{
        zIndex: 99999999999999999999999999999999999999999999999999999,
        height: "100%",
      }}
    >
      <Sidebar />
      <Layout
        className="site-layout"
        style={{
          position: "relative",
        }}
      >
        {loading && (
          <div
            style={{
              top: "60px",
              left: "200px",
              position: "fixed",
              height: "95vh",
              width: "calc(100% - 200px)",
              backgroundColor: "white",
              backdropFilter: "blur(0.2px)",
              opacity: "0.7",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <header className="site-header">
          <div className="site-header-left">
            <h2 className="header-title">
              {pathname === "/dashboard" ? (
                "Dashboard"
              ) : pathname === "/dashboard/sales" ? (
                <div className="bread-cumb">
                  <p>Sales</p>
                  <span>{" > All Sales"}</span>
                </div>
              ) : pathname === "/dashboard/sales/pending" ? (
                <div className="bread-cumb">
                  <p>Sales</p>
                  <span>{" > Pending Sales"}</span>
                </div>
              ) : pathname === "/dashboard/sales/drafts" ? (
                <div className="bread-cumb">
                  <p>Sales</p>
                  <span>{" > Draft Sales"}</span>
                </div>
              ) : pathname === "/dashboard/sales/report" ? (
                <div className="bread-cumb">
                  <p>Sales</p>
                  <span>{" > Sales Report"}</span>
                </div>
              ) : pathname === "/dashboard/order/neworders" ? (
                <div className="bread-cumb">
                  <p>Order</p>
                  <span>{" > New Orders"}</span>
                </div>
              ) : pathname === "/dashboard/order/completed" ? (
                <div className="bread-cumb">
                  <p>Order</p>
                  <span>{" > Completed"}</span>
                </div>
              ) : pathname === "/dashboard/payment/payroll" ? (
                <div className="bread-cumb">
                  <p>Payment</p>
                  <span>{" > payroll"}</span>
                </div>
              ) : pathname === "/dashboard/payment/payout" ? (
                <div className="bread-cumb">
                  <p>Payment</p>
                  <span>{" > payout"}</span>
                </div>
              ) : pathname === "/dashboard/customer" ? (
                "Customer"
              ) : pathname === "/dashboard/customer/add-new" ? (
                <div className="bread-cumb">
                  <p>Customer</p>
                  <span>{" > Add New Customer"}</span>
                </div>
              ) : pathname === "/dashboard/staff" ? (
                "Staff"
              ) : pathname === "/dashboard/staff/add-new" ? (
                <div className="bread-cumb">
                  <p>Staff</p>
                  <span>{" > Add New Staff"}</span>
                </div>
              ) : pathname === "/dashboard/messaging" ? (
                "Messaging"
              ) : pathname === "/dashboard/attendance" ? (
                "Attendance"
              ) : pathname === "/dashboard/settings" ? (
                "Setting"
              ) : (
                pathname.split("/")[2]?.replace("-", " ")
              )}
            </h2>
          </div>

          <div className="site-header-right">
            <i className="fas fa-bell" onClick={handleNotification}>
              {notifications.length > 0 && (
                <span className="bell-badge">{notifications.length}</span>
              )}
            </i>
            <div className="site-header-right-inner">
              <img src={UserImage} className="avatar" alt="avatar" />
              <div className="header-user-details">
                <p className="header-user-name">{user?.name} </p>
                <p className="header-user-email">{user?.email}</p>
                <p className="header-user-email" style={{ minWidth: "70px" }}>
                  {!showPassword ? (
                    <i
                      className="fa fa-eye-slash"
                      onClick={handleTogglePassword}
                      style={{ marginRight: "5px" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      onClick={handleTogglePassword}
                      style={{ marginRight: "5px" }}
                    ></i>
                  )}
                  {showPassword && <>{loggedInStaffNumber || user?.id}</>}
                </p>
              </div>
            </div>
          </div>
        </header>

        <Content
          style={{
            padding: "1.625rem",
            overflow: "initial",
            backgroundColor: "#f0f2f5",
          }}
        >
          <DashboardRoutes />
        </Content>
        <NotificationModal
          visible={showModal}
          closeNotification={() => setShowModal(false)}
        />
      </Layout>
    </Layout>
  );
};

export default Dashboard;
