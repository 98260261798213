import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "./index.css";
import { ReactComponent as AuthImg } from "./images/login-img.svg";
import { getUserApiCall, loginApiCall } from "../../Api/AuthApi";
import { useDispatch, useSelector } from "react-redux";
import { addUserAction } from "../../store/reducers/user";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { notification } from "antd";
import { parseEerror } from "../../helpers";

const Login = () => {
  const [showPassword, setShowPassword] = useState("password");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const loggedIn = useSelector((state) => state.User.loggedIn);
  const dispatch = useDispatch();
  const handleTogglePassword = () => {
    showPassword === "password"
      ? setShowPassword("text")
      : setShowPassword("password");
  };
  const login = async (values) => {
    setLoading(true);
    const res = await loginApiCall(values);
    const { success, resData, error } = res;
    console.info(resData);
    localStorage.setItem("washRyteToken2022", resData?.access_token);
    if (success) {
      const res = await getUserApiCall();
      const { success, resData, error } = res;

      if (success) dispatch(addUserAction({ loggedIn: true, user: resData }));
      if (error) notification.notify("error", parseEerror(error));
    }
    if (error) setError("Invalid Credentials");
    setLoading(false);
  };

  let validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "password should be at least 8 characters long")
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // )
      .required("this field is required"),
    email: yup
      .string()
      .email("invalid email format")
      .required("this field is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setError(null);
      login(values);
    },
  });

  if (loggedIn) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <div className="auth-container">
      <div className="auth-container-inner">
        <div className="auth-left">
          <form onSubmit={formik.handleSubmit}>
            <h2>Welcome to your space</h2>
            <p>Login to your account and keep record of all sales</p>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <label>Email</label>
            <br />
            <input
              type="email"
              name="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email && formik.touched.email && (
              <div style={{ color: "red" }}> {formik.errors.email}</div>
            )}

            <br />
            <label>Enter your Password</label>
            <br />
            <div className="input">
              <input
                type={showPassword}
                placeholder="Enter your password"
                name="password"
                id="password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {showPassword === "password" ? (
                <i
                  className="fa fa-eye-slash"
                  onClick={handleTogglePassword}
                ></i>
              ) : (
                <img
                  src="/assets/icons/eye.png"
                  alt="eye"
                  onClick={handleTogglePassword}
                />
              )}
            </div>
            {formik.errors.password && formik.touched.password && (
              <div style={{ color: "red" }}> {formik.errors.password}</div>
            )}

            <div className="rem-me">
              <div className="check-sec">
                <input type="checkbox" id="check-rem-me" />{" "}
                <span>Remember me?</span>
              </div>

              <Link to="/forgot-password" className="forgot-pass-link">
                Forgot Password ?
              </Link>
            </div>
            <br />

            <LoadingButton
              loading={loading}
              type="submit"
              // disabled={formik.isSubmitting}
              onClick={formik.submitForm}
              loadingPosition="end"
            >
              LogIn
            </LoadingButton>
          </form>
        </div>
        <div className="auth-right">
          <AuthImg style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default Login;
