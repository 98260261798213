import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const loginUrl = `${BASE_URL}/auth/login`;
const forgotPasswordUrl = `${BASE_URL}/auth/forgot-password`;
const resetPasswordUrl = `${BASE_URL}/auth/reset-password`;
const userUrl = `${BASE_URL}/user`;

export function loginApiCall(payload) {
  return request({ url: loginUrl, method: "post", payload });
}

export function forgotPasswordApiCall(payload) {
  return request({ url: forgotPasswordUrl, method: "post", payload });
}

export function resetPasswordApiCall(payload) {
  return request({ url: resetPasswordUrl, method: "post", payload });
}

export function getUserApiCall() {
  return request({ url: userUrl });
}

export function changePasswordApiCall(payload) {
  return request({ url: `${BASE_URL}/password`, method: "post", payload });
}
