import React from "react";
import "./index.css";
import { Modal } from "antd";
import Logo from "../../assets/images/logo.png";

const Btns = ({ handleCancel }) => {
  return (
    <div className="receipt-tools" id="section-to-remove">
      <button className="receipt-print-btn" onClick={() => window.print()}>
        Print
      </button>
      <button onClick={handleCancel} className="receipt-cancel-btn">
        Cancel
      </button>
    </div>
  );
};

const SalesReceipt = ({ visible, handleCancel, salesData }) => {
  console.info(salesData);

  if (!visible) return null;

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <div className="receipt-container" style={{ fontWeight: "bold" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <img src={Logo} alt="logo" />
        </div>
        <h3>Thanks for your Patronage</h3>
        <p className="recept-message">
          This is to thank you for your patronage and for record purpose, a
          breakdown of service(s) offered. Reach out to us on{" "}
          <a href="tel: 0813443232">0813443232</a> for any complaints.
        </p>

        <ul style={{ marginTop: "15px" }}>
          <li>
            <span> Customer: </span>
            {salesData?.customer}
          </li>
          <li>
            <span> Attendant: </span>
            {salesData?.attendant}
          </li>
          <li>
            <span> Date: </span>
            {salesData?.created_at.substring(0, 11)}
          </li>
          <li>
            <span>Status: </span>
            {salesData.payment_method}
          </li>
        </ul>

        {/* <div className="item-lists">
          <p className="left">{salesData?.items}</p>
          <p>N{salesData?.price}</p>
        </div> */}

        <div className="sales-summary" style={{ marginTop: "15px" }}>
          <div className="item-info">
            <p className="item-name">
              Item
              <p className="">Qty</p>
            </p>

            <p>Value</p>
          </div>
          {salesData.sale_services?.map((item, index) => {
            return (
              <div className="item-info" key={index}>
                <p className="item-name">
                  {item.service_name}
                  <p className="">X {item.service_units}</p>
                </p>

                <p>N{item.service_unit_price}</p>
              </div>
            );
          })}
          {salesData.sale_discounts?.map((item, index) => {
            return (
              <div className="item-info" key={index}>
                <p className="item-name">{item.discount_name}</p>
                <p>{item.discount_percentage_off}%</p>
              </div>
            );
          })}
          <div className="item-info">
            <p className="item-name">VAT</p>
            <p>7.5%</p>
          </div>
          <div className="total">
            <p>{`Total`.concat("-")} </p>
            <p>{salesData?.price}</p>
          </div>
        </div>

        <Btns handleCancel={handleCancel} />
        <div
          style={{
            fontSize: "10px",
            marginTop: "20px",
            marginBottom: "-50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          powered by waizytech engineering
        </div>
      </div>
    </Modal>
  );
};

export default SalesReceipt;
