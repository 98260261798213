import React, { useState } from "react";
import "./index.css";

const StoreCard = ({ icon, store_location, staff_number, store_id }) => {
  const [storeCardStyle, setStoreCardStyle] = useState("inactive-store-card");

  const getStoreToDelete = async () => {
    localStorage.setItem("store_id", JSON.stringify(store_id));

    let s = JSON.parse(localStorage.getItem("store_id"));

    if (store_id === s) {
      setStoreCardStyle("active-store-card");
      console.log("hey");
    } else {
      setStoreCardStyle("inactive-store-card");
      console.log("hi");
    }
  };

  return (
    <div className={storeCardStyle} onClick={getStoreToDelete} key={store_id}>
      <img src={icon} alt="store-icon" />
      <div className="store-info-bottom">
        <p className="staff-location">{store_location}</p>
        <p className="staff-number">Staff: {staff_number}</p>
      </div>
    </div>
  );
};

export default StoreCard;
