import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  scales: {
    x: {
      beginAtZero: false,
      grid: {
        color: "#F0F0F0",
        zeroLineColor: "trasparent",
        tickMarkLength: false,
      },
    },

    y: {
      beginAtZero: true,
      ticks: {
        display: false,
      },
      grid: {
        tickMarkLength: false,
        zeroLineColor: "trasparent",
        color: "#F0F0F0",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        boxWidth: 1.5,
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  maintainAspectRatio: false,
  tension: 0.5,
};

const CustomChart = () => {
  const salesHistory =
    useSelector((state) => state.Store.storeInformation?.sales_history) || {};
  let maxVal = 1;
  const dataToDisplay = {};
  Object.entries(salesHistory)?.forEach(([k, v]) => {
    const val = parseFloat(v);
    if (val > maxVal) maxVal = val;
    dataToDisplay[k] = val;
  });

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");

    const graddient = ctx.createLinearGradient(100, 50, 50, 300);
    graddient.addColorStop(0, "#CAC6E1");
    graddient.addColorStop(1, "rgba(207, 211, 226, 0.21");
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      datasets: [
        {
          backgroundColor: graddient,
          fill: "start",
          borderColor: "transparent",
          borderWidth: 2,
          borderRadius: 15,
          pointColor: "blue",
          pointStrokeColor: "blue",
          data: [
            (dataToDisplay["JANUARY"] / maxVal) * 10 || 0,
            (dataToDisplay["FEBRUARY"] / maxVal) * 10 || 0,
            (dataToDisplay["MARCH"] / maxVal) * 10 || 0,
            (dataToDisplay["APRIL"] / maxVal) * 10 || 0,
            (dataToDisplay["MAY"] / maxVal) * 10 || 0,
            (dataToDisplay["JUNE"] / maxVal) * 10 || 0,
            (dataToDisplay["JULY"] / maxVal) * 10 || 0,
            (dataToDisplay["AUGUST"] / maxVal) * 10 || 0,
            (dataToDisplay["SEPTEMBER"] / maxVal) * 10 || 0,
            (dataToDisplay["OCTOBER"] / maxVal) * 10 || 0,
            (dataToDisplay["NOVEMBER"] / maxVal) * 10 || 0,
            (dataToDisplay["DECEMBER"] / maxVal) * 10 || 0,
          ],
        },
      ],
    };
  };
  return <Line data={data} options={options} />;
};

export default CustomChart;
