import { request } from ".";

const BASE_URL = process.env.REACT_APP_API_SERVER;
const payoutsUrl = (storeId) => `${BASE_URL}/${storeId}/payouts`;

export function getPayoutsApiCall(storeId, query) {
  return request({ url: payoutsUrl(storeId), query });
}

export function createPayoutApiCall(storeId, payload) {
  return request({ url: payoutsUrl(storeId), method: "post", payload });
}
